// const string for actions in uppercase
const APP_TASK_SEARCH_FILTER_CHANGED = 'APP_TASK_SEARCH_FILTER_CHANGED';
const APPP_SEARCH_OVERLAY_VISIBILITY = 'APPP_SEARCH_OVERLAY_VISIBILITY';
const APP_START_DATE_FILTER_CHANGED = 'APP_START_DATE_FILTER_CHANGED';
const APP_END_DATE_FILTER_CHANGED = 'APP_END_DATE_FILTER_CHANGED';
const APP_MONTH_FILTER = 'APP_MONTH_FILTER';

// actions
export const actions = {
  searchFilterChanged: (filter) => ({ type: APP_TASK_SEARCH_FILTER_CHANGED, filter }),
  setSearchOverlayVisibility: (visibility) => ({ type: APPP_SEARCH_OVERLAY_VISIBILITY, visibility }),
  startDateFilterChanged: (startDateFilter) => ({ type: APP_START_DATE_FILTER_CHANGED, startDateFilter }),
  endDateFilterChanged: (endDateFilter) => ({ type: APP_END_DATE_FILTER_CHANGED, endDateFilter }),
  onCalendarMonthChange: (startMonth) => ({ type: APP_MONTH_FILTER, startMonth }),
};

// initial state
const initialState = {
  taskFilter: '',
  isSearchOverlayVisible: false,
  startDateFilter: null,
  endDateFilter: null,
  startMonth: null,
  endMonth: null,
};

// action handlers
const actionHandlers = {
  [APP_TASK_SEARCH_FILTER_CHANGED]: (state, { filter }) => ({ ...state, taskFilter: filter }),
  [APPP_SEARCH_OVERLAY_VISIBILITY]: (state, { visibility }) => ({ ...state, isSearchOverlayVisible: visibility }),
  [APP_START_DATE_FILTER_CHANGED]: (state, { startDateFilter }) => ({ ...state, startDateFilter }),
  [APP_END_DATE_FILTER_CHANGED]: (state, { endDateFilter }) => ({ ...state, endDateFilter }),
  [APP_MONTH_FILTER]: (state, { startMonth }) => ({ ...state, startMonth }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
