import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, withFirebase, withFirestore, isLoaded } from 'react-redux-firebase';
import { Button, Input, InputGroupAddon, FormGroup } from 'reactstrap';
import { NotificationManager, NotificationPosition } from 'components/Layout';
import { Destination } from 'components/Destination';
import WithLoader from 'components/WithLoader';
import { actions as destinationActions } from 'reducers/destination';
import { actions as terminalActions } from 'reducers/terminal';
import { actions as fetchingActions } from 'reducers/fetching';
import * as collections from 'constants/collections';
import * as destinations from 'constants/destinations';
import _ from 'lodash';
import { now, collator } from 'utils';
import DataTable from 'react-data-table-component';

class Terminals extends Component {
  constructor(props) {
    super(props);
    this.showTerminal = this.showTerminal.bind(this);
    this.showList = this.showList.bind(this);

    this.state = {
      activeDiv: 'list',
    };
  }

  static propTypes = {
    role: PropTypes.string,
    className: PropTypes.string
  };

  showList() {
    this.setState({ activeDiv: 'list' });
    this.props.onTerminalNew();
  }

  saveTerminal = async () => {
    const { firestore, destination, terminal, onTerminalNew } = this.props;
    if (
      destination.address.state === '' ||
      destination.address.country === '' ||
      destination.address.formatted === '' ||
      destination.address.line1 === ''
    ) {
      NotificationManager.error('A valid address is required!', null, NotificationPosition.TOP_LEFT);
      return;
    }

    const terminalData = _.pick(terminal, ['taxPercentage']);

    let data = {
      ...terminalData,
      name: destination.name,
      address: destination.address,
      ...(!terminalData.id ? { createdAt: now() } : {}),
      modifiedAt: now(),
      disabled: false,
    }

    this.props.setLoading(true);

    if (terminal && terminal.id) {
      await firestore
        .collection(collections.TERMINAL)
        .doc(terminal.id)
        .update(data);

      onTerminalNew();
      this.showList();
      NotificationManager.success('Terminal successfully Updated', null, NotificationPosition.TOP_LEFT);
    } else {
      await firestore
        .add(collections.TERMINAL, data);
      onTerminalNew();
      this.showList();
      NotificationManager.success('Terminal successfully created', null, NotificationPosition.TOP_LEFT);
    }
    this.props.setLoading(false);
  };

  showTerminal = terminal => {
    const { onDestinationEdit, clear, onTerminalNew, onTerminalEdit } = this.props;
    this.setState(({ activeDiv: 'form' }));
    if (!!terminal) {
      onDestinationEdit(destinations.TERMINAL, terminal);
      onTerminalEdit(terminal);
    } else {
      onTerminalNew();
      clear(destinations.TERMINAL);
    }
  };

  getList = () => {
    const { terminals } = this.props;
    const onRowClicked = terminal => {
      this.showTerminal(terminal);
    }

    const newList = [...terminals];
    newList.sort((a, b) => collator.compare(a.description, b.description));

    const columns = [
      {
        name: '#',
        maxWidth: '5%',
        center: true,
        selector: 'idx',
        sortable: true,
      },
      {
        name: 'Name',
        left: true,
        selector: 'name',
        sortable: true
      },
      {
        name: 'Actions',
        left: true,
        button: true,
        cell: terminal =>
          <button
            className="btn btn-link"
            href="#"
            onClick={() => this.deleteTerminal(terminal)}>
            <i className="fa fa-trash" />
          </button>
      },
    ]
    return (
      <>
        <DataTable
          columns={columns}
          dense
          data={terminals.map((item, idx) => ({ idx: (idx + 1), ...item }))}
          highlightOnHover
          noHeader
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 25, 50]}
          responsive
          pointerOnHover
          onRowClicked={onRowClicked}
        />
      </>
    );
  };

  getForm = () => {
    const { terminal, onTerminalTaxPercentageChanged, settings } = this.props;
    const destinationAddress = { address: terminal.address, name: terminal.name };

    return (
      <div>
        <form
          className="p-t-15"
          onSubmit={e => {
            e.preventDefault();
            this.saveTerminal();
          }}>

          <FormGroup className={"form-group form-group-default input-group"}>
            <div className={"form-input-group"}>
              <label>Tax Percentage</label>
              <Input
                placeholder={`Default ${settings.taxPercentage}`}
                min={0}
                max={100}
                type="number"
                step="0.01"
                className="form-control"
                defaultValue={terminal.taxPercentage || ''}
                onChange={event => onTerminalTaxPercentageChanged(event.target.value)}
              />
            </div>
            <InputGroupAddon addonType="append">.00</InputGroupAddon>
          </FormGroup>
          <div className="mt-2">
            <Destination
              as={destinations.TERMINAL}
              defaultData={destinationAddress}
              readOnly={true} />
          </div>
          <Button color="primary" type="submit">
            {terminal.id ? 'Update' : 'Create'}
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={() => this.showList()}>
            Cancel
          </Button>
          <br />
        </form>
      </div>
    );
  };

  deleteTerminal(item) {
    if (window.confirm('Please confirm to delete this Terminal')) {
      const { firestore } = this.props;
      firestore
        .collection(collections.TERMINAL)
        .doc(item.id)
        .delete()
        .then(() => NotificationManager.success('Terminal successfully Deleted', null, NotificationPosition.TOP_LEFT));
    }
  }

  render() {
    const { terminals } = this.props;
    const { activeDiv } = this.state;
 
    if (isLoaded(terminals)) {
      return (
        <div>
          <div>
            {activeDiv !== 'form' ? (
              <Button color="primary" onClick={() => this.showTerminal()}> New Terminal </Button>
            ) : (
                <Button color="secondary" onClick={() => this.showList()}>Back</Button>
              )}
          </div>
          {activeDiv === 'form' ? this.getForm() : this.getList()}
        </div>
      );
    }
  }
}

const mapStateToProps = ({
  terminal,
  destination,
  firestore: {
    ordered: { terminals, settings = [] },
  },
}) => ({
  settings: settings && settings.length > 0 && settings[0],
  terminal,
  destination: destination[destinations.TERMINAL],
  terminals
});

const mapDispatchToProps = {
  ...destinationActions,
  ...terminalActions,
  ...fetchingActions,
};

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  firestoreConnect([{ 
    collection: collections.TERMINAL,
    orderBy: ['name', 'asc'],
    where: ['disabled', '==', false],
  }]),
  connect(mapStateToProps, mapDispatchToProps)
)(Terminals);

export default WithLoader(FirestoreConnected);
