export const homePath = '/';
export const loginPath = '/login';

export const ordersPath = '/orders';
export const newOrderPath = `${ordersPath}/new`;
export const viewOrderPath = `${ordersPath}/:id`;
export const editOrderPath = `${ordersPath}/:id/edit`;
export const orderPathForView = id => `${ordersPath}/${id}`; 
export const orderPathForEdit = id => `${ordersPath}/${id}/edit`; 

export const reportsPath = `/reports`;
