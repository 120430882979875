import { geocodeAddress } from 'utils';
import * as destinations from 'constants/destinations';

// const string for actions in uppercase
const DESTINATION_EDIT = "DESTINATION_EDIT";
const DESTINATION_NAME_CHANGED = 'DESTINATION_NAME_CHANGED';
const DESTINATION_LINE1_CHANGED = 'DESTINATION_LINE1_CHANGED';
const DESTINATION_COUNTRY_CHANGED = 'DESTINATION_COUNTRY_CHANGED';
const DESTINATION_STATE_CHANGED = 'DESTINATION_STATE_CHANGED';
const DESTINATION_POSTALCODE_CHANGED = 'DESTINATION_POSTALCODE_CHANGED';
const DESTINATION_LOAD_FROM_ADDRESS = 'DESTINATION_LOAD_FROM_ADDRESS';
const DESTINATION_LOADED_FROM_ADDRESS = 'DESTINATION_LOADED_FROM_ADDRESS';
const DESTINATION_LOCATION_ADD = 'DESTINATION_LOCATION_ADD';
const DESTINATION_FORMATTED_ADD = 'DESTINATION_FORMATTED_ADD';
const DESTINATION_EXTRA_ADD = 'DESTINATION_EXTRA_ADD';
const DESTINATION_FORM_CLEAR = 'DESTINATION_FORM_CLEAR';
const DESTINATION_FORM_ERROR = 'DESTINATION_FORM_ERROR';
const DESTINATION_FORM_SUCCESS = 'DESTINATION_FORM_SUCCESS';

// actions
export const actions = {
  onDestinationEdit: (dest, data) => ({ type: DESTINATION_EDIT, dest, data }),
  onDestinationNameChanged: (dest, name) => ({ type: DESTINATION_NAME_CHANGED, dest, name }),
  onLine1Changed: (dest, line1) => ({ type: DESTINATION_LINE1_CHANGED, dest, line1 }),
  onCountryChanged: (dest, country) => ({ type: DESTINATION_COUNTRY_CHANGED, dest, country }),
  onStateChanged: (dest, state) => ({ type: DESTINATION_STATE_CHANGED, dest, state }),
  onPostalCodeChanged: (dest, postalCode) => ({ type: DESTINATION_POSTALCODE_CHANGED, dest, postalCode }),
  onLoadDataFromAddress: (dest, address) => dispatch => {
    dispatch({ type: DESTINATION_LOAD_FROM_ADDRESS, dest });
    geocodeAddress(address)
      .then(addressData => {
        if (!addressData) {
          return;
        }

      // Getting state, postcode & country
        const {
          location,
          address: {
            formatted,
            postCode,
            state,
            country,
          },
          extra: info,
        } = addressData;
        
        location && dispatch({ type: DESTINATION_LOCATION_ADD, dest, location });
        formatted && dispatch({ type: DESTINATION_FORMATTED_ADD, dest, formatted });
        info && dispatch({ type: DESTINATION_EXTRA_ADD, dest, info });
        state && dispatch({ type: DESTINATION_STATE_CHANGED, dest, state });
        postCode && dispatch({ type: DESTINATION_POSTALCODE_CHANGED, dest, postalCode: postCode });
        country && dispatch({ type: DESTINATION_COUNTRY_CHANGED, dest, country });
        
        dispatch({ type: DESTINATION_LOADED_FROM_ADDRESS, dest })
      });
  },
  addLocation: (dest, location) => ({ type: DESTINATION_LOCATION_ADD, dest, location }),
  addFormatted: (dest, formatted) => ({ type: DESTINATION_FORMATTED_ADD, dest, formatted }),
  addExtra: (dest, info) => ({ type: DESTINATION_EXTRA_ADD, dest, info }),
  onError: (dest, error) => ({ type: DESTINATION_FORM_ERROR, dest, error }),
  onSuccess: (dest, success) => ({ type: DESTINATION_FORM_ERROR, dest, success }),
  clear: (dest) => ({ type: DESTINATION_FORM_CLEAR, dest }),
};

const baseState = {
  name: '',
  address: {
    line1: '',
    country: '',
    state: '',
    postalCode: '',
    formatted: '',
  },
  extra: null,
  location: null,
  error: null,
  success: null,
  loading: false,
};

// initial state
const initialState = {
  [destinations.TASK_ORIGIN]: baseState,
  [destinations.TASK_DESTINATION]: baseState,
  [destinations.COMPANY_ADDRESS]: baseState,
  [destinations.RECIPIENT_ADDRESS]: baseState,
  [destinations.TERMINAL]: baseState,
}

const actionHandlers = {
  [DESTINATION_NAME_CHANGED]: (state, { dest, name }) => ({ ...state, [dest]: {...state[dest], name } }),
  [DESTINATION_LINE1_CHANGED]: (state, { dest, line1 }) => {
    return { ...state, [dest]: {...state[dest], address: {...state[dest].address, line1 }, name: line1.split(',')[0].trim() } };
  },
  [DESTINATION_EDIT]: (state, { dest, data }) => ({...state, [dest]: { ...data }}),
  [DESTINATION_COUNTRY_CHANGED]: (state, { dest, country }) => {
    return { ...state, [dest]: {...state[dest], address: {...state[dest].address, country } } };
  },
  [DESTINATION_STATE_CHANGED]: (_state, { dest, state } ) => {
    return { ..._state, [dest]: {..._state[dest], address: {..._state[dest].address, state } } };
  },
  [DESTINATION_POSTALCODE_CHANGED]: (state, { dest, postalCode }) => {
    return { ...state, [dest]: {...state[dest], address: {...state[dest].address, postalCode } } };
  },
  [DESTINATION_LOCATION_ADD]: (state, { dest, location }) => {
    return { ...state, [dest]: {...state[dest], location: {...state[dest].location, lat: location.lat, lon: (location.lng || location.lon) } } };
  },
  [DESTINATION_FORMATTED_ADD]: (state, { dest, formatted }) => {
    return { ...state, [dest]: {...state[dest], address: {...state[dest].address, formatted } } };
  },
  [DESTINATION_EXTRA_ADD]: (state, { dest, info }) => ({...state, [dest]: {...state[dest], extra: { ...(state.extra || {}), ...info }}}),
  [DESTINATION_LOAD_FROM_ADDRESS]: (state, { dest }) => ({...state, [dest]: {...state[dest], loading: true }}),
  [DESTINATION_LOADED_FROM_ADDRESS]: (state, { dest }) => ({...state, [dest]: {...state[dest], loading: false }}),
  [DESTINATION_FORM_CLEAR]: (state, { dest }) => ({...state, [dest]: { ...baseState }}),
  [DESTINATION_FORM_ERROR]: (state, { dest, error }) => ({...state, [dest]: {...state[dest], error }}),
  [DESTINATION_FORM_SUCCESS]: (state, { dest, success }) => ({...state, [dest]: {...state[dest], success }}),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state
};