import React, { Component } from 'react';


class TaskInstructions extends Component {

  render() {
    const { instructions } = this.props;
    const steps = this.getSteps(instructions);

    const totalDistance = (instructions.distance * 0.000621371192).toFixed(2);
    var totalDuration = Math.floor(instructions.duration / 60);

    return <React.Fragment>
      <br />
      <div className="instructions">
        <div className="timeline-wrapper">
          <h3>Route Detail</h3>
          <br />
          <p className="instruction-header-information">
            <span className="distance">{totalDistance}mi</span> 
            <span className="separator"> | </span> 
            <span className ="duration">{totalDuration}min </span>
          </p>
          <hr />
          <div className="list-wrapper">
            {
              steps && steps.map((step, idx) => (
                <div key={idx}>
                  <p >
                    <img src={step.icon} width="40" data-src={step.icon} data-src-retina={step.icon} alt="" className="lazy" />
                    <span className={'instructions-title'}> {step.instruction} </span>
                    <br />
                    {step.distance > 0 &&
                      <span className="instructions-miles"> {step.distance} mi </span>
                    }
                  </p>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  }

  getSteps = (instructions) => {
    var steps = [];
    if (instructions && instructions.legs) {
      instructions.legs.map(item => {
        return item.steps.map(step => {
          // convert to miles
          const distance = (step.distance * 0.000621371192).toFixed(2);
          var modifier = "";
          var type = "";
          var icon = "";

          if (step.maneuver.type !== undefined) {
            type = step.maneuver.type.replace(/\s/g, '_');
            icon = `${process.env.PUBLIC_URL}/mapbox/direction_${type}.png`
          }
        
          if (step.maneuver.modifier !== undefined) {
            modifier = step.maneuver.modifier.replace(/\s/g, '_');
            icon = `${process.env.PUBLIC_URL}/mapbox/direction_${type}_${modifier}.png`
          }

          return steps.push({ instruction: step.maneuver.instruction, modifier: modifier, distance: distance, type: step.maneuver.type, icon: icon })
        });
      });

      return steps;
    }
  }
}

export default TaskInstructions;
