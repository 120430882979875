import React from 'react';
import * as Sentry from '@sentry/browser';

const errorBoundary = (Component) => {
  class ErrorBoundary extends React.Component {
    componentDidCatch(err, info) {
      if (process.env.NODE_ENV === 'production') {
        Sentry.captureException(err);
      }
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return {};
    }

    render() {
      return (<Component {...this.props} />);
    }
  }

  return ErrorBoundary;
};

export default errorBoundary;
