import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Container, Button } from 'reactstrap';
import { TaskForm, TaskInvoiceModal, TaskCancelModal } from 'components/Task';
import { NotificationManager, NotificationPosition } from 'components/Layout';
import withOrder from 'components/WithOrder';
import { actions as taskActions } from 'reducers/task';
import { actions as destinationActions } from 'reducers/destination';
import { actions as instructionsActions } from 'reducers/instructions';
import * as collections from 'constants/collections';
import * as destinationKeys from 'constants/destinations';
import * as taskStatus from 'constants/taskStatus';
import { homePath } from 'constants/routes';
import { now, getLicenses } from 'utils';

class ViewOrder extends PureComponent {
  state = {
    isLoaded: false,
    showInvoice: false,
    cancelNote: null,
    recipients: this.props.recipients || [],
    terminals: this.props.terminals || [],
    vehicles: this.props.vehicles || [],
    recipientLicenses: [],
    senderLicenses: [],
    brands: this.props.brands || [],
  };

  componentDidMount() {
    const { task } = this.props;
    if (task) {
      this.setFormData(task);
    }
  }

  setFormData = async task => {
    const {
      firestore,
      onEditTask,
      onEstimatedPickupDate,
      onEstimatedPickupTime,
      onEstimatedDeliveryDate,
      onEstimatedDeliveryTime,
      onVehicleChanged,
      onTerminalChanged,
      onRecipientLicenseChanged,
      onSenderLicenseChanged,
      onBrandChanged,
    } = this.props;

    onEditTask(task);
    onEstimatedPickupDate(
      moment.unix(task.estimatedPickup).format('YYYY-MM-DD'),
    );
    onEstimatedPickupTime(moment.unix(task.estimatedPickup).format('HH:mm'));
    onEstimatedDeliveryDate(
      moment.unix(task.estimatedDelivery).format('YYYY-MM-DD'),
    );
    onEstimatedDeliveryTime(
      moment.unix(task.estimatedDelivery).format('HH:mm'),
    );

    const origin = `${task.origin ? task.origin.owner : task.originId}, ${
      task.originId
    }`;
    const destination = `${
      task.destination ? task.destination.owner : task.destinationId
    }, ${task.destinationId}`;
    const vehicleId =
      (task && task.vehicleId) ||
      (task && task.vehicle && task.vehicle.id) ||
      (task.driver && task.driver.vehicleId);

    const recipientLicenses = await getLicenses(
      firestore,
      task.recipientId,
      'recipientLicenses',
    );
    const senderLicenses = await getLicenses(
      firestore,
      task.senderId,
      'senderLicenses',
    );

    this.setState({ recipientLicenses });
    this.setState({ senderLicenses });
    // Not in list, get sender from databse and ask if sender is disabled
    await this.getTaskItem(task.senderId, collections.RECIPIENT);
    await this.getTaskItem(task.recipientId, collections.RECIPIENT);
    await this.getTaskItem(vehicleId, collections.VEHICLE);
    await this.getTaskItem(task.terminalId, collections.TERMINAL);
    await this.getTaskItem(task.brandId, collections.BRAND);
    await this.getTaskInstructions(task.instructionsId);

    this.handleBusinessValue(destination, destinationKeys.TASK_DESTINATION);
    this.handleBusinessValue(origin, destinationKeys.TASK_ORIGIN);

    const vehicle = this.state.vehicles.find(
      vehicle => vehicle.id === vehicleId,
    );
    const terminal = this.state.terminals.find(t => t.id === task.terminalId);
    const recipientLicense =
      (recipientLicenses &&
        recipientLicenses.find(l => l.id === task.recipientLicenseId)) ||
      [];
    const senderLicense =
      (senderLicenses &&
        senderLicenses.find(l => l.id === task.senderLicenseId)) ||
      [];
    const brand = this.state.brands.find(l => l.id === task.brandId);

    vehicle && onVehicleChanged(vehicle);
    terminal && onTerminalChanged(terminal.id);
    recipientLicense && onRecipientLicenseChanged(recipientLicense);
    senderLicense && onSenderLicenseChanged(senderLicense);
    brand && onBrandChanged(brand);

    this.setState({ isLoaded: true });
  };

  getTaskItem = async (itemId, collection) => {
    if (!itemId || collection) {
      return;
    }
    const { firestore } = this.props;
    const isItemEnabled = this.state[collection].find(r => r.id === itemId);
    if (!isItemEnabled) {
      const document = await firestore
        .collection(collection)
        .doc(itemId)
        .get();
      const item = document.data();

      if (item && item.disabled) {
        item.id = itemId;
        this.setState(prevState => ({
          [collection]: [...prevState[collection], item],
        }));
      }
    }
  };

  getTaskInstructions = async instructionsId => {
    if (!instructionsId) {
      return;
    }

    const { firestore, onTaskInstrunctionsChanged } = this.props;
    const item = await firestore
      .collection(collections.ORDER_INSTRUCTIONS)
      .doc(instructionsId)
      .get();

    if (item.empty) {
      return null;
    }
    const orderInstructions = item.data();
    orderInstructions && onTaskInstrunctionsChanged(orderInstructions);
  };

  handleBusinessValue(
    selectedDestination,
    on = destinationKeys.TASK_DESTINATION,
  ) {
    const {
      destinations,
      onRecipientChanged,
      onDestinationChanged,
      onSenderChanged,
      onOriginChanged,
      onRecipientInfoChanged,
      onDestinationEdit,
    } = this.props;

    const [recipientId, destinationId] = selectedDestination
      .split(',')
      .map(id => id.trim());
    const recipient = this.state.recipients.find(r => r.id === recipientId);
    const destination = destinations.find(d => d.id === destinationId);
    if (on === destinationKeys.TASK_DESTINATION) {
      onRecipientChanged(recipient);
      onDestinationChanged(destination);
      onDestinationEdit(destinationKeys.TASK_DESTINATION, null);
      onDestinationEdit(destinationKeys.TASK_DESTINATION, destination);
      !recipient && onRecipientInfoChanged(on, { name: selectedDestination });
    } else if (on === destinationKeys.TASK_ORIGIN) {
      onSenderChanged(recipient);
      onOriginChanged(destination);
      onDestinationEdit(destinationKeys.TASK_ORIGIN, null);
      onDestinationEdit(destinationKeys.TASK_ORIGIN, destination);
      !recipient && onRecipientInfoChanged(on, { name: selectedDestination });
    }
  }

  cancelOrder = async () => {
    const {
      firestore,
      task: { id: taskId },
      history,
    } = this.props;
    await firestore
      .collection(collections.TASK)
      .doc(taskId)
      .update({
        status: taskStatus.CANCELED,
        cancelNote: this.state.cancelNote || '-',
        'details.events': firestore.FieldValue.arrayUnion({
          name: 'canceled',
          time: now(),
        }),
      });

    this.toggleCancelDialog();

    history.push(homePath);
    NotificationManager.success(
      'Order has been canceled!',
      null,
      NotificationPosition.TOP_LEFT,
    );
  };

  showInvoice = showInvoice => {
    this.setState({ showInvoice });
  };

  toggleCancelDialog = () => {
    this.setState(prevState => ({
      cancelDialog: !prevState.cancelDialog,
    }));
  };

  render() {
    const { task, drivers, destinations, currentUser, history } = this.props;

    const { showInvoice, cancelDialog, isLoaded } = this.state;

    if (task) {
      return (
        <Container>
          <div className='d-flex justify-content-between align-items-center'>
            <h1>
              {task.status.toUpperCase()}{' '}
              ORDER&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Order #
              {task.orderNumber || task.id}
            </h1>
            {task.status === taskStatus.COMPLETED && (
              <Button color='primary' onClick={() => this.showInvoice(true)}>
                Create Invoice
              </Button>
            )}
            {task.status === taskStatus.PROGRESS && (
              <Button color='danger' onClick={this.toggleCancelDialog}>
                Cancel Order
              </Button>
            )}
          </div>
          {isLoaded && (
            <TaskForm
              currentUser={currentUser}
              destinations={destinations}
              drivers={drivers}
              recipients={this.state.recipients}
              terminals={this.state.terminals}
              vehicles={this.state.vehicles}
              recipientLicenses={this.state.recipientLicenses}
              senderLicenses={this.state.senderLicenses}
              toggle={() => history.push(homePath)}
              brands={this.state.brands}
            />
          )}
          {showInvoice && (
            <TaskInvoiceModal
              order={task}
              open={showInvoice}
              toggle={() => this.showInvoice(false)}
            />
          )}
          {cancelDialog && (
            <TaskCancelModal
              isOpen={cancelDialog}
              onClose={this.toggleCancelDialog}
              cancelNote={this.state.cancelNote}
              onChangeInput={cancelNote => this.setState({ cancelNote })}
              confirmAction={this.cancelOrder}
            />
          )}
        </Container>
      );
    }
  }
}

const mapDispatchToProps = {
  ...taskActions,
  ...destinationActions,
  ...instructionsActions,
};

const Connected = compose(
  withRouter,
  withOrder,
  connect(null, mapDispatchToProps),
)(ViewOrder);

export default Connected;
