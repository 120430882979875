import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty } from 'react-redux-firebase';
import * as collections from 'constants/collections';
import Loading from 'components/Loading';
import { OrderNotFound } from 'components/NotFound';

const storeAs = 'order';

const withOrder = (Component) => {
  class WithOrder extends React.Component {
    render() {
      const { order, ...rest } = this.props;

      if (order === undefined) {
        return <Loading message="Loading Order" />;
      }

      if (isEmpty(order)) {
        return <OrderNotFound />;
      }

      return <Component {...rest} task={order} />;
    }
  }

  const mapStateToProps = (state, props) => {
    const { firestore: { data } } = state;
    const { match: { params: { id } } } = props;

    let order = data[`${storeAs}-${id}`];

    if (order) {
      order = {
        id,
        ...order,
        origin: (data.destinations[order.originId] || null),
        destination: (data.destinations[order.destinationId] || null),
      };
    }

    return { order };
  };

  return compose(
    firestoreConnect(props => {
      const { match: { params: { id } } } = props;
      return [{
        collection: collections.TASK,
        storeAs: `${storeAs}-${id}`,
        doc: id,
      }];
    }),
    connect(mapStateToProps),
  )(WithOrder);
};

export default withOrder;
