import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, withFirebase, withFirestore } from 'react-redux-firebase';
import { Button } from 'reactstrap';
import WithLoader from 'components/WithLoader';
import User from './User';
import { actions as accountActions } from 'reducers/account';
import { actions as fetchingActions } from 'reducers/fetching';
import * as collections from 'constants/collections';
import * as roles from 'constants/roles';

class Drivers extends User {
  static defaultProps = {
    role: roles.DRIVER
  }
  getAccounts() {
    return this.props.drivers;
  }

  getNewAccountButton() {
    return <Button color="primary" onClick={() => this.showAccount()}>New Driver</Button>;
  }
}

const ALL_USERS = 'ALL_USERS';

const mapStateToProps = ({ account, firestore: { ordered } }) => {
  const { terminals } = ordered;
  const users = ordered[ALL_USERS] || [];
  const drivers = users.filter(u => u.role === roles.DRIVER && !u.disabled);

  return {
    account,
    drivers,
    terminals
  }
};

const mapDispatchToProps = ({
  ...accountActions,
  ...fetchingActions,
});

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  firestoreConnect(() => [
    {
      collection: collections.USER,
      orderBy: ['firstName', 'asc'],
      storeAs: ALL_USERS
    },
  ]),
  connect(mapStateToProps, mapDispatchToProps),
)(Drivers);

export default WithLoader(FirestoreConnected);
