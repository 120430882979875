import { LabelPin } from '.';
import { summerSky } from 'constants/colors';

class WarehousePin extends LabelPin {
  constructor(props) {
    super(props);

    this.propsDefault.className = 'warehouse';
    this.propsDefault.fill = props.color || summerSky;
    this.state = { text: 'A' };
  }
}

export default WarehousePin;
