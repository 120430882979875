import TaskList from './TaskList';
import TaskItem from './TaskItem';
import TaskModal from './TaskModal';
import TaskForm from './TaskForm';
import TaskFormModal from './TaskFormModal';
import TaskReportModal from './TaskReportModal';
import TaskInvoiceModal from './TaskInvoiceModal';
import TaskCancelModal from './TaskCancelModal';
import TaskInstructions from './TaskInstructions';

export {
  TaskItem,
  TaskModal,
  TaskForm,
  TaskFormModal,  // DEPRECATED
  TaskReportModal,
  TaskInvoiceModal,
  TaskCancelModal,
  TaskInstructions
}

export default TaskList;
