import React from 'react';

import Pin from './Pin';
import CarPin from './CarPin';
import LabelPin from './LabelPin';
import DestinationPin from './DestinationPin';
import WarehousePin from './WarehousePin';
import DetourPin from './DetourPin';

import CarInfo from './CarInfo';
import MaxSpeedInfo from './MaxSpeedInfo';

import PolylineOverlay from './PolylineOverlay';

import MonitoringMap from './MonitoringMap';
import RouteMap from './RouteMap';
import DriverRouteMap from './DriverRouteMap';
import ControlPanel from './ControlPanel';
import TaskInfo from './TaskInfo';

import { Marker } from 'react-map-gl';

export {
  Pin,
  CarPin,
  LabelPin,
  DestinationPin,
  WarehousePin,
  DetourPin,

  CarInfo,
  PolylineOverlay,

  RouteMap,
  MonitoringMap,
  DriverRouteMap,

  ControlPanel,
  TaskInfo,
  MaxSpeedInfo
};

const PinType = {
  DESTINATION: DestinationPin,
  ORIGIN: WarehousePin,
};

export const getMarker = (point, type='DESTINATION', color) => {
  if (!point) {
    return;
  }

  const ComponentPin = PinType[type];
  return <Marker latitude={point.lat} longitude={point.lon}>
      <ComponentPin color={color} />
  </Marker>;
}

export const getDestinationMarker = (point) => {
  return getMarker(point);
}

export const getOriginMarker = (point, color = false) => {
  return getMarker(point, 'ORIGIN', color);
}

export default MonitoringMap;
