import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, withFirebase, withFirestore, isLoaded } from 'react-redux-firebase';
import 'firebase/firestore';
import { Button, CustomInput } from 'reactstrap';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import DataTable from 'react-data-table-component';
import { NotificationManager, NotificationPosition } from 'components/Layout';
import Select from 'components/Select';
import WithLoader from 'components/WithLoader';
import { actions as vehicleActions } from 'reducers/vehicle';
import { actions as fetchingActions } from 'reducers/fetching';
import * as collections from 'constants/collections';
import { collator, now, deleteOrDisableDocument } from 'utils';
import _ from 'lodash';

const styles = reactCSS({
  'default': {
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
    overflowHide: {
      overflow: 'visible'
    }
  },
});

const filterOptions = [
  { label: 'Show All', value: 'all' },
  { label: 'Show Enabled', value: 'enabled' },
  { label: 'Show Disabled', value: 'disabled' },
];

class Vehicles extends Component {
  constructor(props) {
    super(props);
    this.showList = this.showList.bind(this);

    this.state = {
      activeDiv: 'list',
      filter: 'all',
      displayColorPicker: false,
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  render() {
    const { vehicles, terminals } = this.props;

    if (isLoaded(vehicles) && isLoaded(terminals)) {
      return (
        <div>
          <div>
            {this.state.activeDiv !== 'form' ? (
              <div className="d-flex justify-content-between align-items-center">
                <Button color="primary" onClick={() => this.showVehicle()}>
                  New Vehicle
                </Button>
                <CustomInput
                  type='select'
                  id='filter-vehicles'
                  name='filter-vehicles'
                  style={{ width: '200px' }}
                  className="my-2"
                  onChange={event => this.setState({ filter: event.target.value })}
                  value={this.state.filter}
                >
                  {filterOptions.map(item => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))}
                </CustomInput>
              </div>
            ) : (
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => this.showList()}
                >
                  Back
                </Button>
              )}
          </div>
          {this.state.activeDiv === 'form' ? this.getForm() : this.getList()}
        </div>
      );
    }
    return '';
  }

  handleChange(event) {
    const { firestore } = this.props;

    if (
      window.confirm(
        "Please confirm to " +
        (event.target.checked ? "enable" : "disable") +
        " this item ?"
      )
    ) {
      firestore
        .collection(collections.VEHICLE)
        .doc(event.target.id)
        .update({
          disabled: !event.target.checked
        })
    } else {
      event.target.checked = !event.target.checked;
    }
  }

  async deleteVehicle(vehicle) {
    if (window.confirm('Please confirm to delete this Vehicle')) {
      const disableReason = 'It has order(s) assigned to it and can not be deleted';
      const { firestore, tasks } = this.props;
      const exists = tasks.some(o => o.vehicleId === vehicle.id);

      await deleteOrDisableDocument(firestore
        , collections.VEHICLE
        , vehicle.id
        , exists
        , disableReason
        , NotificationManager
        , NotificationPosition.TOP_LEFT
      );
    }
  }

  showVehicle = vehicle => {
    const { onVehicleEdit, onVehicleNew } = this.props;
    if (!!vehicle) {
      onVehicleEdit(vehicle);
    } else {
      onVehicleNew();
    }

    setTimeout(function () {
      this.setState({ activeDiv: 'form' });
    }.bind(this), 200)
  };

  getList = () => {
    const { vehicles } = this.props;
    const { filter } = this.state;
    const filteredVehicles = vehicles.filter(vehicle => {
      switch(filter) {
        case 'enabled':
          return !vehicle.disabled;
        case 'disabled':
          return vehicle.disabled;
        default:
          return true;
      }
    });

    filteredVehicles.sort((a, b) => collator.compare(a.description, b.description));
    
    const onRowClicked = vehicle => this.showVehicle(vehicle);

    const columns = [
      {
        name: '#',
        minWidth: '20px !important',
        maxWidth: '40px !important',
        center: true,
        compact: true,
        selector: 'idx',
        sortable: true,
      },
      {
        name: 'Description',
        compact: true,
        left: true,
        width: '190px !important',
        wrap: true,
        selector: 'description',
        sortable: true
      },
      {
        name: 'Model',
        compact: true,
        left: true,
        width: '95px !important',
        selector: 'model',
        sortable: true
      },
      {
        name: 'LicensePlate',
        compact: true,
        left: true,
        width: '100px !important',
        selector: 'licensePlate',
        sortable: true
      },
      {
        name: 'Color',
        compact: true,
        left: true,
        button: true,
        cell: vehicle => <button
          className={"btn btn-link"}
          href="#"
          onClick={() => this.showVehicle(vehicle)} >
          <div style={styles.swatch} onClick={() => this.showVehicle(vehicle)} >
            <div style={{ background: `${vehicle.color}` }} className="vehicle-color-container" />
          </div>
        </button>
      },
      {
        name: 'Actions',
        compact: true,
        left: true,
        button: true,
        cell: vehicle => {
          return <>
            <CustomInput
              type="switch"
              id={vehicle.id}
              name={vehicle.id}
              defaultChecked={!vehicle.disabled}
              onChange={this.handleChange.bind(this)}
            />
            <button
              className="btn btn-link"
              href="#"
              onClick={() => this.deleteVehicle(vehicle)}>
              <i className="fa fa-trash" />
            </button>
          </>
        }
      },
    ]

    return (
      <DataTable
        columns={columns}
        dense
        data={filteredVehicles.map((item, idx) => ({ idx: (idx + 1), ...item }))}
        highlightOnHover
        noHeader
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50]}
        responsive
        pointerOnHover
        onRowClicked={onRowClicked}
      />
    );
  };

  showList() {
    this.setState({ activeDiv: 'list' });
    const { onVehicleNew } = this.props;
    onVehicleNew();
  }

  getVehicleColor = () => {
    const { vehicles } = this.props;
    // 16777215 = fff color bytes equivalent (or (256*256*256)-1)
    let color = '#' + Math.floor(Math.random() * 16777215).toString(16);
    const exists = vehicles.some(v => v.color === color);

    if (exists) {
      color = this.getVehicleColor();
    }
    return color;
  }

  saveVehicle = async () => {
    const { vehicle, firestore, clear, onVehicleNew } = this.props;

    const vehicleData = _.pick(vehicle, [
      'model',
      'maker',
      'licensePlate',
      'color',
      'description',
      'disabled',
      'vinNumber',
      'terminalLocationId',
    ]);

    if (!vehicleData.color) {
      vehicleData.color = this.getVehicleColor();
    }

    const data = {
      disabled: false,
      ...vehicleData,
      ...(!vehicle.id ? { createdAt: now() } : {}),
      modifiedAt: now(),
    };

    this.props.setLoading(true);
    if (vehicle.id) {
      await firestore
        .collection(collections.VEHICLE)
        .doc(vehicle.id)
        .update(data)
        .then(() => {
          this.showList();
          onVehicleNew();
          NotificationManager.success('Vehicle Updated!', null, NotificationPosition.TOP_LEFT);
        });
    } else {
      const result = await firestore.collection(collections.VEHICLE).where('vinNumber', '==', data.vinNumber).get();
      if (!result.empty) {
        NotificationManager.error(
          'There is already a vehicle registered with this VIN number.',
          null,
          NotificationPosition.TOP_LEFT
        );
        this.props.setLoading(false);
        return;
      }

      await firestore.add(collections.VEHICLE, data).then(() => {
        //all good
        clear();
        this.showList();
        NotificationManager.success('Vehicle Created!', null, NotificationPosition.TOP_LEFT);
      });
    }

    this.props.setLoading(false);
  };

  getForm = () => {
    const { onVehicleDescriptionChanged,
      onVehicleModelChanged,
      onVehicleLicensePlateChanged,
      onVehicleColorChanged,
      onVehicleMakerChanged,
      onVehicleVinNumberChanged,
      onVehicleTerminalLocationChanged,
      vehicle,
      terminals,
    } = this.props;

    return (
      <div>
        <form
          className="p-t-15"
          onSubmit={e => {
            e.preventDefault();
            this.saveVehicle();
          }}>
          <div className="form-group form-group-default">
            <label>Vehicle Description</label>
            <div className="controls">
              <input
                type="text"
                name="description"
                placeholder="Description"
                className="form-control"
                required
                value={vehicle.description || ""}
                onChange={event => {
                  onVehicleDescriptionChanged(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="form-group form-group-default" style={styles.overflowHide}>
            <label>Vehicle Color</label>
            <div className="controls">
              <div>
                <div style={styles.swatch} onClick={this.handleClick} >
                  <div style={{ background: `${vehicle.color}` }} className="vehicle-color-container" />
                </div>
                <span className="ml-1 align-top"> {vehicle.color ? vehicle.color : 'Please choose a color'} </span>
                {this.state.displayColorPicker &&
                  <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleClose} />
                    <SketchPicker color={vehicle.color}
                      onChange={color => {
                        onVehicleColorChanged(color.hex)
                        this.setState({
                          ...this.state,
                          color: color.hex
                        })
                      }} />
                  </div>}
              </div>
            </div>
          </div>
          <div className="form-group form-group-default">
            <label>Vehicle Maker</label>
            <div className="controls">
              <input
                type="text"
                name="maker"
                placeholder="Maker"
                className="form-control"
                required
                value={vehicle.maker || ""}
                onChange={event => {
                  onVehicleMakerChanged(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="form-group form-group-default">
            <label>Vehicle Model</label>
            <div className="controls">
              <input
                type="text"
                name="model"
                placeholder="Model"
                className="form-control"
                required
                value={vehicle.model || ""}
                onChange={event => {
                  onVehicleModelChanged(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="form-group form-group-default">
            <label>License PLate</label>
            <div className="controls">
              <input
                type="text"
                name="licensePlate"
                placeholder="License Plate"
                className="form-control"
                required
                value={vehicle.licensePlate || ""}
                onChange={event => {
                  onVehicleLicensePlateChanged(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="form-group form-group-default">
            <label>VIN Number</label>
            <div className="controls">
              <input
                type="text"
                name="vinNumber"
                placeholder="VIN Number"
                className="form-control"
                required
                maxLength="17"
                value={vehicle.vinNumber || ""}
                onChange={event => {
                  onVehicleVinNumberChanged(event.target.value.toUpperCase());
                }}
              />
            </div>
          </div>

          <div className="form-group form-group-default">
            <label>Terminal Location</label>
            <div className="controls">
              <Select
                name="driver"
                id="driver"
                value={vehicle.terminalLocationId}
                options={terminals.map(({ name: label, id: value }) => ({ label, value })) || []}
                onChange={event => onVehicleTerminalLocationChanged(event.target.value)} />
            </div>
          </div>

          <Button color="primary" type="submit">
            {vehicle.id ? "Update" : "Create"}
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={() => this.showList()}>
            Cancel
          </Button>
          <br />
        </form>
      </div >
    );
  };

}
const mapStateToProps = ({
  vehicle,
  firestore: {
    ordered: { vehicles, terminals, tasks }
  },
}) => ({
  vehicle,
  vehicles,
  terminals,
  tasks
});

const mapDispatchToProps = {
  ...vehicleActions,
  ...fetchingActions,
};

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  firestoreConnect([
    {
      collection: collections.VEHICLE,
      orderBy: ["description", "asc"]
    }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Vehicles);

export default WithLoader(FirestoreConnected);
