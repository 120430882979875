import React, { Component, Fragment } from 'react';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './custom-date-range-picker.css';
import { Button, ButtonGroup } from 'reactstrap';
import moment from 'moment';
import * as colors from 'constants/colors';

const START_DATE = 'startDate';
const END_DATE = 'endDate';

export default class CustomDateRangePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
      startDate: props.startDate || moment(),
      endDate: props.endDate || moment(),
      showDatePicker: false,
      forceUpdate: false,
    };
    this.rangeWrapperRef = React.createRef();
  }

  // We need to do this `hack` to force re render the calendar
  // day content with the new highlighted dates
  componentWillReceiveProps(nextProps) {
    const { highlightedDates } = this.props;
    if (nextProps.highlightedDates.length !== highlightedDates.length) {
      this.setState({ forceUpdate: true });

      setTimeout(
        function () {
          this.setState({ forceUpdate: false });
        }.bind(this),
        100
      );
    }
  }

  onDatesChange = ({ startDate, endDate }) => {
    if (!startDate) {
      startDate = moment();
    }
    if (!endDate) {
      if (!!startDate) {
        endDate = moment(startDate);
      } else {
        endDate = moment();
      }
    }

    const dateRange = {
      startDate: startDate.clone().startOf('day'),
      endDate: endDate.clone().endOf('day'),
    };

    this.setState({ ...dateRange })
    this.props.handleDateChange(dateRange);
  };

  onFocusChange = focusedInput => {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput
    });
  };

  clearDateSelection = () => {
    const dateRange = {
      startDate: moment(),
      endDate: moment()
    };
    this.setState({ ...dateRange });
    this.props.handleDateChange(dateRange);
  };

  setDateSelection = () => {
    this.setState({
      showDatePicker: false
    });
  };

  renderControls = () => {
    return (
      <section className="calendar_controls">
        <Button size="sm" onClick={this.clearDateSelection}>Clear</Button>
        <Button size="sm" onClick={this.setDateSelection}>Apply</Button>
      </section>
    );
  };

  renderInitialDayContents = (day) => {
    return this.renderDayContents(day);
  }

  renderUpdatedDayContents = (day) => {
    return this.renderDayContents(day);
  }

  renderDayContents = (day) => {
    const { highlightedDates } = this.props;
    const { startDate, endDate } = this.state;

    // If not highlightedDates or day is in the selected range, keep de default colors
    if ((highlightedDates === undefined || !highlightedDates.length) ||
      (day.isBetween(startDate.startOf('day'), endDate.endOf('day'), 'days', '[]'))) {
      return <span>{day && day.format('D')}</span>;
    };

    const hasOrders = highlightedDates.find(date => date.isSame(day, 'day'));
    const dayColor = hasOrders ? colors.green : colors.lightGray;

    const styles = {
      backgroundColor: dayColor,
      width: 'inherit',
      height: 'inherit',
      display: 'table-cell',
      verticalAlign: 'middle',
    };

    return (
      <div style={styles}>
        <span>{day && day.format('D')}</span>
      </div>
    );
  }

  renderDateRangeString(startDate = moment(), endDate = moment()) {
    return (
      <Button className={'range-wrapper'}
        ref={this.rangeWrapperRef}
        onClick={(event) => {
          event.stopPropagation();
          this.setState({ showDatePicker: !this.state.showDatePicker });
        }}>
        {startDate.isSame(endDate, 'day') ? this.singleDateSelected(startDate, endDate) : this.rangeSelected(startDate, endDate)}
      </Button>
    )
  }

  singleDateSelected(startDate) {
    return (
      <div><small className={'selected-month'}>{startDate.format("MMM").toUpperCase()}</small><br />{startDate.format("D")}</div>
    )
  }

  rangeSelected(startDate, endDate) {
    return (
      <Fragment>
        <div><small className={'selected-month'}>{startDate.format("MMM").toUpperCase()}</small><br />{startDate.format("D")}</div>
        <div className={'separator'}>-</div>
        <div><small className={'selected-month'}>{endDate.format("MMM").toUpperCase()}</small><br />{endDate.format("D")}</div>
      </Fragment>
    )
  }

  render() {
    const { showInputs, onPrevMonthChange, onNextMonthChange } = this.props;
    const { focusedInput, startDate, endDate, forceUpdate } = this.state;

    return (
      <div>
        {showInputs && (
          <ButtonGroup className={'calendar-group'} style={{ marginRight: '10px' }}>
            <Button className={'narrow-button'} size="sm"
              onClick={() =>
                this.onDatesChange({ startDate: moment(startDate).add(-1, 'days'), endDate })
              }>
              <i className="fa fa-chevron-left"></i>
            </Button>
            {this.renderDateRangeString(startDate, endDate)}
            <Button className={'narrow-button'} size="sm"
              onClick={() =>
                this.onDatesChange({ startDate, endDate: moment(endDate).add(1, 'days') })
              }>
              <i className="fa fa-chevron-right"></i>
            </Button>
          </ButtonGroup>
        )}

        {this.state.showDatePicker && (
          <DayPickerRangeController
            onFocusChange={this.onFocusChange}
            onDatesChange={this.onDatesChange}
            onPrevMonthClick={onPrevMonthChange}
            onNextMonthClick={onNextMonthChange}
            focusedInput={focusedInput}
            startDate={startDate}
            endDate={endDate}
            keepOpenOnDateSelect={false}
            enableOutsideDays={false}
            hideKeyboardShortcutsPanel={true}
            numberOfMonths={2}
            onOutsideClick={(event) => {
              if (event && event.path && !event.path.map(p => p.className).includes('calendar-group btn-group')) {
                this.setState({ showDatePicker: false });
              }
            }}
            renderCalendarInfo={this.renderControls}
            renderDayContents={!forceUpdate
              ? this.renderInitialDayContents
              : this.renderUpdatedDayContents
            }
            minimumNights={0}
          />
        )}
      </div>
    );
  }
}
