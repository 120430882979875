import React, { Component } from 'react';
import { BarChart } from "components/Reports";
import { Col, Container, Row } from "reactstrap";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { connect } from "react-redux";
import * as collections from 'constants/collections';
import Loading from "components/Loading";
import moment from "moment-timezone";
import days from "constants/days";
import _ from 'lodash';

class Reports extends Component {
  /**
   * Will parse data from firebase to be exactly in an array of 7 values, to plot exactly a week
   * @returns {{ordersCount: [], recipientsCount: []}}
   */
  getOrderStats() {
    const {orderStats} = this.props;
    const today = moment.tz('America/Detroit');
    const from_date = today.startOf('isoWeek');
    const ordersCount = [];
    const recipientsCount = [];
    const driversCount = [];
    const financialAmounts = [];
    const distance = [];
    for(let i = 0; i < 7; i++) {
      const dayData = orderStats.filter(item => moment.tz(item.date.seconds*1000, 'America/Detroit').isSame(from_date, 'day'));
      ordersCount.push({label: days[i].label, value: dayData.length ? dayData[0].count : 0});
      recipientsCount.push({label: days[i].label,
        value: dayData.length && dayData[0].recipients ? dayData[0].recipients.length : 0});
      driversCount.push({label: days[i].label,
        value: dayData.length && dayData[0].drivers ? dayData[0].drivers.length : 0});
      financialAmounts.push({label: days[i].label, value: dayData.length ? dayData[0].financial : 0});
      distance.push({label: days[i].label, value: dayData.length ? dayData[0].distance * 0.62137 : 0});
      from_date.add(1, 'days');
    }
    return {ordersCount, recipientsCount, driversCount, financialAmounts, distance};
  }

  /**
   * Will parse data from firebase to be exactly in an array of 7 values, to plot exactly a week
   * @returns {{hoursCount: []}}
   */
  getDriverStats() {
    const MILLISECONDS_IN_HOUR = 3600000;
    const {driverStats} = this.props;
    const today = moment.tz('America/Detroit');
    const from_date = today.startOf('isoWeek');
    const hoursCount = [];

    for(let i = 0; i < 7; i++) {
      const dayData = driverStats.filter(item => moment.tz(item.date.seconds*1000, 'America/Detroit').isSame(from_date, 'day'));

      hoursCount.push({label: days[i].label, value: dayData.length ? dayData[0].hours / MILLISECONDS_IN_HOUR : 0})
      from_date.add(1, 'days');
    }
    return {hoursCount};
  }

  getMaxYTicks = (data, interval = 10, minValue) => {
    const maxValue = _.maxBy(data, 'value').value || 0; // Get max value
    const roundMaxValue = Math.ceil((maxValue + 1) / interval ) * interval; // Get nearest number to the interval
    
    if (minValue) {
      return roundMaxValue < minValue ? minValue : roundMaxValue;
    }

    return roundMaxValue;
  }

  render() {
    if (!isLoaded(this.props.orderStats) || !isLoaded(this.props.driverStats)) {
      return (<Loading />);
    }

    const {ordersCount, recipientsCount, driversCount, financialAmounts, distance} = this.getOrderStats();
    const {hoursCount} = this.getDriverStats();

    return (
      <div className="d-flex flex-column full-height justify-content-center align-items-center">
        <h1 style={{flexGrow: 0}}>Reports</h1>
        <Container style={{flexGrow: 1, height: '50%'}}>
          <Row style={{ paddingBotton: '50px'}}>
            <Col className={'p-2'}>
              <div style={{backgroundColor: 'black'}}  className={'p-2'}>
                <h3 className="text-center  text-white">ORDERS</h3>
                <div style={{height: '300px',}}>
                  <BarChart
                    maxYTicks={this.getMaxYTicks(ordersCount)}
                    data={ordersCount}
                    title={'orders'}
                    color="#f8d053"
                  />
                </div>
              </div>
          </Col>
            <Col className={'p-2'}>
              <div style={{backgroundColor: 'black'}}  className={'p-2'}>
                <h3
                  className="text-center text-white">DRIVERS</h3>
                <div style={{height: '300px'}}>
                  <BarChart
                    maxYTicks={this.getMaxYTicks(driversCount)}
                    data={driversCount}
                    title={'drivers'}
                    color="#f8d053"
                  />
                </div>
              </div>
            </Col>
            <Col className={'p-2'}>
              <div style={{backgroundColor: 'black'}}  className={'p-2'}>
                <h3
                  className="text-center text-white">CUSTOMERS</h3>
                <div style={{height: '300px'}}>
                  <BarChart
                    maxYTicks={this.getMaxYTicks(recipientsCount)}
                    data={recipientsCount}
                    title={'customers'}
                    color="#f8d053"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className={'p-2'}>
              <div style={{backgroundColor: 'black'}}  className={'p-2'}>
                <h3 className="text-center text-white">DRIVING DISTANCE</h3>
                <div style={{height: '300px'}}><BarChart
                    data={distance}
                    title={'distance'}
                    color="#f8d053"
                /></div>
              </div>
          </Col>
            <Col className={'p-2'}>
              <div style={{backgroundColor: 'black'}}  className={'p-2'}>
                <h3 className="text-center text-white">FINANCIALS</h3>
                <div style={{height: '300px'}}>
                  <BarChart
                    maxYTicks={this.getMaxYTicks(financialAmounts, 1000, 10000)}
                    stepSize={1000}
                    data={financialAmounts}
                    title={'financial'}
                    color="#f8d053"
                  />
                </div>
              </div>
            </Col>
            <Col className={'p-2'}>
              <div style={{backgroundColor: 'black'}}  className={'p-2'}>
                <h3 className="text-center text-white">DRIVER HOURS</h3>
                <div style={{height: '300px'}}><BarChart
                    data={hoursCount}
                    title={'hours'}
                    color="#f8d053"
                /></div>
              </div>

            </Col></Row>
        </Container>
      </div>
    );
  }
}

const FirestoreConnected = compose(
    firestoreConnect((props) => ([
      {
        collection: collections.ORDER_STATS,
      },
      {
        collection: collections.DRIVER_STATS,
      },
    ])),
    connect(state => ({
          orderStats: state.firestore.ordered.orderStats,
          driverStats: state.firestore.ordered.driverStats
        })
    )
)(Reports);
export default FirestoreConnected;