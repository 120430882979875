import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Avatar extends Component {
  static propTypes = {
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    id: PropTypes.string,
    user: PropTypes.object,
  };

  render() {
    const { id, color, backgroundColor, user } = this.props; 
    const name = `${user.displayName || `${user.firstName || ''} ${user.lastName || ''}`}`.trim();
    const style = {width: 32, height: 32, cursor: 'pointer', color: color || 'white', backgroundColor : backgroundColor || 'trasparent',};

    if (user.avatarUrl) {
      return <img src={user.avatarUrl} style={style} id={id} alt={name} />;
    }

    var nameParts = (name || '').trim().toUpperCase();
    if (nameParts.length > 0) {
      nameParts = nameParts.split(' ');
    } else if (user.email && user.email.length > 0) {
      nameParts = [user.email.toUpperCase()];
    }

    if (nameParts.length === 1) {
      return <div style={style} id={id} alt={name}>{nameParts[0].charAt(0)}</div>
    } else if (nameParts.length > 1) {
      return <div style={style} id={id} alt={name}>{nameParts[0].charAt(0)}{nameParts[1].charAt(0)}</div>
    } else {
      return <div style={style} id={id} alt={name}>
        <i className="fa fa-user fs-16"></i>
      </div>
    }
  }
}

export default Avatar;
