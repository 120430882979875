import React from 'react';
import { connect } from 'react-redux';
import MonitoringMap from 'components/Map';

const Monitoring = ({ groupedTasks, onDutyDrivers, appState }) => {
  const { startDateFilter, endDateFilter } = appState;
  const showMapRoutes = startDateFilter.isSame(endDateFilter, 'day');

  return (
    <MonitoringMap
      groupedTasks={(showMapRoutes && groupedTasks) || []}
      driverMarkers={onDutyDrivers} />
  );
}

const mapStateToProps = ({ appState }) => ({ appState });
export default connect(mapStateToProps)(Monitoring);
