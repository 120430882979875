import React from 'react';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';

const TaskCancelModel = ({ isOpen, cancelNote, onChangeInput, confirmAction, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}>
      <ModalHeader>
        Confirm Order Cancelation
        <Button onClick={onClose} className="close">
          <i className="pg-close fs-14"></i>
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="form-group form-group-default">
          <label>Cancel Note</label>
          <div className="controls">
              <textarea
                id="cancelNoteInput"
                name="cancelNote"
                className="form-control"
                placeholder="Enter notes with the canceling reason"
                value={cancelNote || ''}
                onChange={event => onChangeInput(event.target.value)}
              />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start">
        <Button color="danger" onClick={confirmAction}>Confirm</Button>
        <Button color="default" onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

export default TaskCancelModel;
