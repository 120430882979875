// const string for actions in uppercase
const NOTIFICATION_ADDED = 'NOTIFICATION_ADDED';
const NOTIFICATION_READ_CHANGE = 'NOTIFICATION_CREATED_ADDED';
const NOTIFICATION_TITLE_CHANGE = 'NOTIFICATION_CREATED_ADDED';
const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';

// actions
export const actions = {
    onNotificationAdded: () => ({ type: NOTIFICATION_ADDED }),
    onNotificationReadChanged: (read) => ({ type: NOTIFICATION_READ_CHANGE, read }),
    onNotificationTitleChanged: (read) => ({ type: NOTIFICATION_TITLE_CHANGE, read }),
    clear: () => ({ type: NOTIFICATION_CLEAR }),
  };
  // initial state
  const initialState = {
    type: '',
    read: false,
    title: "",
  };
  // action handlers
  const actionHandlers = {
    [NOTIFICATION_ADDED]: (state) => ({ ...state, ...initialState }),
    [NOTIFICATION_CLEAR]: (state) => ({ ...state, ...initialState }),
    [NOTIFICATION_READ_CHANGE]: (state, { read }) => ({ ...state, read }),
    [NOTIFICATION_TITLE_CHANGE]: (state, { title }) => ({ ...state, title }),
  };

  export const reducer = (state = initialState, action) => {
    const handler = actionHandlers[action.type]
    return handler ? handler(state, action) : state
  };
