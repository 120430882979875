import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from "react-redux";
import { isLoaded, withFirebase, withFirestore } from "react-redux-firebase";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Alert,
  InputGroupAddon,
  UncontrolledTooltip,
} from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import "firebase/firestore";
import firebase from 'firebase';

import { actions as taskActions } from 'reducers/task';
import { actions as taskDetailActions } from 'reducers/task-detail';
import { actions as invoiceDetailActions } from 'reducers/invoice-detail';
import { actions as destinationActions } from 'reducers/destination';
import { actions as instructionsActions } from 'reducers/instructions';

import * as collections from 'constants/collections';
import * as taskStatuses from 'constants/taskStatus';
import Loading from "components/Loading";
import { Destination } from "components/Destination";
import Select from "components/Select";
import Checkbox from "components/Checkbox";
import { NotificationManager, NotificationPosition } from 'components/Layout';
import * as roles from 'constants/roles';
import * as destinationKeys from 'constants/destinations';
import DriverRouteMap from "components/Map/DriverRouteMap";
import { DATE_TIME_FORMAT } from "constants/formats";
import { ORDER_EVENT_COMPLETION, ORDER_EVENT_CANCELED, EVENT_TYPES } from 'constants/taskEvents';
import * as taskStatus from "constants/taskStatus";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import TaskInstructions from './TaskInstructions';
import { mapbox } from 'config';
import { now, getGroupedBrands } from "utils";

class TaskForm extends Component {
  constructor(props) {
    super(props);

    this.createTask = this.createTask.bind(this);
    this.updateSelectedRecipient = this.updateSelectedRecipient.bind(this);
    this.getSelectedDestination = this.getSelectedDestination.bind(this);

    // Input Refs
    this.orderItems = [];
    this.invoiceItems = [];
    this.state = {
      senderLicenses: this.props.senderLicenses || [],
      recipientLicenses: this.props.recipientLicenses || [],
      newLicense: null
    }
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    recipientLicenses: PropTypes.array,
    senderLicenses: PropTypes.array
  };

  async getLicensesByOwner(ownerId) {
    if (!ownerId) {
      return;
    }

    const { firestore } = this.props;
    const licenses = await firestore.collection(collections.MRA_LICENSE).where('recipientId', '==', ownerId).get();

    if (licenses.empty) {
      return [];
    }
    return licenses;
  }

  getRecipientFor(on) {
    return _.get(this.props, `task.recipients.${on}`);
  }

  async createRecipientFor(on) {
    const { firestore: { add } } = this.props;
    const newRecipient = this.getRecipientFor(on);
    if (!newRecipient) {
      return null;
    }

    newRecipient.disabled = false;
    const result = await add(collections.RECIPIENT, newRecipient);
    return { id: result && result.id, ...newRecipient };
  }

  
  async createBrandFor() {
    const { firestore: { add }, brands, task } = this.props;
    const { brand, sender } = task;

    const newBrand = { name: brand.name, createdAt: now(), modifiedAt: now(), disabled: false, recipients: [sender.id] }

    const exists = brands && brands.find(l => l.name === newBrand.name);

    if (exists) {
      NotificationManager.error("There is already a Brand registered with this name!", null, NotificationPosition.TOP_LEFT);
      return;
    }

    return await add(collections.BRAND, newBrand);
  }


  async saveRecipient(recipientId, recipientInfo) {
    const { firestore: { update } } = this.props;

    // TODO: Temporal fix to try to avoid save `null, null` value as recipient name
    if (recipientInfo.name) {
      delete recipientInfo.name;
    }

    return await update({ collection: collections.RECIPIENT, doc: recipientId }, recipientInfo);
  }

  async createDestinationFor(on, ownerId) {
    const now = moment().utc().unix();
    const { firestore: { add }, destination } = this.props;
    const newDestination = _.pick(destination[on], ['address', 'extra', 'location', 'name']);
    if (!(newDestination.address &&
      newDestination.address.country &&
      newDestination.address.line1 &&
      newDestination.location)) {
      NotificationManager.error('A valid address is required!', null, NotificationPosition.TOP_LEFT);
      return;
    }
    newDestination.createdAt = now;
    newDestination.owner = ownerId;
    newDestination.active = true;
    return await add(collections.DESTINATION, newDestination);
  }

  async createMraLicense(number, ownerId) {
    let newLicense;
    const { firestore: { add }, firestore } = this.props;

    if (!number || !ownerId) {
      return;
    }

    newLicense = { number, recipientId: ownerId, disabled: false };

    const exists = await firestore.collection(collections.MRA_LICENSE).where('number', '==', newLicense.number).get();

    if (!exists.empty) {
      NotificationManager.error("There is already a MRA License registered with this number!", null, NotificationPosition.TOP_LEFT);
      return;
    }

    return await add(collections.MRA_LICENSE, newLicense);
  }

  checkDates(task) {
    const delivery = moment(task.estimatedDelivery.date + ' ' + task.estimatedDelivery.time);
    const pickup = moment(task.estimatedPickup.date + ' ' + task.estimatedPickup.time);

    if (!(delivery.isValid()) && !(pickup.isValid())) {
      NotificationManager.error('Pickup and Delivery fields are required!',
        null,
        NotificationPosition.TOP_LEFT);
      return false;
    }

    if (!delivery.isValid()) {
      NotificationManager.error('Delivery field is required!',
        null,
        NotificationPosition.TOP_LEFT);
      return false;
    }

    if (!pickup.isValid()) {
      NotificationManager.error('Pickup field is required!',
        null,
        NotificationPosition.TOP_LEFT);
      return false;
    }

    // delivery should be after pickup
    if (delivery.isSameOrBefore(pickup)) {
      NotificationManager.error('Estimated delivery must be after pickup!',
        null,
        NotificationPosition.TOP_LEFT);
      return false;
    }

    task.estimatedDelivery = delivery.unix();
    task.estimatedPickup = pickup.unix();
    return true;
  }

  async createTask() {
    const {
      firebase,
      firestore: { add, update },
      task: taskData,
      settings: { fuelSurcharge: companyFuelSurcharge },
      onSenderChanged,
      onOriginChanged,
      onRecipientChanged,
      onRecipientLicenseChanged,
      onSenderLicenseChanged,
      onDestinationChanged,
      destination: destinationData,
      onBrandChanged,
      firestore,
      orderInstructions,
    } = this.props;

    const now = moment().utc().unix();
    const newTask = _.pick(taskData, [
      // TODO: Remove taskTypes
      'taskTypes',
      'terminalId',
      'driverId',
      'assistantId',
      'estimatedPickup',
      'estimatedDelivery',
      'items',
      'invoiceItems',
      'notes',
      'requirements',
      'simulation',
      'fuelSurcharge', // This is optional
    ]);
    // We want to support 0-value fuelSurcharge, but not undefined, null or empty values.
    // We need this to calculate order's total and it's better to make sure it it's filled.
    if (typeof newTask.fuelSurcharge === 'undefined' ||
      newTask.fuelSurcharge === null ||
      newTask.fuelSurcharge === '') {
      newTask.fuelSurcharge = companyFuelSurcharge || 0;
    }

    if (taskData.startingPoint && taskData.startingPoint.length) {
      newTask.startingPoint = taskData.startingPoint;
    }

    newTask.status = taskStatuses.CREATED;
    newTask.creator = firebase.auth().getUid();
    newTask.createdAt = now;

    let sender, origin, recipient, destination;
    // Recipients
    // Sender
    sender = taskData.sender;
    if (!sender || !sender.id) {
      // Create a new one
      sender = await this.createRecipientFor(destinationKeys.TASK_ORIGIN);
      onSenderChanged(sender);
    }
    if (!sender || !sender.id) {
      NotificationManager.error('A origin is required!', null, NotificationPosition.TOP_LEFT);
      return;
    }
    // detect any sender info change
    const senderInfoChanges = this.getRecipientFor(destinationKeys.TASK_ORIGIN);
    if (senderInfoChanges) {
      this.saveRecipient(sender.id, senderInfoChanges);
    }
    // Origin location
    origin = taskData.origin;
    if (!origin || !origin.id) {
      origin = await this.createDestinationFor(destinationKeys.TASK_ORIGIN, sender.id);
      onOriginChanged(origin);
    }
    if (!origin || !origin.id) {
      NotificationManager.error('An origin address is required!', null, NotificationPosition.TOP_LEFT);
      return;
    }

    // Recipient
    recipient = taskData.recipient;
    if (!recipient || !recipient.id) {
      // Create a new one
      recipient = await this.createRecipientFor(destinationKeys.TASK_DESTINATION);
      onRecipientChanged(recipient);
    }
    if (!recipient || !recipient.id) {
      NotificationManager.error('A destination is required!', null, NotificationPosition.TOP_LEFT);
      return;
    }
    // detect any recipient info change
    const recipientInfoChanges = this.getRecipientFor(destinationKeys.TASK_DESTINATION);
    if (recipientInfoChanges) {
      this.saveRecipient(recipient.id, recipientInfoChanges);
    }

    let senderLicense = taskData.senderLicense;
    if (senderLicense && !senderLicense.id) {
      senderLicense = await this.createMraLicense(
        senderLicense.value,
        sender.id,
      );
      onSenderLicenseChanged(senderLicense);
    }
    if (!senderLicense || !senderLicense.id) {
      NotificationManager.error('A sender license is required!', null, NotificationPosition.TOP_LEFT);
      return;
    }

    let brandData = taskData.brand;

    if (brandData && !brandData.id) {
      brandData = await this.createBrandFor()
      onBrandChanged(brandData);
    }
  
    let recipientLicense = taskData.recipientLicense;
    if (recipientLicense && !recipientLicense.id) {
      recipientLicense = await this.createMraLicense(
        recipientLicense.value,
        recipient.id,
      );
      onRecipientLicenseChanged(recipientLicense);
    }
    if (!recipientLicense || !recipientLicense.id) {
      NotificationManager.error('A recipient license is required!', null, NotificationPosition.TOP_LEFT);
      return;
    }

    // Destination location
    destination = taskData.destination;
    if (!destination || !destination.id) {
      destination = await this.createDestinationFor(destinationKeys.TASK_DESTINATION, recipient.id);
      onDestinationChanged(destination);
    }
    if (!destination || !destination.id) {
      NotificationManager.error('A destination address is required!', null, NotificationPosition.TOP_LEFT);
      return;
    }

    // sender, origin, recipient, destination
    newTask.senderId = sender.id;
    newTask.originId = origin.id;
    newTask.recipientId = recipient.id;
    newTask.destinationId = destination.id;
    newTask.recipientLicenseId = recipientLicense.id;
    newTask.senderLicenseId = senderLicense.id;

    if (brandData) {
      newTask.brandId = brandData.id;
    }

    // Check if brand contains recipient 
    if (brandData) {
      const exists = (brandData.recipients && brandData.recipients.find(item => item === newTask.senderId)) || false;
      if (!exists)  {
        firestore
        .collection(collections.BRAND)
        .doc(brandData.id)
        .update({
          recipients: firestore.FieldValue.arrayUnion(newTask.senderId),
        })
      }
    }
    // Checking unit/vehicle selection
    const { vehicle, driver } = taskData;
    // If driver hasn't an assigned vehicle or no vehicle selected on task form
    if ((driver && !driver.vehicleId) && (!vehicle)) {
      NotificationManager.error('Vehicle is required!', null, NotificationPosition.TOP_LEFT);
      return;
    }
    // TODO: Review this on production
    newTask.vehicleId = vehicle ? vehicle.id : null;

    // Checking estimatedPickup and estimatedDelivery by dates.
    if (!this.checkDates(newTask)) {
      return;
    }

    const driverId = newTask.driverId
    const vehicleId = vehicle ? vehicle.id : null;

    await update({ collection: collections.USER, doc: driverId }, { vehicleId });

    // Create or update instructions
    if (orderInstructions && !taskData.instructionsId) {
      const newInstructions = await add(collections.ORDER_INSTRUCTIONS, {
        origin: destinationData.taskOrigin.location,
        destination: destinationData.taskDestination.location,
        ...orderInstructions,
      });
      newTask.instructionsId = newInstructions.id;
    } else if (orderInstructions && taskData.instructionsId) {
      await update(
        {
          collection: collections.ORDER_INSTRUCTIONS,
          doc: taskData.instructionsId,
        },
        {
          origin: destinationData.taskOrigin.location,
          destination: destinationData.taskDestination.location,
          ...orderInstructions,
        },
      );
    }

    // validate if is editing
    if (taskData && taskData.id) {
      await update({ collection: collections.TASK, doc: taskData.id }, newTask);
      NotificationManager.success('Order has been updated!', null, NotificationPosition.TOP_LEFT);
    } else {
      await add(collections.TASK, newTask);
      
      NotificationManager.success('Order has been created!', null, NotificationPosition.TOP_LEFT);
    }

    this.props.clearTask();
    this.props.toggle();
  }

  getAddress(address) {
    return encodeURIComponent(`${address.line1}, ${address.state}, ${address.country}, ${address.zip}`);
  }

  getDestinations(ownerId) {
    return this.props.destinations.filter(item => item.owner === ownerId);
  }

  handleBusinessValue(selectedDestination, on = destinationKeys.TASK_DESTINATION) {
    const { task } = this.props;

    if (on === destinationKeys.TASK_ORIGIN) {
      if (task.origin && task.origin.id) {
        return `${task.origin.owner}, ${task.originId}`;
      }
    } else if (on === destinationKeys.TASK_DESTINATION) {
      if (task.destination && task.destination.id) {
        return `${task.destination.owner}, ${task.destinationId}`;
      }
    }
    return selectedDestination;
  }

  async handleRecipientChange(selectedDestination, on = destinationKeys.TASK_DESTINATION) {
    const {
      recipients,
      destinations,
      onRecipientChanged,
      onDestinationChanged,
      onSenderChanged,
      onOriginChanged,
      onRecipientInfoChanged,
      onDestinationEdit,
      onRecipientLicenseChanged,
      onSenderLicenseChanged,
      brands,
      onFilteredBrandChanged,
    } = this.props;
    const [recipientId, destinationId] = selectedDestination.split(',').map(id => id.trim());
    const recipient = recipients.find(r => r.id === recipientId);
    const destination = destinations.find(d => d.id === destinationId);
    let license = null;
    if (recipient && recipient.id) {
      const recipientLicenses = this.getLicensesByOwner(recipient.id) || null;
      if (recipientLicenses) {
        license = recipientLicenses[0];
      }
    }
    if (on === destinationKeys.TASK_DESTINATION) {
      onRecipientLicenseChanged(license);
      onRecipientChanged(recipient);
      onDestinationChanged(destination);
      onDestinationEdit(destinationKeys.TASK_DESTINATION, null);
      onDestinationEdit(destinationKeys.TASK_DESTINATION, destination);
      
      let recipientLicenses = [];
      if (recipient && recipient.id) {
        recipientLicenses = await this.getLicenseNameOptions(recipient.id, this.state.newLicense);
      }

      this.setState({ recipientLicenses });
      !recipient && onRecipientInfoChanged(on, { name: selectedDestination });
    } else if (on === destinationKeys.TASK_ORIGIN) {
      onFilteredBrandChanged([]);
      onSenderLicenseChanged(license);
      onSenderChanged(recipient);
      onOriginChanged(destination);
      onDestinationEdit(destinationKeys.TASK_ORIGIN, null);
      onDestinationEdit(destinationKeys.TASK_ORIGIN, destination);

      let senderLicenses = [];
      if (recipient && recipient.id) {
        onFilteredBrandChanged(getGroupedBrands(brands, recipient));
        senderLicenses = await this.getLicenseNameOptions(recipient.id, this.state.newLicense);
      }

      this.setState({ senderLicenses });
      !recipient && onRecipientInfoChanged(on, { name: selectedDestination });
    }
    this.getTaskInstructions();
  }

  getTaskInstructions = async () => {
    const {
      onLoadingInstructionsChanged,
      onTaskInstrunctionsChanged,
      task,
      destination,
    } = this.props;
    onLoadingInstructionsChanged(true);
    
    if (
      destination.taskOrigin.location &&
      destination.taskDestination.location && 
      destination.taskOrigin.location.lat &&
      destination.taskOrigin.location.lon &&
      destination.taskDestination.location.lat &&
      destination.taskDestination.location.lon
    ) {
      const data = {
        origin: destination.taskOrigin.location,
        destination: destination.taskDestination.location,
        instructionsId: task.instructionsId,
      }
      const getInstructions = firebase
        .functions()
        .httpsCallable('getInstructions');

      const result = await getInstructions({ data });
      // Read result of the Cloud Function.
      const orderInstructions = result.data;
      onTaskInstrunctionsChanged(orderInstructions);
    }
    onLoadingInstructionsChanged(false);
  }

  findSlotByDate = (currentDate, scheduledTasks) => {
    const nextDay = () => {
      const nextDay = currentDate.add(1, 'days');
      return this.findSlotByDate(nextDay, scheduledTasks);
    }

    const nextHour = slotTime => {
      const slotInterval = 60; // Search slot time every hour, assuming tasks may take a maximum of 1 hour
      return slotTime.add(slotInterval, 'minutes');
    }

    const assignedSlot = slotTime => {
      return scheduledTasks.some(estimatedDelivery => {
        return slotTime.isBetween(estimatedDelivery, estimatedDelivery.add(1, 'hour'), 'days', true);
      });
    }

    const { settings: { hours }, task: { estimatedPickup } } = this.props;
    const { open, close } = hours || {};

    const today = moment();
    const currentDayOfWeek = currentDate.format('dddd').toLocaleLowerCase();

    // We need the open and close hours to find an slot
    if (!(open && close)) {
      return false;
    }

    let openingHour, closingHour;
    if (open[currentDayOfWeek].toString().length && close[currentDayOfWeek].toString().length) {
      openingHour = moment(open[currentDayOfWeek]);
      closingHour = moment(close[currentDayOfWeek]);
    } else {
      return nextDay();
    }

    // Set opening and closing datetimes
    const formatedDate = new Date(currentDate.format('L'));
    let openingDate = formatedDate.setHours(openingHour.format('HH'), openingHour.format('mm'));
    let closingDate = formatedDate.setHours(closingHour.format('HH'), closingHour.format('mm'));

    // For future days assign the openingTime of that day
    if (currentDayOfWeek !== today.format('dddd').toLocaleLowerCase()) {
      currentDate = moment(openingDate);
    }

    let slotTime = moment(openingDate); // Starting to search slot time from the opening date
    const endTime = moment(closingDate);

    // Validate estimated pickup date
    if (estimatedPickup) {
      if (estimatedPickup.date) {
        let estimatedPickupDateTime;

        estimatedPickupDateTime = moment(estimatedPickup.date);
        if (estimatedPickupDateTime > slotTime) {
          return nextDay();
        }

        if (estimatedPickup.time) {
          const [hour = 0, minute = 0] = estimatedPickup.time.split(':');
          estimatedPickupDateTime = estimatedPickupDateTime.set({ hour, minute });
          while (slotTime <= estimatedPickupDateTime) {
            slotTime = nextHour(slotTime);
          }
        }
      }
    }

    while (slotTime < endTime) {
      if ((slotTime >= currentDate) && !assignedSlot(slotTime)) {
        return slotTime;
      }
      slotTime = nextHour(slotTime);
    }

    // Search for next 15 days from now
    const limitDaysToLookingSlotTime = 15;
    if (currentDate.diff(moment(), 'days') < limitDaysToLookingSlotTime) {
      return nextDay();
    }

    return false;
  }

  isReadOnlyModeEnabled = () => {
    const { task: { status } } = this.props;
    return [
      taskStatus.ARRIVAL,
      taskStatus.COMPLETED,
      taskStatus.CANCELED,
      taskStatus.PROGRESS
    ].includes(status);
  };

  handleTerminalChange(terminalId) {
    const { onTerminalChanged } = this.props;
    onTerminalChanged(terminalId);
  }

  handleRequirementChange(event, name) {
    const value = event.target.checked;
    const { onRequirementChanged } = this.props;
    onRequirementChanged(name, value)
  }

  handleVehicleChange(vehicleId) {
    const { vehicles, onVehicleChanged } = this.props;
    const vehicle = vehicles.find(vehicle => vehicle.id === vehicleId);
    onVehicleChanged(vehicle);
  }

  handlePhoneChanged(phone, on) {
    let { task: { recipients: { [on]: recipient } }, onRecipientInfoChanged } = this.props;
    if (!recipient) {
      recipient = {};
    }
    recipient.phone = phone;
    onRecipientInfoChanged(on, recipient);
  }

  suggestCompleteTimes = () => {
    const { tasks, task, onEstimatedDeliveryDate, onEstimatedDeliveryTime } = this.props;
    if (!task.estimatedPickup.date || !task.estimatedPickup.time) {
      NotificationManager.error('Delivery Date cannot be set if Pick Up is missing.', null, NotificationPosition.TOP_LEFT);
      return;
    }

    const currentDate = moment();

    const scheduledTasks = tasks.reduce((filtered, item) => {
      if (item.estimatedDelivery >= currentDate && (item.driverId === task.driverId) && (item.status !== taskStatuses.COMPLETED)) {
        filtered.push(moment.unix(item.estimatedDelivery));
      }
      return filtered;
    }, []);

    const slotTime = this.findSlotByDate(currentDate, scheduledTasks);

    if (slotTime) {
      onEstimatedDeliveryDate(slotTime.format('YYYY-MM-DD'));
      onEstimatedDeliveryTime(slotTime.format('HH:mm'));
    }
  }

  getBusinessNameOptions = (newOption) => {
    const { recipients } = this.props;
    let options = [];

    if (newOption) {
      options.push(newOption);
    }

    for (let i in recipients) {
      const { id, name } = recipients[i];
      const destinations = this.getDestinations(id);
      options.push({ value: id, label: `${name} (New Destination)` });
      // new destination
      if (destinations) {
        options.push(...destinations.map(({ id: destinationId, address }) => ({
          value: `${id}, ${destinationId}`,
          label: `${name} ${address && (`(${address.line1 || address.name})`)}`
        })));
      }
    }

    return options.sort((a, b) => (a.label > b.label) ? 1 : -1);
  }

  getLicenseNameOptions = async (ownerId, newOption) => {
    const { firestore } = this.props;
    let options = [];

    const licenses = await firestore.collection(collections.MRA_LICENSE).where('disabled', '==', false).where('recipientId', '==', ownerId).get();
    if (!licenses.empty) {
      options = licenses.docs.map(l => ({ id: l.id, value: l.id, label: l.data().number })).sort((a, b) => (a.label > b.label) ? 1 : -1);
    }

    if (newOption) {
      options.push(newOption);
    }

    return options;
  }

  handleMraLicenseValue(selectedLicense, on = destinationKeys.TASK_DESTINATION) {
    const { task } = this.props;
    if (on === destinationKeys.TASK_DESTINATION) {
      if (task && task.recipientLicenseId) {
        return task.recipientLicense.id || task.recipientLicense.value;
      }
    } else if (on === destinationKeys.TASK_ORIGIN) {
      if (task && task.senderLicense) {
        return task.senderLicense.id || task.senderLicense.value;
      }
    }
    return selectedLicense;
  }

  handleMraLicenseValueChange(selectedLicenseId, on = destinationKeys.TASK_DESTINATION) {
    const {
      onRecipientLicenseChanged,
      onSenderLicenseChanged,
    } = this.props;

    const { recipientLicenses, senderLicenses } = this.state;

    if (on === destinationKeys.TASK_DESTINATION) {
      let recipientLicense = (recipientLicenses &&
        recipientLicenses.find(l => l.value === selectedLicenseId));

      if(!recipientLicense) {
        recipientLicense = {
          id: null,
          value: selectedLicenseId,
        };

        this.setState({
          recipientLicenses: [recipientLicense, ...recipientLicenses],
        });
        this.setState({ newLicense: recipientLicense });
      }

      onRecipientLicenseChanged(recipientLicense);
    } else if (on === destinationKeys.TASK_ORIGIN) {
      let sendertLicense = (senderLicenses &&
        senderLicenses.find(l => l.value === selectedLicenseId));

      if (!sendertLicense) {
        sendertLicense = {
          id: null,
          value: selectedLicenseId,
        };

        this.setState({
          senderLicenses: [sendertLicense, ...senderLicenses],
        });
        this.setState({ newLicense: sendertLicense });
      }

      onSenderLicenseChanged(sendertLicense);
    }
  }

  handleBrandValueChange(brandItemId) {
    const { brands,
      onBrandChanged,
    } = this.props;

    const brand = brands.find(l => l.id === brandItemId) || { id: null, name: brandItemId };
    onBrandChanged(brand); 
  }

  renderRecipientForm(on = destinationKeys.TASK_DESTINATION, selectId, sectionName) {
    const selectedTarget = on === destinationKeys.TASK_DESTINATION ? 'recipient' : 'sender';
    const selectedLicenseType = `${selectedTarget}License`;
    const selected = (this.props.task && this.props.task[selectedTarget]) || {};
    const selectedLicense = (this.props.task && this.props.task[selectedLicenseType]) || {};
    const { id, name, phone } = selected;
    
    const readOnly = this.isReadOnlyModeEnabled();
    let newOption = false;
    if (!id && name) {
      newOption = { value: name.trim(), label: `${name} (New Recipient)` };
    }

    let newLicense = false;
    if (selectedLicense && !selectedLicense.id && selectedLicense.value) {
      newLicense = { value: selectedLicense.value.trim(), label: `${selectedLicense.value} (New MRA License)` };
    }

    return <Fragment>
      <Row>
        <FormGroup id={`${sectionName}RecipientFormGroup`} className="form-group-default form-group-default-select2">
          <Label for="recipient">Business Name</Label>
          <Select
            data-test-id="recipient"
            name="recipient"
            id={selectId}
            value={newOption ? name.trim() : this.handleBusinessValue(id, on)}
            onChange={event => { this.handleRecipientChange(event.target.value, on) }}
            creatable
            placeholder={'Select'}
            onCreate={(params) => {
              const { term } = params;
              if (term.trim().length === 0) {
                return null;
              }

              return {
                id: term.trim(),
                text: `${term.trim()} (New Recipient)`
              }
            }}
            options={this.getBusinessNameOptions(newOption)}
            readOnly={readOnly}
          />
        </FormGroup>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup className={`form-group-default${readOnly ? ' readonly' : ''}`}>
            <label>Phone</label>
            <PhoneInput
              id={`${sectionName}Phone`}
              data-test-id={`${sectionName}Phone`}
              className={`form-control${readOnly ? ' readonly' : ''}`}
              placeholder="Phone"
              defaultCountry="US"
              type="text"
              required
              name={`${sectionName}Phone`}
              value={phone || ""}
              disabled={readOnly}
              onChange={value => this.handlePhoneChanged(value, on)} />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup id={`${sectionName}metrcLicenseFormGroup`} className="form-group-default form-group-default-select2">
            <Label for="recipient">METRC License #</Label>
            <Select
              id={`${sectionName}MetrcLicenseId`}
              data-test-id={`${sectionName}MetrcLicenseId`}
              value={newLicense ? newLicense.value : selectedLicense.value}
              onChange={event => { this.handleMraLicenseValueChange(event.target.value, on) }}
              creatable
              forceRecreate
              placeholder={'Select'}
              onCreate={(params) => {
                const { term } = params;
                if (term.trim().length === 0) {
                  return null;
                }

                return {
                  id: term.trim(),
                  text: `${term.trim()} (New MRA License)`
                }
              }}
              options={() => (selectedTarget === 'recipient' ? this.state.recipientLicenses : this.state.senderLicenses)}
              readOnly={readOnly}
            />
          </FormGroup>
        </Col>
      </Row>
    </Fragment>;
  }

  renderPickupDestination() {
    // pickupDestination
    const { task: { origin, status } } = this.props;
    const readOnly = this.isReadOnlyModeEnabled();
    const isOnProgress = [taskStatus.PROGRESS].includes(status);
    const isEditing = !readOnly && !isOnProgress;

    return (
      <Fragment>
        <div className="mt-2">
          <Destination
            id="taskOrigin"
            data-test-id="taskOrigin"
            as={destinationKeys.TASK_ORIGIN}
            disabled={(!!origin || readOnly) && !isEditing}
            defaultData={origin}
            readOnly={readOnly} />
        </div>
      </Fragment>
    );
  }

  renderDropOffDestination() {
    const { task: { destination, status } } = this.props;
    const readOnly = this.isReadOnlyModeEnabled();
    const isOnProgress = [taskStatus.PROGRESS].includes(status);
    const isEditing = !readOnly && !isOnProgress;

    return (
      <Fragment>
        <div className="mt-2">
          <Destination
            id="taskDestination"
            data-test-id="taskDestination"
            as={destinationKeys.TASK_DESTINATION}
            disabled={(!!destination || readOnly) && !isEditing}
            defaultData={destination}
            readOnly={readOnly} />
        </div>
      </Fragment>
    );
  }

  renderInvoiceDetails() {
    const {
      task, invoiceDetail,
      onNewInvoiceItem, onRemoveInvoiceItem,
      onInvoiceNameDetailChanged, onInvoiceTransportationChanged, invoiceDetailClear
    } = this.props;

    const readOnly = this.isReadOnlyModeEnabled();

    return (<Fragment>
      <Row>
        <Col xs={12}>
          <h3>Invoice Details</h3>
        </Col>
      </Row>
      <Row>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Transportation Price</th>
              <th></th>
            </tr>
            <tr>
              <th></th>
              <th>
                <input
                  type="text"
                  name="name"
                  id="invoiceItemName"
                  data-test-id="invoiceItemName"
                  placeholder="Name"
                  className="form-control"
                  value={invoiceDetail.name}
                  ref={ref => this.invoiceItems['name'] = ref}
                  onKeyDown={(e) => this.onKeyPressTableInput(e, 'invoice')}
                  onChange={event => { onInvoiceNameDetailChanged(event.target.value) }}
                  readOnly={readOnly}
                />
              </th>
              <th>
                <input
                  type="number"
                  min={1}
                  step={0.01}
                  name="transportationPrice"
                  id="invoiceItemTransportationPrice"
                  data-test-id="invoiceItemTransportationPrice"
                  placeholder="Transportation Price"
                  className="form-control"
                  value={invoiceDetail.transportationPrice}
                  ref={ref => this.invoiceItems['transportationPrice'] = ref}
                  onKeyDown={(e) => this.onKeyPressTableInput(e, 'invoice')}
                  onChange={event => { onInvoiceTransportationChanged(event.target.value) }}
                  readOnly={readOnly}
                />
              </th>
              <th>
                {!readOnly && <Button id="addInvoiceItemBtn" data-test-id="addInvoiceItemBtn" type="button" onClick={() => {
                  if (this.isValidInvoiceDetail(invoiceDetail)) {
                    onNewInvoiceItem(invoiceDetail);
                    invoiceDetailClear();
                  }
                }}>+</Button>}
              </th>
            </tr>
          </thead>
          <tbody>
            {task.invoiceItems.map((item, idx) =>
              <tr id={`invoiceItemRow-${idx}`} data-test-id={`invoiceItemRow-${idx}`} key={idx}>
                <td>{idx + 1}</td>
                <td>{item.name || 'No name'}</td>
                <td>{item.transportationPrice || 'No transportation price'}</td>
                <td>{!readOnly && <Button id={`removeInvoiceItemAddBtn-${idx}`} data-test-id={`removeInvoiceItemAddBtn-${idx}`} type="button" onClick={() => { onRemoveInvoiceItem(idx) }}>-</Button>}
                </td>
              </tr>)}
          </tbody>
        </Table>
      </Row>
    </Fragment>);
  }

  onKeyPressTableInput = (event, type) => {
    const {
      taskDetail, onNewItem, taskDetailItemClear, // task props
      invoiceDetail, onNewInvoiceItem, invoiceDetailClear, // invoice props
    } = this.props;

    const isOrderItem = type === 'order';
    const sourceData = isOrderItem ? taskDetail : invoiceDetail;
    const addAction = isOrderItem ? onNewItem : onNewInvoiceItem;
    const clearAction = isOrderItem ? taskDetailItemClear : invoiceDetailClear;

    if (event.keyCode === 13) {
      const fields = Object.keys(sourceData);
      let validInputs = false;

      for (let i in fields) {
        let field = fields[i];
        if (((typeof sourceData[field] === 'string') && !sourceData[field].length) || sourceData[field] <= 1) {
          if (isOrderItem) {
            this.orderItems[field].focus();
          } else {
            this.invoiceItems[field].focus();
          }
          break;
        } else {
          validInputs++;
        }
      }

      if (fields.length === validInputs) {
        addAction(sourceData);
        clearAction();
        const [initialField] = fields;
        if (isOrderItem) {
          this.orderItems[initialField].focus();
        } else {
          this.invoiceItems[initialField].focus();
        }
      }
    }
  }


  /**
   * @param {*} drivers: All drivers from firestore
   * @param {*} excludeId:  To avoid having same driver/assistant assigned at the same time
   * @param {*} assignedDriverId: Driver/assistant assigned to the order
   * @returns A list of enabled drivers (AND driver assigned to the current order even if it's disabled)
   * @memberof components/Task/TaskForm
   */
  getDriverOptions(drivers, excludeId, assignedDriverId) {
    // remove assignedDriverId if only !disabled drivers are filtered from firestore
    return drivers
      .filter((d) => (!d.disabled || d.id === assignedDriverId) && d.id !== excludeId && (d.firstName || d.lastName))
      .map(driver => ({
        value: driver.id,
        label: `${driver.firstName || ''} ${driver.lastName || ''}`
      }));
  }

  isValidOrderDetail(orderItem) {
    const { item, price, quantity } = orderItem;

    if (item === '') {
      NotificationManager.error('A valid item is required!', null, NotificationPosition.TOP_LEFT);
      return false;
    }

    if (!quantity || quantity < 1) {
      NotificationManager.error('A valid quantity is required!', null, NotificationPosition.TOP_LEFT);
      return false;
    }

    if (!price || price < 1) {
      NotificationManager.error('A valid price is required!', null, NotificationPosition.TOP_LEFT);
      return false;
    }

    return true;
  }

  isValidInvoiceDetail(invoiceDetail) {
    const { name, transportationPrice } = invoiceDetail;

    if (!name || name === '') {
      NotificationManager.error('A valid invoice name is required!', null, NotificationPosition.TOP_LEFT);
      return false;
    }

    if (!transportationPrice || transportationPrice < 1) {
      NotificationManager.error('A valid transportation price is required!', null, NotificationPosition.TOP_LEFT);
      return false;
    }
    return true;
  }

  render() {
    if (!isLoaded(this.props.drivers) || !isLoaded(this.props.recipients) || !isLoaded(this.props.terminals) || !isLoaded(this.props.brands)) {
      return (<Loading />);
    }
    const {
      onItemChanged, onDescriptionChanged, onQuantityChanged, onPriceChanged, taskDetailItemClear, // task detail actions
      onAssistantChanged, onDriverChanged,
      onEstimatedPickupDate, onEstimatedPickupTime, onStartingPointChanged,
      onEstimatedDeliveryDate, onEstimatedDeliveryTime, onNewItem, onRemoveItem,
      onSimulateChanged, onNotesChanged, onFuelSurchargeChanged, // task actions
      toggle, error, success, task,
      taskDetail, drivers, vehicles, currentUser, terminals, tasks,
      settings: { hours, fuelSurcharge: companyFuelSurcharge },
      orderInstructions
    } = this.props;
    const { driver, estimatedPickup, estimatedDelivery, driverId, assistantId } = task;

    const requirements = [
      { name: 'signature', label: 'Signature' },
      { name: 'photo', label: 'Photo' },
      { name: 'notes', label: 'Notes' },
      { name: 'collectPayment', label: 'Collect Payment' },
      { name: 'manifestPhoto', label: 'Manifest Photo' },
    ];

    const terminalsOptions = terminals
      .map(terminal => ({ value: terminal.id, label: `${terminal.name}` }));

    let time = null;
    switch (task.status) {
      case taskStatus.COMPLETED:
        time = task.details.events.filter(item => item.name === ORDER_EVENT_COMPLETION)[0].time;
        break;
      case taskStatus.CANCELED:
        time = task.details.events.filter(item => item.name === ORDER_EVENT_CANCELED)[0].time;
        break;
      default: break;
    }

    const todayTasks = (estimatedDelivery
      && estimatedDelivery.date
      && tasks.filter(t => moment.unix(t.estimatedDelivery).isSame(moment(estimatedDelivery.date), 'day'))) || [];

    const startingOptions = todayTasks.map(task => {
      const destination = this.getSelectedDestination(task.destinationId);
      const label = (destination && destination.address && destination.address.line1) || '';
      return { value: task.id, label };
    });

    const readOnly = this.isReadOnlyModeEnabled()
    // if (mapbox.showInstructions && destination.taskDestination.location && destination.taskOrigin.location && !readOnly && !task.loadingInstructions) {
    //   onLoadingInstructionsChanged(true)
    //   getDestinationInstructions(destination.taskOrigin.location, destination.taskDestination.location, task.id, this.state);
    // }
    
    return <Fragment>
      {readOnly &&
        (
          (task.status === taskStatus.COMPLETED &&
            <Row><Col md={12}>Completed: {!!time ? moment(time * 1000).format(DATE_TIME_FORMAT) : '-'}</Col></Row>) ||
          (task.status === taskStatus.CANCELED &&
            <Row><Col md={12}>Canceled: {!!time ? moment(time * 1000).format(DATE_TIME_FORMAT) : '-'}</Col></Row>) ||
          (task.status === taskStatus.PROGRESS &&
            <Row><Col md={12}>PROGRESS</Col></Row>)
        )
      }
      <Form id="orderForm" data-test-id="orderForm" className="">
        {(!hours || (!hours.open && !hours.close)) &&
          <Alert color="warning">
            <strong>Warning!</strong> You don't have any open-close hours set so system is not able to suggest a time, please go to settings and enter them.
          </Alert>}
        <Row>
          <h3>Origin</h3>
        </Row>
        {this.renderRecipientForm(destinationKeys.TASK_ORIGIN, "originRecipient", "origin")}
        {this.renderPickupDestination()}
        <Row>
          <Col md="6">
            <div className="form-group-attached">
              <Row className="clearfix">
                <Col md="6">
                  <FormGroup className={`form-group-default${readOnly ? ' readonly' : ''}`}>
                    <Label for="estimatedPickupDate">Pickup</Label>
                    <Input
                      type="date"
                      name="estimatedPickup[date]"
                      id="estimatedPickupDate"
                      data-test-id="estimatedPickupDate"
                      onChange={event => onEstimatedPickupDate(event.target.value)}
                      defaultValue={(estimatedPickup && estimatedPickup.date) || ''}
                      readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className={`form-group-default${readOnly ? ' readonly' : ''}`}>
                    <Label for="estimatedPickupTime">&nbsp;</Label>
                    <Input
                      type="time"
                      name="estimatedPickup[time]"
                      id="estimatedPickupTime"
                      data-test-id="estimatedPickupTime"
                      onChange={event => onEstimatedPickupTime(event.target.value)}
                      defaultValue={(estimatedPickup && estimatedPickup.time) || ''}
                      readOnly={readOnly} />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md="6">
          <FormGroup
            id={`BrandFormGroup`}
            className="form-group-default form-group-default-select2"
          >
            <Label for="brand">Brand Name</Label>
            <Select
              data-test-id="brand"
              name="brand"
              value={task.brandId}
              onChange={(event) => {
                this.handleBrandValueChange(event.target.value);
              }}
              creatable
              placeholder={"Select"}
              onCreate={(params) => {
                const { term } = params;
                if (term.trim().length === 0) {
                  return null;
                }
                return {
                  id: term.trim(),
                  text: `${term.trim()} (New Brand)`,
                };
              }}
              options={task.filteredBrands || []}
              readOnly={readOnly}
            />
          </FormGroup>
          </Col>
        </Row>
        <Row>
          <h3>Terminal</h3>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <FormGroup className="form-group-default form-group-default-select2">
              <Label for="driver">Select</Label>
              <Select
                name="terminal"
                id="terminal"
                data-test-id="terminal"
                value={task.terminalId || ''}
                placeholder="Select Terminal"
                options={terminalsOptions}
                onChange={event => { this.handleTerminalChange(event.target.value) }}
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <FormGroup className="form-group-default form-group-default-select2">
              <Label for="driver">Unit</Label>
              <Select
                name="vehicle"
                id="vehicle"
                data-test-id="vehicle"
                value={(task && task.vehicle && task.vehicle.id) || (driver && driver.vehicleId) || ''}
                placeholder="Select Vehicle"
                options={vehicles.map(vehicle => ({ value: vehicle.id, label: `${vehicle.description || ''} ${vehicle.model || ''}` }))}
                onChange={event => { this.handleVehicleChange(event.target.value) }}
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <h3>Drivers</h3>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <FormGroup className="form-group-default form-group-default-select2">
              <Label for="driver">Driver</Label>
              <Select
                name="driver"
                id="driver"
                data-test-id="driver"
                value={task.driverId}
                options={this.getDriverOptions(drivers, assistantId, task.driverId)}
                onChange={event => { onDriverChanged(event.target.value) }}
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <FormGroup className="form-group-default form-group-default-select2">
              <Label for="assistant">Assistant Driver</Label>
              <Select
                name="assistant"
                id="assistant"
                data-test-id="assistant"
                value={task.assistantId}
                placeholder="Select an assistant driver"
                options={this.getDriverOptions(drivers, driverId, task.assistantId)}
                onChange={event => { onAssistantChanged(event.target.value) }}
                readOnly={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <h3>Destination</h3>
        </Row>
        {this.renderRecipientForm(destinationKeys.TASK_DESTINATION, "destinationRecipient", "destination")}
        {this.renderDropOffDestination()}
        {!readOnly && (<Row>
          <Col xs={12}>
            <Button id="suggest-time-btn" className="float-left mb-2" onClick={this.suggestCompleteTimes}>Suggest Time</Button>
            <UncontrolledTooltip placement="top-start" target="suggest-time-btn">
              Suggest time bassed on open and closed Hours
            </UncontrolledTooltip>
          </Col>
        </Row>)}
        <Row>
          <Col md="6">
            <div className="form-group-attached">
              <Row className="clearfix">
                <Col md="6">
                  <FormGroup className={`form-group-default${readOnly ? ' readonly' : ''}`}>
                    <Label for="estimatedDeliveryDate">Delivery</Label>
                    <Input
                      type="date"
                      name="estimatedDelivery[date]"
                      id="estimatedDeliveryDate"
                      data-test-id="estimatedDeliveryDate"
                      onChange={event => onEstimatedDeliveryDate(event.target.value)}
                      value={(estimatedDelivery && estimatedDelivery.date) || ''}
                      readOnly={readOnly} />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className={`form-group-default${readOnly ? ' readonly' : ''}`}>
                    <Label for="estimatedDeliveryTime">&nbsp;</Label>
                    <Input
                      type="time"
                      name="estimatedDelivery[time]"
                      id="estimatedDeliveryTime"
                      data-test-id="estimatedDeliveryTime"
                      onChange={event => onEstimatedDeliveryTime(event.target.value)}
                      value={(estimatedDelivery && estimatedDelivery.time) || ''}
                      readOnly={readOnly} />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md="6">
          </Col>
        </Row>
        <Row>
          <h3>Order Details</h3>
        </Row>
        <Row>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Price</th>
                <th></th>
              </tr>
              <tr>
                <th></th>
                <th>
                  <input
                    type="text"
                    name="item"
                    id="orderItemName"
                    data-test-id="orderItemName"
                    placeholder="Item"
                    className="form-control"
                    required
                    value={taskDetail.item}
                    ref={ref => this.orderItems['item'] = ref}
                    onKeyDown={(e) => this.onKeyPressTableInput(e, 'order')}
                    onChange={event => { onItemChanged(event.target.value) }}
                    readOnly={readOnly}
                  />
                </th>
                <th>
                  <input
                    type="text"
                    name="description"
                    id="orderItemDescription"
                    data-test-id="orderItemDescription"
                    placeholder="Description"
                    className="form-control"
                    required
                    value={taskDetail.description}
                    ref={ref => this.orderItems['description'] = ref}
                    onKeyDown={(e) => this.onKeyPressTableInput(e, 'order')}
                    onChange={event => { onDescriptionChanged(event.target.value) }}
                    readOnly={readOnly}
                  />
                </th>
                <th>
                  <input
                    type="number"
                    name="quantity"
                    id="orderItemQuantity"
                    data-test-id="orderItemQuantity"
                    placeholder="Quantity"
                    className="form-control"
                    min={1}
                    step={1}
                    value={taskDetail.quantity}
                    ref={ref => this.orderItems['quantity'] = ref}
                    onKeyDown={(e) => this.onKeyPressTableInput(e, 'order')}
                    onChange={event => { onQuantityChanged(event.target.value) }}
                    readOnly={readOnly}
                  />
                </th>
                <th>
                  <input
                    type="number"
                    name="price"
                    id="orderItemPrice"
                    data-test-id="orderItemPrice"
                    placeholder="Price"
                    className="form-control"
                    min={1}
                    step={0.01}
                    value={taskDetail.price}
                    ref={ref => this.orderItems['price'] = ref}
                    onKeyDown={(e) => this.onKeyPressTableInput(e, 'order')}
                    onChange={event => { onPriceChanged(event.target.value) }}
                    readOnly={readOnly}
                  />
                </th>
                <th>
                  {task.status !== taskStatus.COMPLETED &&
                    <Button id="addOrderItemBtn" data-test-id="addOrderItemBtn" type="button" onClick={() => {
                      if (this.isValidOrderDetail(taskDetail)) {
                        onNewItem(taskDetail);
                        taskDetailItemClear();
                      }
                    }}>+</Button>}
                </th>
              </tr>
            </thead>
            <tbody>
              {task.items.map((item, idx) =>
                <tr id={`orderItemRow-${idx}`} data-test-id={`orderItemRow-${idx}`} key={idx}>
                  <td>{idx + 1}</td>
                  <td>{item.item || 'No item'}</td>
                  <td>{item.description || 'No description'}</td>
                  <td>{item.quantity}</td>
                  <td>{item.price}</td>
                  <td>{task.status !== taskStatus.COMPLETED && <Button id={`removeOrderItemBtn-${idx}`} data-test-id={`removeOrderItem-${idx}`} type="button" onClick={() => { onRemoveItem(idx) }}>-</Button>}</td>
                </tr>)}
            </tbody>
          </Table>
        </Row>
        {this.renderInvoiceDetails()}
        <br />
        <Row>
          <FormGroup className="form-group-default form-group-default-select2">
            <label>Starting Point</label>
            <Select
              name="origin"
              id="startingPoint"
              data-test-id="startingPoint"
              value={task.startingPoint}
              onChange={(e) => onStartingPointChanged(e.target.value)}
              placeholder={'Select a starting point'}
              options={startingOptions}
              readOnly={readOnly}
            />
          </FormGroup>
          <FormGroup className={"form-group-default input-group m-b-10"}>
            <div className="form-input-group">
              <label>Fuel Surcharge</label>
              <Input
                name="fuelSurcharge"
                id="fuelSurcharge"
                data-test-id="fuelSurcharge"
                placeholder={`Default ${companyFuelSurcharge}`}
                min={0}
                max={100}
                type="number"
                step="0.01"
                className="form-control"
                value={task.fuelSurcharge || ''}
                disabled={readOnly}
                onChange={event => onFuelSurchargeChanged(event.target.value)}
              />
            </div>
            <InputGroupAddon addonType="append">.00</InputGroupAddon>
          </FormGroup>
        </Row>
        {readOnly &&
          (
            <Row>
              <br />
              <Col md="6" className={'p-3 events-timeline'}>
                <div className="timeline-wrapper">
                  <h4>Timeline</h4>
                  <hr />
                  <div className="list-wrapper">
                    <ul>
                      {
                        task.details && task.details.events && task.details.events.map((event, idx) => (
                          <li className="event" key={idx}>
                            <p className={'event-title'}>{EVENT_TYPES.get(event.name)}</p>
                            <p>
                              {event.location && event.location.lat && event.location.lon &&
                                <a target="_blank" rel="noopener noreferrer"
                                  href={`https://www.google.com/maps/search/?api=1&query=${event.location.lat},${event.location.lon}`}>
                                  {event.location.lat.toFixed(3)},{event.location.lon.toFixed(3)}&nbsp;-&nbsp;</a>
                              }
                              <time>{moment(event.time * 1000).format(DATE_TIME_FORMAT)}</time>
                            </p>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </Col>
              {task.status !== taskStatus.CANCELED &&
                <Col md="6" className={'events-timeline'}>
                  <DriverRouteMap task={task.id} status={task.status} />
                </Col>
              }
            </Row>)
        }
        {mapbox.showInstructions && orderInstructions && !readOnly &&
          <TaskInstructions instructions={orderInstructions}> </TaskInstructions>
        }
        <br />
        <Row>
          <h3>Notes</h3>
        </Row>
        <Row>
          <FormGroup className={`form-group-default${readOnly ? ' readonly' : ''}`}>
            <Label for="notes">Notes</Label>
            <textarea
              name="notes"
              id="notes"
              data-test-id="notes"
              className="form-control"
              placeholder="Enter notes for driver"
              value={task.notes || ''}
              onChange={event => onNotesChanged(event.target.value)}
              readOnly={readOnly}></textarea>
          </FormGroup>
        </Row>
        <Row>
          <h3>Driver requirements</h3>
        </Row>
        {requirements.map((requirement, idx) =>
          <Row key={`${idx}`}>
            <Checkbox
              label={requirement.label}
              id={`driverRequirement-${idx}`}
              data-test-id={`driverRequirement-${idx}`}
              checked={task.requirements[requirement.name] || false}
              defaultValue={requirement.name}
              onChange={event => { this.handleRequirementChange(event, requirement.name) }}
              readOnly={readOnly} />
          </Row>)}
        {currentUser.role === roles.ADMIN && process.env.NODE_ENV !== 'production' &&
          <Row>
            <strong>Simulation</strong>
            <Container>
              <Row>
                <Checkbox
                  label='Driver Simulation'
                  id="driverRequirementSimulation"
                  data-test-id="driverRequirementSimulation"
                  checked={task.simulation || false}
                  onChange={event => { onSimulateChanged(!task.simulation) }}
                  readOnly={readOnly}
                />
              </Row>
            </Container>
          </Row>}
        <br />
        <Row>
          <span style={{ color: 'red' }}>{!success && error && error.message}</span>
          <span style={{ color: 'black' }}>{success && success.message}</span>
        </Row>
        <Row>
          <Col>
            {!readOnly && <Button id="createOrderBtn" data-test-id="createOrderBtn" color="primary" size="lg" className="mr-2" onClick={this.createTask}>Save</Button>}
            <Button id="cancelOrderBtn" data-tests-id="cancelOrderBtn" color="secondary" size="lg" onClick={toggle}>{readOnly ? 'Back' : 'Cancel'}</Button>
          </Col>
        </Row>
        <Row>&nbsp;</Row>
      </Form>
    </Fragment>;
  }

  updateSelectedRecipient(value) {
    const [recipientId, destinationId] = value.split('-');
    this.setState(prevState => ({
      recipient: this.getSelectedRecipient(recipientId),
      destination: this.getSelectedDestination(destinationId)
    }));
    this.props.onDestinationIdChanged(destinationId)
  }

  getSelectedRecipient(recipientId) {
    return this.props.recipients.filter(item => item.id === recipientId)[0];
  }

  getSelectedDestination(destinationId) {
    return this.props.destinations.filter(item => item.id === destinationId)[0];
  }
}

const mapStateToProps = ({
  destination,
  task,
  taskDetail,
  invoiceDetail,
  firestore: {
    ordered: { destinations, tasks, settings} },
  orderInstructions
}, { drivers, task: taskProps }) => {
  settings = (settings && settings.length && settings[0]);
  return {
    destination,
    task: { ...task, driver: drivers.find(driver => driver.id === task.driverId), ...taskProps },
    taskDetail,
    invoiceDetail,
    destinations,
    tasks,
    settings,
    ...orderInstructions
  }
}

// TODO: How to reuse reducers: attachNestedReducers or https://stackoverflow.com/q/36786244
const mapDispatchToProps = {
  ...destinationActions,
  ...taskActions,
  ...taskDetailActions,
  ...invoiceDetailActions,
  ...instructionsActions,
};

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps)
)(TaskForm);
export default FirestoreConnected;
