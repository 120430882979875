import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import WithAuthorization from 'components/WithAuthorization';
import { AppLayout } from 'components/Layout';
import Loading from 'components/Loading';
import { authCondition } from 'utils';

const AppRoute = ({ component: Component, location, ...rest }) => {
  const [showLoading, setShowLoading] = useState(false);
  
  useEffect(() => {
    setShowLoading(true);
  }, [location.pathname]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [showLoading]);

  const getComponent = () => showLoading ? Loading : Component;

  return (
    <Route
      {...rest}
      render={() => (
        <AppLayout {...rest} component={getComponent()} />
      )}
    />
  )
};

export default WithAuthorization(authCondition)(AppRoute);
