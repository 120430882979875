import React from 'react';
import { Pin } from '.';
import { gray } from 'constants/colors';
import { getContrastColor } from 'utils';

class LabelPin extends Pin {
  propsDefault = {
    size: 40,
    fill: gray,
    className: '',
  }

  render() {
    const {
      size = this.propsDefault.size,
      fill = this.propsDefault.fill,
      onClick = this.propsDefault.onClick,
      className = this.propsDefault.className
    } = this.props;

    const style = {
      backgroundColor: fill,
      color: getContrastColor(fill),
      width: size,
      height: size,
      marginLeft: (-1 * (size / 2)),
      marginTop: (-1 * (size / 2)),
    }

    return (<div
      className={`pin ${className}`}
      style={style}
      onClick={onClick}>{this.state.text}</div>);
  }
}

export default LabelPin;
