import React, { Component } from 'react';
import { actions as notificationActions } from "reducers/notification";
import { connect } from "react-redux";
import { compose } from "redux";
import * as collections from "constants/collections";
import { isLoaded } from "react-redux-firebase";
import Moment from 'react-moment';

import {
  firestoreConnect,
  withFirebase,
  withFirestore
} from "react-redux-firebase";

const intervalToShow = 5;

class Notification extends Component {
  state = {
    itemsToShow: intervalToShow,
  };

  constructor(props) {
    super(props);
    this.showToggle = this.showToggle.bind(this);
    this.isMessageRead = this.isMessageRead.bind(this);
  }

  showToggle(notification) {
    const notificationHeading = document.getElementById(notification.id);
    if (notificationHeading.classList.contains('open')) {
      notificationHeading.classList.remove("open");
    } else {
      notificationHeading.classList.add("open");
      this.setNotificationAsRead(notification)
    }
  }

  setNotificationAsRead(notification) {
    const { firestore, firebase } = this.props;
    firestore
      .collection(collections.NOTIFICATION)
      .doc(notification.id)
      .update({
        read: firestore.FieldValue.arrayUnion(firebase.auth().currentUser.uid)
      })
      .then(result => {

      });
  }

  getNotificationIconType(item) {
    switch (item.type) {
      case 'info':
        return (item.extra.event === "task:completed" ? "pg-map" : "pg-info");
      case 'warning':
        return "fa fa-exclamation-triangle";
      case 'danger':
        return "fa fa-exclamation-triangle";
      default:
        return null;
    }
  }

  isMessageRead(notification) {
    const { firebase } = this.props;

    if (notification.read !== undefined) {
      const hasRead = notification.read.filter(item => item === firebase.auth().currentUser.uid);
      return (hasRead.length > 0)
    } else {
      return false;
    }
  }

  showMoreNotifications() {
    this.setState(prevState => ({
      itemsToShow: prevState.itemsToShow + intervalToShow
    }));
  }

  render() {
    const { notifications } = this.props;
    const { itemsToShow } = this.state;
    let hrefLink = '#';

    if (isLoaded(notifications)) {
      const currentNotifications = notifications.slice(0, itemsToShow);
      const readMore = notifications.length > itemsToShow;
      return (
        <div className="notification-panel">
          <div className="notification-body scrollable">
            {currentNotifications.map((item, key) => (
              <div key={key} className={`notification-item ${this.isMessageRead(item) ? '' : 'unread'} clearfix`}>
                <div className="heading" id={item.id}>
                  <span className={`text-${(item.extra.event === "task:completed") ? 'complete' : `${item.type}`} pull-left`}>
                    <i className={`${this.getNotificationIconType(item)} m-r-10`}></i>
                    <span className="bold">{item.name}</span>
                  </span>
                  <div className="pull-right">
                    <div className="thumbnail-wrapper d16 circular inline m-t-15 m-r-10 toggle-more-details">
                      <div onClick={() => this.showToggle(item)}><i className="fa fa-angle-left"></i></div>
                    </div>
                    <span className="time"><Moment toNow>{item.createdAt}</Moment></span>
                  </div>
                  <div className="more-details">
                    <div className="more-details-inner">
                      <h5 className="semi-bold fs-16">{item.message}</h5>
                    </div>
                  </div>
                </div>
                <div className="option" data-toggle="tooltip" data-placement="left" title="mark as read">
                  <a href={hrefLink} onClick={() => this.setNotificationAsRead(item)}>
                    <i className="mark"></i>
                  </a>
                </div>
              </div>
            ))}
          </div>
          {(readMore || !notifications.length) &&
            <div className="notification-footer text-center">
              {readMore && <a href={hrefLink} onClick={() => this.showMoreNotifications()}>Read more notifications</a>}
              {!notifications.length && <span>No notifications</span>}
            </div>
          }
        </div>
      )
    }
    return "";
  }
}
const mapStateToProps = ({ firestore: { ordered: { notifications, } }, firebase }) => ({
  notifications: notifications &&
    notifications.filter(item => item.recipients && item.recipients.includes(firebase.auth.uid))
});
const mapDispatchToProps = { ...notificationActions };

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  firestoreConnect([
    {
      collection: collections.NOTIFICATION,
    },
  ]),
  connect(mapStateToProps, mapDispatchToProps),
)(Notification);

export default FirestoreConnected;
