import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Button, Table, CustomInput, Input, Row } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import {
  firestoreConnect,
  withFirebase,
  withFirestore,
  isLoaded
} from 'react-redux-firebase';
import 'firebase/firestore'; // TODO: Do we really need this? TEST!
import WithLoader from 'components/WithLoader';
import * as collections from 'constants/collections';
import { actions as recipientsActions } from 'reducers/recipient';
import { actions as fetchingActions } from 'reducers/fetching';
import { actions as destinationActions } from 'reducers/destination';
import { actions as metrcLicenseActions } from 'reducers/mraLicense';
import { NotificationManager, NotificationPosition } from 'components/Layout';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input'
import { Destination } from 'components/Destination';
import * as destinations from 'constants/destinations';
import Select from 'components/Select';
import _ from 'lodash';
import { now, collator, notificationTypes } from 'utils';


class Recipients extends Component {
  constructor(props) {
    super(props);
    this.showRecipient = this.showRecipient.bind(this);
    this.showList = this.showList.bind(this);

    this.state = {
      activeDiv: "list",
      recipient: this.props.recipient,
      destination: this.props.destination,
      result: null,
      destinationForErrors: false
    };
  }

  handleChange(event) {
    const { firestore } = this.props;

    if (
      window.confirm(
        "Please confirm to " +
        (event.target.checked ? "enable" : "disable") +
        " this item ?"
      )
    ) {
      firestore
        .collection(collections.DESTINATION)
        .doc(event.target.id)
        .update({
          active: event.target.checked
        })
        .then(result => { });
    } else {
      event.target.checked = !event.target.checked;
    }
  }
  
  showList() {
    this.props.clear();
    this.setState({ activeDiv: 'list' });
  }

  static propTypes = {
    role: PropTypes.string,
    className: PropTypes.string
  };

  setCustomerAccount = async (recipientId) => {
    const { recipient, firestore } = this.props;
    const result = await firestore.collection(collections.USER).where('recipientId', '==', recipient.id).get();
          
    if (result.empty && recipient.enableAccount) {
      const data = {
        recipientId: recipientId,
        disabled: false,
        role: "customer",
        password: recipient.enableAccountPassword,
        email: recipient.email,
        firstName: recipient.name
      }
      await firestore.add('newUsers', data);
    } else if (!result.empty) {
      const user = result.docs[0].data();
      if (user.disabled);
      const data = {
        disabled: recipient.enableAccount,
        modifiedAt: now()
      }

      await firestore
      .collection(collections.USER)
      .doc(user.uid)
      .update(data);
    }
  }

  saveRecipient = async () => {
    try {
      const { recipient, firestore, clear, onRecipientNew } = this.props;
      const recipientData = _.pick(recipient, [
        'name',
        'phone',
        'email',
        'billingAddressId',
        'metrcLicenseNumber',
        'smsNotification',
        'emailNotification',
        'enableAccount',
        'notificationTypes'
      ]);

      if (recipient.enableAccount) {
        if (recipient.enableAccountPassword !== recipient.enableAccountConfirmPassword) {
          NotificationManager.error('Your password and confirmation password do not match', null, NotificationPosition.TOP_LEFT);
          return;
        }
      }

      let data = {
        ...recipientData,
        ...(!recipient.id ? { createdAt: now() } : {}),
        arrivingNotificationInterval: recipient.arrivingNotificationInterval || 30,
        disabled: false,
        modifiedAt: now()
      }

      this.props.setLoading(true);
      if (recipient.id) {
        await firestore
          .collection(collections.RECIPIENT)
          .doc(recipient.id)
          .update(data);

        await this.setCustomerAccount(recipient.id);        

        this.showList();
        onRecipientNew();
        NotificationManager.success('Recipient successfully Updated', null, NotificationPosition.TOP_LEFT);

      } else {
        const result = await firestore.collection(collections.RECIPIENT).where('email', '==', data.email).get();

        if (!result.empty) {
          NotificationManager.error(
            'There is already a customer registered with this email.',
            null,
            NotificationPosition.TOP_LEFT
          );
          this.props.setLoading(false);
          return;
        }

        const newRecipient = await firestore.add(collections.RECIPIENT, data);
        if (newRecipient && newRecipient.id) {
          await this.setCustomerAccount(newRecipient.id);
        }
        
        //all good
        clear();
        this.showList();
        NotificationManager.success(
          'Recipient successfully created',
          null,
          NotificationPosition.TOP_LEFT
        );
      }

    } catch {
      NotificationManager.error(
        'An error occurred trying to save the customer.',
        null,
        NotificationPosition.TOP_LEFT
      );

    } finally {
      this.props.setLoading(false);
    }
  };

  saveDestination = async () => {
    try {
      const { recipient, firestore, clear, destination: { [destinations.RECIPIENT_ADDRESS]: destination } } = this.props;
      if (
        destination.address.state === "" ||
        destination.address.country === "" ||
        destination.address.formatted === "" ||
        destination.address.line1 === ""
      ) {
        NotificationManager.error("A valid address is required!", null, NotificationPosition.TOP_LEFT);
        return;
      }

      const data = {
        ...destination,
        owner: recipient.id
      };

      this.props.setLoading(true);

      await firestore.add(collections.DESTINATION, data);
      //al good
      this.setState({ destinationForErrors: false });
      // clear destination
      clear(destinations.RECIPIENT_ADDRESS);

    } catch {
      NotificationManager.error(
        'An error occurred trying to save the address.',
        null,
        NotificationPosition.TOP_LEFT
      );

    } finally {
      this.props.setLoading(false);
    }
  };

  showRecipient = recipient => {
    const { onRecipientEdit, onRecipientNew } = this.props;
    this.setState({
      activeDiv: 'form',
      destinationForErrors: false
    });

    if (!!recipient) {
      onRecipientEdit(recipient);
    } else {
      onRecipientNew();
    }
  };

  getList = () => {
    const { recipients } = this.props;
    const onRowClicked = recipient => {
      this.showRecipient(recipient);
    }

    const sortedList = [...recipients];
    sortedList.sort((a, b) => collator.compare(a.name, b.name));

    const columns = [
      {
        name: '#',
        maxWidth: '5%',
        center: true,
        selector: 'idx',
        sortable: true,
      },
      {
        name: 'Name',
        left: true,
        selector: 'name',
        sortable: true
      },
      {
        name: 'Phone',
        left: true,
        selector: 'phone',
        sortable: true
      },
      {
        name: 'Actions',
        left: true,
        button: true,
        cell: recipient =>
          <button
            className="btn btn-link"
            href="#"
            onClick={() => this.deleteRecipient(recipient)}>
            <i className="fa fa-trash" />
          </button>
      },
    ]

    return (
      <DataTable
        columns={columns}
        dense
        data={sortedList.map((item, idx) => ({ idx: (idx + 1), ...item }))}
        highlightOnHover
        noHeader
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50]}
        responsive
        pointerOnHover
        onRowClicked={onRowClicked}
      />
    );
  };

  getDestinationsTableList() {
    const { destinations } = this.props;
    return (
      <div className="row">
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Zip Code</th>
              <th>Country</th>
              <th>Name</th>
              <th>Actions</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {destinations.map((item, idx) => (
              <tr key={idx}>
                <td>{idx}</td>
                <td>{item.address.postalCode}</td>
                <td>{item.address.country}</td>
                <td>{item.address.line1}</td>
                <td>
                  <CustomInput
                    type="switch"
                    id={item.id}
                    name={item.id}
                    defaultChecked={item.active}
                    onChange={this.handleChange.bind(this)}
                  />
                </td>
                <td>
                  <button
                    className={"btn btn-link"}
                    href="#"
                    onClick={() => this.deleteAddress(item)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  getDestinationForm() {
    const { destination } = this.props;
    return ( 
      <div className="row">
        <div className="col-xs-12 col-md-12 p-t-15">
          <h5>Addresses</h5>
          <form
            onSubmit={e => {
              e.preventDefault();
              this.saveDestination();
            }} >
            <div className="mt-2">
              <Destination
                id="new-address"
                as={destinations.RECIPIENT_ADDRESS}
                readOnly={true} />
            </div>
            <Button color="primary" type="submit">
              {destination && destination.id ? "Update" : "Add"}
            </Button>&nbsp;
            <Button
              type="button"
              color="secondary"
              onClick={() => this.showList()}
            >
              Cancel
              </Button>
            <br />
            <br />
          </form>    
        </div>
      </div>
    );
  }

  async handleMetrcLicenseSave() {
    const { setLoading } = this.props;
    try {
      const { recipient, firestore, onMetrcLicenseClear, mraLicense, mraLicenses } = this.props;
      if (mraLicense.number === '') {
        NotificationManager.error("A valid MRA License number is required!", null, NotificationPosition.TOP_LEFT);
        return;
      }

      const data = {
        ...mraLicense,
        recipientId: recipient.id
      };

      setLoading(true);

      // TODO: VERIFY IF LICENSE IS UNIQUE
      const exists = mraLicenses && mraLicenses.find(l => l.number === data.number);

      if (exists) {
        NotificationManager.error("There is already a MRA License registered with this number!", null, NotificationPosition.TOP_LEFT);
        return;
      }

      await firestore.add(collections.MRA_LICENSE, data);
      onMetrcLicenseClear();
    } catch {
      NotificationManager.error(
        'An error occurred trying to save the MRA license.',
        null,
        NotificationPosition.TOP_LEFT
      );
    } finally {
      setLoading(false);
    }
  }

  async handleMetrcLicenseDelete(item) {
    if (window.confirm('Please confirm to delete this MRA License')) {
      try {
        const { firestore } = this.props;
        firestore
          .collection(collections.MRA_LICENSE)
          .doc(item.id)
          .delete()
          .then(result => {
            NotificationManager.success('Customer\'s MRA License successfully deleted.', null, NotificationPosition.TOP_LEFT);
          });
      } catch (error) {
        NotificationManager.error('An error occurred trying to delete the Customer\'s MRA License.', null, NotificationPosition.TOP_LEFT);
      }
    }
  }

  handleMetrcLicenseDisable(event) {
    const { firestore } = this.props;
    if (
      window.confirm(
        'Please confirm to ' +
        (event.target.checked ? "enable" : "disable") +
        ' this MRA License ?'
      )
    ) {
      firestore
        .collection(collections.MRA_LICENSE)
        .doc(event.target.id)
        .update({
          disabled: !event.target.checked
        });
    } else {
      event.target.checked = !event.target.checked;
    }
  }

  getMetrcLicenses() {
    const { mraLicenses, mraLicense, onMetrcLicenseNumberChanged } = this.props;

    return (
      <div className="row">
        <div className="col-xs-12 col-md-12 p-t-15">
          <form onSubmit={e => {
            e.preventDefault();
            this.handleMetrcLicenseSave();
          }} >
          <h5>MRA Licenses</h5>
          <div className="form-group form-group-default">
            <label>MRA License #</label>
            <div className="controls">
              <input
                type="text"
                name="metrc"
                placeholder="MRA License #"
                className="form-control"
                value={mraLicense.number || ''}
                onChange={event => {
                  onMetrcLicenseNumberChanged(event.target.value);
                }}
              />
            </div>
          </div>
          <Button color="primary" type="submit">
            Add
          </Button>&nbsp;
          <Button 
            type="button"
            color="secondary"
              onClick={() => this.showList()}
            >
              Cancel
          </Button>
          </form>
        </div>

        <div className="col-xs-12 col-md-12 p-t-15">
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Metrc License</th>
                <th>Actions</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {mraLicenses.map((item, idx) => (
                <tr key={idx + 1}>
                  <td>{idx + 1}</td>
                  <td>{item.number}</td>
                  <td>
                    <CustomInput
                      type="switch"
                      id={item.id}
                      name={item.number}
                      defaultChecked={!item.disabled}
                      onChange={e => this.handleMetrcLicenseDisable(e)}
                      style={{zIndex: '10000'}}
                    />
                  </td>
                  <td>
                    <button
                      className={"btn btn-link"}
                      href="#"
                      onClick={() => this.handleMetrcLicenseDelete(item)}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    ); 
  }

  handleNotificationChange(event, name) {
    const value = event.target.checked;
    const { onNotificationTypeChanged } = this.props;
    onNotificationTypeChanged(name, value)
  }
  
  handleNotificationSmsAndEmailChange(event, name) {
    const { onNotificationTypeChanged, onSmsNotificationChanged, onEmailNotificationChanged, recipient } = this.props;

    if (name === "smsNotification") {
      onSmsNotificationChanged(event);
    } else{
      onEmailNotificationChanged(event);
    }

    if (!recipient.smsNotification && !recipient.emailNotification) {
      notificationTypes.map(item => {
        return onNotificationTypeChanged(item.name, false);
      })
    }
  }

  getForm = () => {
    const {
      onNameChanged,
      onPhoneChanged,
      onEmailChanged,
      onBillingAddressChanged,
      onArrivingNotificationIntervalChanged,
      recipient,
      destinations,
      settings,
      onEnableAccountChanged,
      onEnableAccountPasswordChanged,
      onEnableAccountConfirmPasswordChanged,
      customer
    } = this.props;
    
    return (
      <div className="row">
        <div className="col-xs-12 col-md-12">
          <form
            className="p-t-15"
            onSubmit={(e) => {
              e.preventDefault();
              this.saveRecipient();
            }}
          >
            <div className="form-group form-group-default">
              <label>Name</label>
              <div className="controls">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="form-control"
                  required
                  value={recipient.name || ""}
                  onChange={(event) => {
                    onNameChanged(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group form-group-default">
              <label>Phone</label>
              <div className="controls">
                <PhoneInput
                  className="form-control"
                  placeholder="Phone Number"
                  defaultCountry="US"
                  type="text"
                  required
                  name="phone"
                  value={recipient.phone || ""}
                  onChange={(event) => {
                    onPhoneChanged(event);
                  }}
                />
              </div>
            </div>
            <div className="form-group form-group-default">
              <label>Email</label>
              <div className="controls">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                  required
                  value={recipient.email || ""}
                  onChange={(event) => {
                    onEmailChanged(event.target.value);
                  }}
                />
              </div>
            </div>
            {/* This works as a select component */}
            {destinations.length > 0 && (
              <div
                className="form-group form-group-default form-group-default-select2"
                id="billing-address-wrapper"
              >
                <label>Billing Address</label>
                <Select
                  name="billingAddress"
                  id="billing-address"
                  value={recipient.billingAddressId || ""}
                  options={destinations.map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                  onChange={(event) => {
                    onBillingAddressChanged(event.target.value);
                  }}
                />
              </div>
            )}
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group form-group-default">
                  <label>Account</label>
                  <div className="controls">
                    <CustomInput
                      type="checkbox"
                      id="accountEnable"
                      name="accountEnable"
                      label="ENABLE"
                      checked={recipient.enableAccount}
                      onChange={(event) =>
                        onEnableAccountChanged(event.target.checked)
                      }
                    />
                    {recipient.enableAccount && !customer && (
                      <React.Fragment>
                        <div className="form-group form-group-default mt-2">
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            className="form-control"
                            required
                            value={recipient.enableAccountPassword || ''}
                            onChange={(event) => {
                              onEnableAccountPasswordChanged(event.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group form-group-default mt-2">
                          <input
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            className="form-control"
                            required
                            value={recipient.enableAccountConfirmPassword || ''}
                            onChange={(event) => {
                              onEnableAccountConfirmPasswordChanged(event.target.value);
                            }}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group form-group-default">
                  <label>Notifications</label>
                  <div className="controls">
                    <CustomInput
                      type="checkbox"
                      id="smsNotification"
                      name="smsNotification"
                      label="SMS"
                      checked={recipient.smsNotification}
                      onChange={event =>
                        this.handleNotificationSmsAndEmailChange(event.target.checked, "smsNotification")
                      }
                    />
                    <CustomInput
                      type="checkbox"
                      id="emailNotification"
                      name="emailNotification"
                      label="EMAIL"
                      checked={recipient.emailNotification}
                      onChange={event =>
                        this.handleNotificationSmsAndEmailChange(event.target.checked, "emailNotification")
                      }
                    />
                  </div>
                </div>
                  {(recipient.smsNotification || recipient.emailNotification) && 
                  <div className="col-sm-12">
                  <div className="form-group form-group-default">
                    <label>Notification Types</label>
                    <div className="controls">
                    {notificationTypes.map((notification, idx) =>
                      <Row key={`${idx}`}>
                      <CustomInput
                          type="checkbox"
                          label={notification.label}
                          id={`notificationType-${idx}`}
                          checked={recipient.notificationTypes[notification.name] || false}
                          defaultValue={notification.name}
                          onChange={event => { this.handleNotificationChange(event, notification.name) }}
                          />
                      </Row>)}
                    </div>
                  </div>
                </div>
                }
              </div>
              {recipient.notificationTypes["on_order_arriving_interval"] && 
                <div className="col-sm-6">
                  <div className="form-group form-group-default">
                    <label>Arriving Notification Interval</label>
                    <div className="controls">
                      <Input
                        type="select"
                        id="arrivingNotificationInterval"
                        name="arrivingNotificationInterval"
                        onChange={(e) => onArrivingNotificationIntervalChanged(e.target.value)}
                        value={recipient.arrivingNotificationInterval || (settings && settings.arrivingNotificationInterval) || '30'}>
                        {['15', '30', '45', '60'].map(item =>
                          <option
                            key={item}
                            value={item}>
                            {`${item} min`}
                          </option>)}
                      </Input>
                    </div>
                  </div>
                </div>
              }
            </div>
            <Button color="primary" type="submit">
              {recipient.id ? "Update" : "Create"}
            </Button>
            &nbsp;
            <Button
              type="button"
              color="secondary"
              onClick={() => this.showList()}
            >
              Cancel
            </Button>
            <br />
          </form>
          {recipient.id && this.getMetrcLicenses()}
          {recipient.id && this.getDestinationForm()}
        </div>
      </div>
    );
  };

  render() {
    const { recipients, recipient } = this.props;

    if (isLoaded(recipients)) {
      return (
        <div>
          <div>
            {this.state.activeDiv !== "form" ? (
              <Button color="primary" onClick={() => this.showRecipient()}>
                New Customer
              </Button>
            ) : (
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => this.showList()}
                >
                  Back
              </Button>
              )}
          </div>
          {this.state.activeDiv === "form" ? this.getForm() : this.getList()}
          {recipient.id != null && this.state.activeDiv === "form"
            ? this.getDestinationsTableList()
            : null}
        </div>
      );
    }
    return "";
  }

  deleteRecipient(item) {
    if (window.confirm("Please confirm to delete this Customer")) {
      const { firestore } = this.props;
      firestore
        .collection(collections.RECIPIENT)
        .doc(item.id)
        .delete()
        .then(result => {
          NotificationManager.success('Customer successfully Deleted', null, NotificationPosition.TOP_LEFT);
        });
    }
  }

  async deleteAddress(address) {
    if (window.confirm('Please confirm to delete this Customer\'s address')) {

      try {
        const { firestore } = this.props;
        /*
          check if there are no tasks associated with recipient's address
          make two queries because firebase compounding queries have limited support for OR operator
          https://firebase.google.com/docs/firestore/query-data/queries#compound_queries
        */
        const hasDestinationResult = await firestore.collection(collections.TASK).where("destinationId", "==", address.id).get();
        const hasOriginResult = await firestore.collection(collections.TASK).where("originId", "==", address.id).get();
        
        // address does not have an order associated
        if (hasDestinationResult.empty && hasOriginResult.empty) {
          await firestore
            .collection(collections.DESTINATION)
            .doc(address.id)
            .delete()
        } else {
          // soft delete
          await firestore
            .collection(collections.DESTINATION)
            .doc(address.id)
            .update({
              disabled: true
            });
        }
        NotificationManager.success('Customer\'s address successfully deleted', null, NotificationPosition.TOP_LEFT);
      } catch (error) {
        NotificationManager.error('An error occurred trying to delete the Customer\'s address.', null, NotificationPosition.TOP_LEFT);
      }
    }
  }
}

const mapStateToProps = ({
  mraLicense,
  recipient,
  destination,
  firestore: {
    ordered: { recipients, settings }
  },
  firestore: {
    ordered: { destinations, mraLicenses, users }
  }
}) => ({
  mraLicense,
  recipient,
  destination,
  recipients: recipients || [],
  destinations: destinations.filter(item => item.owner === recipient.id && !item.disabled),
  settings: settings && settings.length && settings[0],
  mraLicenses: (mraLicenses && mraLicenses.filter(item => item.recipientId === recipient.id)) || [],
  customer: users && (users.filter(item => item.recipientId === recipient.id))[0],
});

const mapDispatchToProps = {
  ...recipientsActions,
  ...fetchingActions,
  ...destinationActions,
  ...metrcLicenseActions
};

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  firestoreConnect([
    {
      collection: collections.DESTINATION
    },
    {
      collection: collections.MRA_LICENSE
    }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Recipients);

export default WithLoader(FirestoreConnected);
