import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';

import { actions as resetActions } from 'reducers/resetPassword';
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import _ from "lodash";
import { INVALID_EMAIL_ERROR_CODE, USER_NOT_FOUND_ERROR_CODE, EMAIL_REGEX, MIN_RECAPTCHA_SCORE, MAX_LOGIN_ATTEMPTS_ALLOWED } from 'constants/firebaseAuth';
import { checkRecaptcha, logActionAttempt, clearActionAttempt, getActionAttempt } from 'utils';
import { google, login as loginConfig } from 'config';

class ForgotPassword extends Component {
  static propTypes = {
    emailReset: PropTypes.string,
    onEmailResetChanged: PropTypes.func,
    reset: PropTypes.func,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    recaptcha: PropTypes.object
  };

  // clean modal in case it was closed with keyboard
  toggleModal = () => {
    const { toggle, clear } = this.props;
    clear();
    toggle();
  }

  // regex based on https://emailregex.com/
  isValidEmail = (email) => {
    const re = new RegExp(EMAIL_REGEX);
    return re.test(email);
  }

  sendPasswordResetEmail = () => {
    const {
      emailReset,
      firebase,
      onError,
      onSuccess,
      toggle,
      clear
    } = this.props;

    if (_.isEmpty(emailReset)) {
      onError({
        message: 'Email is required.',
      });
      return;
    }

    if (!this.isValidEmail(emailReset)) {
      onError({
        message: 'Please enter a valid email.',
      });
      return;
    }

    const handleSucceded = () => {
      onSuccess({
        message: 'An email has been sent',
      });
      setTimeout(() => {
        clear();
        toggle();
      }, 850);
    }

    const { resetTime: { amount, unit } } = loginConfig;
    firebase.auth().sendPasswordResetEmail(emailReset).then(() => {
      logActionAttempt('forgotPassword', amount, unit);
      handleSucceded();
    }).catch(error => {
      // Firebase status codes: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#sendpasswordresetemail
      let message = 'An error ocurred trying to send you an email to reset your password.';
      logActionAttempt('forgotPassword', amount, unit);

      if (error.code === USER_NOT_FOUND_ERROR_CODE) {
        handleSucceded();
        return;
      }

      if (error.code === INVALID_EMAIL_ERROR_CODE) {
        message = 'An invalid email was provided, please check.';
      }

      onError({ message });
    });
  }
  
  handleRecaptchaValidation = (callback) => {
    const { recaptcha, onError } = this.props;
    recaptcha.execute(google.recaptcha.siteKey, { action: 'forgotPassword' })
      .then(async token => {
        const result = await checkRecaptcha(token);

        if (result.status === 'failed') {
          onError({ message: result.message });
          return;
        }

        if (result.score <= MIN_RECAPTCHA_SCORE) {

          onError({ message: 'Unable to send password recovery email at this moment.' });
          return;
        }

        callback();
      });
  }

  onSubmit = event => {
    const { resetTime: { unit } } = loginConfig;
    const { recaptcha, onError } = this.props;
    event.preventDefault();

    try {
      if (!recaptcha) {
        return;
      }

      const attemptpsJson = getActionAttempt('forgotPassword');
      const attemptps = attemptpsJson && JSON.parse(attemptpsJson);

      if (attemptps) {
        const end = moment(attemptps.timestamp);
        const now = moment();
        const difference = end.diff(now, unit);

        // reset attempts counter
        if (difference <= 0) {
          clearActionAttempt('forgotPassword');
          this.handleRecaptchaValidation(this.sendPasswordResetEmail);
          return;
        }

        // reject login if attempts exceeded today
        if (difference > 0 && attemptps.count > MAX_LOGIN_ATTEMPTS_ALLOWED) {
          onError({
            message: `Too many password recovery attempts. Please try again in ${difference} ${unit}`
          });
          return;
        }
      }

      // otherwise execute captcha validation
      if (!attemptps || attemptps.count <= MAX_LOGIN_ATTEMPTS_ALLOWED) {
        this.handleRecaptchaValidation(this.sendPasswordResetEmail);
      }

    } catch (err) {
      onError(err);
    }
  }

  render() {
    const { clear, emailReset, onEmailResetChanged, isOpen, toggle, error, success } = this.props;

    return <Modal isOpen={isOpen} toggle={this.toggleModal} className={this.props.className}>
      <form id="form-forgot" className="p-t-15" onSubmit={this.onSubmit}>
        <ModalHeader toggle={toggle}>Forgot Password</ModalHeader>
        <ModalBody>
          Please provide an email to send you a link to reset your password.
          <div className="form-group form-group-default">
            <label>Email</label>
            <div className="controls">
              <input
                type="email"
                name="emailReset"
                placeholder="Email"
                className="form-control"
                required
                autoComplete="emailReset"
                value={emailReset}
                onChange={event => { onEmailResetChanged(event.target.value) }} />
            </div>
          </div>
          <span style={{ color: 'red' }}>{!success && error && error.message}</span>
          <span style={{ color: 'black' }}>{success && success.message}</span>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary">Send</Button>
          <Button color="secondary" onClick={() => { clear(); toggle(); }}>Cancel</Button>
        </ModalFooter>
      </form>
    </Modal>;
  }
}

// export default ForgotPassword;
const mapStateToProps = ({ reset, recaptcha, firebase }) => ({
  ...reset,
  ...recaptcha
});
const mapDispatchToProps = ({
  ...resetActions,
});

const Connected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(ForgotPassword);
export default Connected;