import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isLoaded } from 'react-redux-firebase';
import Checkbox from "components/Checkbox";
import { NotificationManager, NotificationPosition } from 'components/Layout';
import 'react-phone-number-input/style.css';
import flags from 'react-phone-number-input/flags';
import { Button } from 'reactstrap';
import DataTable from 'react-data-table-component';
import PhoneInput from 'react-phone-number-input';
import Select from 'components/Select';
import * as collections from 'constants/collections';
import * as roles from 'constants/roles';
import { collator } from 'utils';
import 'firebase/firestore';

class User extends Component {
  static propTypes = {
    role: PropTypes.string,
    className: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.showAccount = this.showAccount.bind(this);
    this.getNewAccountButton = this.getNewAccountButton.bind(this);
    this.showList = this.showList.bind(this);
    this.state = {
      activeDiv: 'list',
      account: this.props.account,
      result: null,
    };
  }

  showList() {
    this.props.clear();
    this.setState({ activeDiv: 'list' });
  }

  saveAccount = async () => {
    this.props.setLoading(true);
    const { account, firestore, role} = this.props;

    if (!account.role) {
      account.role = role;
    }

    if (account.id) {
      await firestore.collection(collections.USER).doc(account.id).update(account);
      this.showList();
      NotificationManager.success('Account successfully updated', null, NotificationPosition.TOP_LEFT);
    } else {
      // check if user exists
      const result = await firestore.collection(collections.USER).where('email', '==', account.email).get();

      if (!result.empty) {
        NotificationManager.error('There is already a user registered with this email.', null, NotificationPosition.TOP_LEFT);
        this.props.setLoading(false);
        return;
      }
      await firestore.add('newUsers', account);
      NotificationManager.success('Account successfully created', null, NotificationPosition.TOP_LEFT);
      this.showList();
    }

    this.props.setLoading(false);
  };

  showAccount = (driver) => {
    const { onAccountEdit, onAccountNew } = this.props;
    if (!!driver) {
      onAccountEdit(driver);
    } else {
      onAccountNew();
    }

    setTimeout(function () {
      this.setState({ activeDiv: 'form' });
    }.bind(this), 200)
  };

  handleIsAdminChanged(checked) {
    const { onAccountRoleChanged } = this.props;
    let role = checked ? roles.ADMIN : roles.DISPATCHER;
    onAccountRoleChanged(role);
  }

  getAccounts() {
    //overwrite
  }

  getList = () => {
    const columns = [
      {
        name: '#',
        minWidth: '20px !important',
        maxWidth: '40px !important',
        left: true,
        selector: 'idx',
        sortable: true,
        compact: true
      },
      {
        name: 'First Name',
        left: true,
        width: '120px',
        selector: 'firstName',
        sortable: true,
        compact: true
      },
      {
        name: 'Last Name',
        left: true,
        width: '120px',
        selector: 'lastName',
        sortable: true,
        compact: true
      },
      {
        name: 'Email',
        left: true,
        selector: 'email',
        sortable: true,
        compact: true
      },
      {
        name: 'Is Admin?',
        center: true,
        omit: this.props.role !== 'dispatcher',
        cell: account => {
          const isAdmin = account.role === roles.ADMIN;
          return this.props.role === 'dispatcher' &&
          <td>
            <span className={`badge ${isAdmin ? 'badge-success' : ''}`}>
              {isAdmin ? 'Yes' : 'No'}
            </span>
          </td>
        },
        compact: true,
        minWidth: '20px !important',
        maxWidth: '40px !important'
      },
      {
        name: 'Actions',
        left: true,
        button: true,
        cell: account => {
          return <button
            className="btn btn-link"
            href="#"
            onClick={() => this.deleteAccount(account)}>
            <i className="fa fa-trash" />
          </button>
        },
        compact: true
      },
    ]

    const onRowClicked = account => {
      this.showAccount(account);
    }

    const sortedList = [...this.getAccounts()];
    sortedList.sort((a, b) => collator.compare(a.firstName, b.firstName));

    return (
      <DataTable
        columns={columns}
        dense
        data={sortedList.map((item, idx) => ({ ...item, idx: (idx + 1) }))}
        highlightOnHover
        noHeader
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50]}
        responsive
        pointerOnHover
        onRowClicked={onRowClicked}
      />
    );
  }

  getForm = () => {

    const {
      onAccountEmailChanged, onAccountPasswordChanged, onAccountFirstNameChanged, onAccountLastNameChanged,
      onAccountDriverLicenseChanged, onAccountMetrcNumberIdChanged,
      account,
      onAccountNumberPhoneChanged,
      onAccountTerminalChanged,
      role,
      currentUser,
      terminals,
    } = this.props;

    return <form className="p-t-15" onSubmit={e => { e.preventDefault(); this.saveAccount() }} autoComplete="nope">
      <div className="form-group form-group-default">
        <label>Email</label>
        <div className="controls">
          <input
            type="email"
            name="not-email"
            placeholder="Email"
            className="form-control"
            required
            autoComplete={'not-email'}
            spellCheck="false"
            value={account.email || ''}
            onChange={event => { onAccountEmailChanged(event.target.value) }} />
        </div>
      </div>
      {
        !account.id && <div className="form-group form-group-default">
          <label>Password</label>
          <div className="controls">
            <input
              type="password"
              required
              autoComplete={'not-password'}
              spellCheck="false"
              name="not-password"
              placeholder="password"
              className="form-control"
              value={account.password || ''}
              minLength={6}
              onChange={event => { onAccountPasswordChanged(event.target.value) }} />
          </div>
        </div>
      }
      <div className="form-group form-group-default">
        <label>First Name</label>
        <div className="controls">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            className="form-control"
            required
            value={account.firstName}
            onChange={event => { onAccountFirstNameChanged(event.target.value) }} />
        </div>
      </div>
      <div className="form-group form-group-default">
        <label>Last Name</label>
        <div className="controls">
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            className="form-control"
            required
            value={account.lastName}
            onChange={event => { onAccountLastNameChanged(event.target.value) }} />
        </div>
      </div>

      {role === roles.DRIVER &&
        <div className="form-group form-group-default">
          <label>Driver's License</label>
          <div className="controls">
            <input
              type="text"
              name="driverLicense"
              placeholder="Driver's License"
              className="form-control"
              required
              value={account.driverLicense}
              onChange={event => { onAccountDriverLicenseChanged(event.target.value) }} />
          </div>
        </div>}

      {role === roles.DRIVER &&
        <Fragment>
          <div className="form-group form-group-default">
            <label>Phone Number</label>
            <div className="controls">
              <PhoneInput
                flags={flags}
                className="form-control"
                placeholder="Phone Number"
                defaultCountry="US"
                type="text"
                required
                name="phoneNumber"
                value={account.phoneNumber}
                onChange={event => {
                  onAccountNumberPhoneChanged(event);
                }} />
            </div>
          </div>
          <div className="form-group form-group-default">
            <label>Metrc Number Id</label>
            <div className="controls">
              <input
                type="text"
                name="metrcNumberId"
                placeholder="Metrc Number Id"
                className="form-control"
                value={account.metrcNumberId}
                onChange={event => { onAccountMetrcNumberIdChanged(event.target.value) }} />
            </div>
          </div>
          <div className="form-group form-group-default">
            <label>Terminal Location</label>
            <div className="controls">
              <Select
                name="driver"
                id="driver"
                value={account.terminalLocationId}
                options={terminals.map(({ name: label, id: value }) => ({ label, value })) || []}
                onChange={event => onAccountTerminalChanged(event.target.value)} />
            </div>
          </div>
        </Fragment>
      }
      {currentUser.role === roles.ADMIN && role !== roles.DRIVER && <div className="form-group">
        <div className="controls">
          <Checkbox label="Is Admin?" checked={account.role === roles.ADMIN} id={'isAdmin'}
            onChange={event => { this.handleIsAdminChanged(event.target.checked) }} />
        </div>
      </div>}
      {account.role === roles.ADMIN && currentUser.role !== roles.ADMIN ? null : <Button color="primary" type="submit">{account.id ? 'Update' : 'Create'}</Button>}
      <Button type="button" color="secondary" onClick={() => this.showList()}>Cancel</Button>
    </form>;
  }

  render() {
    if (isLoaded(this.getAccounts())) {
      return <div>
        <div>
          {
            this.state.activeDiv !== 'form' ?
              this.getNewAccountButton()
              : <Button type="button" color="secondary" onClick={() => this.showList()}>Back</Button>
          }
        </div>
        {this.state.activeDiv === 'form' ? this.getForm() : this.getList()}
      </div>;
    }
    return '';
  }

  getNewAccountButton() {
    //override
  }

  async deleteAccount(account) {
    if (window.confirm('Please confirm to delete this account')) {
      const { firestore, firebase } = this.props;
      const { currentUser } = firebase.auth();

      // soft delete item, mark as disabled in firestore
      await firestore.collection(collections.USER).doc(account.id).update({ 'disabled': true });

      NotificationManager.success(
        'Account successfully deleted',
        null,
        NotificationPosition.TOP_LEFT,
      );

      // if current user email == user deleted email, then logout
      if (currentUser.email === account.email) {
        firebase.logout();
      }
    }
  }
}

export default User;
