import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { actions as profileActions } from 'reducers/profile';
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import FileUploader from 'react-firebase-file-uploader';

class Profile extends Component {

  state = {
    isUploading: false,
    progress: 0,
    avatarUrl: ''
  };

  static propTypes = {
    email: PropTypes.string,
    uid: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    phone: PropTypes.string,
    onEmailChanged: PropTypes.func,
    onNameChanged: PropTypes.func,
    profile: PropTypes.func,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
  };

  updateProfile = () => {
    const {
      firstName,
      lastName,
      middleName,
      phone,
      role,
      firebase,
      onError,
      onSuccess,
      toggle,
      clear
    } = this.props;

    const { avatarUrl } = this.state;

      let user = firebase.auth().currentUser;

      user.updateProfile({
        displayName: `${firstName} ${lastName}`,
      }).then(() => {
        firebase.updateProfile({
          firstName,
          lastName,
          middleName: middleName || null,
          phone: phone || null,
          avatarUrl: avatarUrl || null,
          role: role || "admin"
        });
        onSuccess({
          message: 'Profile info has been updated',
        });
        setTimeout(() => {
          toggle();
          clear();
        }, 850);
      }).catch(function(error) {
        onError({
          message: 'An error has occurred',
        });
      });

  };

  handleUploadStart = () => this.setState({isUploading: true, progress: 0});

  handleProgress = (progress) => this.setState({progress});

  handleUploadError = (error) => {
    const { onError } = this.props;
    this.setState({isUploading: false});
    onError({
      message: 'An error has occurred when uploading file',
    });
  };

  handleUploadSuccess = (filename) => {
    this.setState({avatar: filename, progress: 100, isUploading: false});
    this.props.firebase.storage().ref('images').child(filename).getDownloadURL()
      .then(url => this.setState({avatarUrl: url}));
  };

  render() {
    const { onFirstNameChanged, onMiddleNameChanged, onLastNameChanged, onPhoneChanged,
      firstName, lastName, middleName, phone,
      isOpen, toggle, error, success, firebase } = this.props;

    return <Modal isOpen={isOpen} toggle={toggle} className={this.props.className}>
      <ModalHeader toggle={toggle}>Profile</ModalHeader>
      <ModalBody>
        <form id="form-profile" className="p-t-15">
          <div className="form-group form-group-default">
            <label>First Name</label>
            <div className="controls">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="form-control"
                required
                autoComplete="name"
                value={firstName}
                onChange={event => {onFirstNameChanged(event.target.value) }} />
            </div>
          </div>
          <div className="form-group form-group-default">
            <label>Middle Name</label>
            <div className="controls">
              <input
                type="text"
                name="middleName"
                placeholder="Middle Name"
                className="form-control"
                autoComplete="name"
                value={middleName || ''}
                onChange={event => {onMiddleNameChanged(event.target.value) }} />
            </div>
          </div>
          <div className="form-group form-group-default">
            <label>Last Name</label>
            <div className="controls">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="form-control"
                required
                autoComplete="name"
                value={lastName}
                onChange={event => {onLastNameChanged(event.target.value) }} />
            </div>
          </div>
          <div className="form-group form-group-default">
            <label>Phone</label>
            <div className="controls">
              <input
                type="text"
                name="phone"
                placeholder="Phone"
                className="form-control"
                required
                autoComplete="name"
                value={phone}
                onChange={event => {onPhoneChanged(event.target.value) }} />
            </div>
          </div>
          <div className="form-group form-group-default">
            <label>Avatar:</label>
            {this.state.isUploading &&
            <p>Progress: {this.state.progress}</p>
            }
            {this.state.avatarUrl &&
            <img src={this.state.avatarUrl} alt="Avatar" />
            }
            <FileUploader
              accept="image/*"
              name="avatarUrl"
              randomizeFilename
              storageRef={firebase.storage().ref('images')}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
              maxHeight={400}
              maxWidth={400}
            />
          </div>
        </form>
        <span style={{color: 'red'}}>{!success && error && error.message}</span>
        <span style={{color: 'black'}}>{success && success.message}</span>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={this.updateProfile}>Update</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>;
  }
}

const mapStateToProps = ({ profile, firebase: { profile: currentProfile } }) => ({
  ...currentProfile,
  ...profile
});
const mapDispatchToProps = ({
  ...profileActions,
});

const FirestoreConnected = compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
)(Profile);
export default FirestoreConnected;
