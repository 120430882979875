import React from 'react';
import moment from 'moment';
import { formatTime } from 'utils';

const TaskInfo = ({ departure, completion }) => {
  let departureTime = moment.unix(departure.time);
  let completionTime = moment.unix(completion.time);

  return (
    <div className="map-pin-popup">
      <div className="d-flex align-items-baseline popup-row">
        <i className="fa fa-map-marker"></i>
        <span>Starting at {formatTime(departure.time)}</span>
      </div>
      <div className="d-flex align-items-baseline popup-row">
        <i className="fa fa-map-pin"></i>
        <span>Arrival at {formatTime(completion.time)}</span>
      </div>
      <div className="d-flex align-items-baseline popup-row">
        <i className="fa fa-clock-o"></i>
        <span>Duration: {moment.duration(completionTime.diff(departureTime)).humanize()}</span>
      </div>
    </div>
  );
}

export default TaskInfo;