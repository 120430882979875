import { EventEmitter } from 'events';
import { v4 as uuidv4 } from 'uuid';
import { NotificationType as Type, NotificationPosition as Position } from './';

const CHANGE = 'change';

class NotificationManager extends EventEmitter {
  constructor() {
    super();

    this.listNotify = [];
  }

  create(notify) {
    const defaultNotify = {
      id: uuidv4(),
      type: Type.INFO,
      position: Position.TOP_RIGHT,
      title: null,
      message: null,
    };
    this.listNotify.push(Object.assign(defaultNotify, notify));
    this.emitChange();
  }

  info(message, title, position, timeOut = 5000, onClick = null) {
    this.create({
      type: Type.INFO,
      message,
      position,
      title,
      timeOut,
      onClick,
    });
  }

  success(message, title, position, timeOut = 5000, onClick = null) {
    this.create({
      type: Type.SUCCESS,
      message,
      title,
      position,
      timeOut,
      onClick,
    });
  }

  warning(message, title, position, timeOut = 5000, onClick = null) {
    this.create({
      type: Type.WARNING,
      message,
      title,
      position,
      timeOut,
      onClick,
    });
  }

  error(message, title, position, timeOut = 5000, onClick = null) {
    this.create({
      type: Type.ERROR,
      message,
      title,
      position,
      timeOut,
      onClick,
    });
  }

  remove(notification) {
    this.listNotify = this.listNotify.filter(n => notification.id !== n.id);
    this.emitChange();
  }

  emitChange() {
    this.emit(CHANGE, this.listNotify);
  }

  addChangeListener(callback) {
    this.addListener(CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }
}

export default new NotificationManager();
