import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import {
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import { Drivers, Dispatchers } from 'components/Users';
import Recipients from './Recipients';
import Vehicles from './Vehicles';
import Terminals from './Terminals';
import GeneralSettings from './GeneralSettings';
import Brands from './Brands';

import 'firebase/firestore';

class Settings extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 0
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    className: PropTypes.string,
  };

  getTabs = () => {
    return [
      {
        title: 'General Settings',
        subtitle: 'Manage your teams and preferences',
        component: <GeneralSettings />,
      },
      {
        title: 'Drivers',
        subtitle: 'Manage drivers and the driver app',
        component: <Drivers currentUser={this.props.currentUser} />,
      },
      {
        title: 'Brands',
        subtitle: 'manage Brands',
        component: <Brands />,
      },
      {
        title: 'Employees',
        subtitle: 'On-board and manage dispatchers',
        component: <Dispatchers currentUser={this.props.currentUser} />,
      },
      {
        title: 'Customers',
        subtitle: 'Manage customer settings',
        component: <Recipients />,
      },
      {
        title: 'Vehicles',
        subtitle: 'Vehicle maintenance',
        component: <Vehicles />,
      },
      {
        title: 'Terminals',
        subtitle: 'Manage vehicle terminals',
        component: <Terminals />,
      },
      // {title: 'Plans and Billing', subtitle: 'Manage your subscription'},
      // {title: 'API & WebHooks', subtitle: 'Integrate with other services'},
    ]
  };

  getTabContent = (tabIndex) => {
    const tabs = this.getTabs();
    return tabs[tabIndex].component;
  }

  render() {
    const {
      isOpen, toggle, error, success
    } = this.props;
    const { activeTab } = this.state;

    return <Modal isOpen={isOpen} toggle={toggle} className={this.props.className} zIndex="1060">
      <ModalBody>
        <div className="card card-transparent flex-row">
          <Nav tabs className={'nav nav-tabs nav-tabs-simple nav-tabs-left bg-white settings'}>
            {this.getTabs().map((item, index) =>
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: activeTab === index })}
                  onClick={() => { this.toggle(index); }}
                >
                  <p>{item.title}</p>
                  <p className={'subtitle'}>{item.subtitle}</p>
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              {this.getTabContent(activeTab)}
            </TabPane>
          </TabContent>
          <span style={{ color: 'red' }}>{!success && error && error.message}</span>
          <span style={{ color: 'black' }}>{success && success.message}</span>
        </div>
      </ModalBody>
    </Modal>;
  }
}

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
)(Settings);
export default FirestoreConnected;
