import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { firebase as config } from 'config';
const { emulatorUrl, ...firebaseConfig } = config;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  emulatorUrl && firebase.functions().useFunctionsEmulator(emulatorUrl);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const settings = {};
firestore.settings(settings);

export {
  auth,
  firestore,
};

export default firebase;
