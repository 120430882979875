import React, { PureComponent } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import mapStyles from 'constants/mapStyles';

class ControlPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    }
  }

  toggleUserMenu() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const {dropdownOpen} = this.state;

    return <div className="control-panel">
      <Dropdown isOpen={dropdownOpen} toggle={() => this.toggleUserMenu()}>
        <DropdownToggle caret>
          Map Options
        </DropdownToggle>
        <DropdownMenu>
          {mapStyles.map(item => <DropdownItem key={item.name} onClick={evt => this.props.onChange(item.style)}>
            {item.name}
          </DropdownItem>)}
        </DropdownMenu>
      </Dropdown>
    </div>
  }
}

export default ControlPanel;
