import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TaskModal, TaskReportModal } from ".";
import * as taskStatus from "constants/taskStatus";
import _ from 'lodash';
import {formatDateTime, formatTime} from "../../utils";
import * as colors from 'constants/colors';
import { getContrastColor } from 'utils';
import moment from 'moment';

class TaskItem extends Component {
  static propTypes = {
    number: PropTypes.number,
    color: PropTypes.string,
    bgColor: PropTypes.string,
    task: PropTypes.object,
    select: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      taskModal: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  getStatus({ status, details, estimatedDelivery, estimatedTimeArrival = 0}) {
    const events = (details && details.events) || [];
    switch (status) {
      case taskStatus.COMPLETED:
        if (events.length > 0) {
          const time = _.head(events.filter(item => item.name === 'completion'));
          return time
            ? <time title={formatDateTime(time.time)}>{status} - {time && formatTime(time.time)}</time>
            : <span>{status}</span>
        }
        break;
      case taskStatus.ARRIVAL:
        if (events.length > 0) {
          const time = _.head(events.filter(item => item.name === 'arrival'));
          return time
            ? <time className="blink" title={formatDateTime(time.time)}>ARRIVED - {time && formatTime(time.time)}</time>
            : <span className="blink">{status}</span>
        }
      break;
      case taskStatus.PROGRESS:
        return <span>IN PROGRESS - ETA - {moment.duration(estimatedTimeArrival, 'seconds').humanize()}</span>;
      case taskStatus.CANCELED:
        return <span>CANCELED</span>;
      default:
    }

    return <span>ETA -&nbsp;
      <time title={formatDateTime(estimatedDelivery)}>{formatTime(estimatedDelivery)}</time>
    </span>;
  }

  toggleModal() {
    this.setState(prevState => ({
      taskModal: !prevState.taskModal
    }));
  }

  getAddress(destination) {
    if (!destination || !destination.address) {
      return null;
    }

    const { task: { status } } = this.props;
    return <div style={{ textDecoration: status === taskStatus.CANCELED ? 'line-through' : 'none' }} className="address">
      <small>
        {[destination.address.line1,
        destination.address.line2,
        destination.address.city,
        destination.address.state]
          .map(str => (str || '').trim())
          .filter(str => str !== '')
          .join(', ')} {destination.address.postalCode}</small>
    </div>
  }

  render() {
    const { number, color, bgColor, task, user, recipient, select } = this.props;
    const { status, destination } = task;
    const { taskModal } = this.state;
    
    const statusText = this.getStatus(task);

    const customStyle = {};

    if (number) {
      if (color) {
        customStyle.color = getContrastColor(bgColor);
      }
      if (bgColor) {
        customStyle.backgroundColor = bgColor;
      }
    }

    const statusTextStyle = {};
    switch (status) {
      case taskStatus.COMPLETED:
      case taskStatus.ARRIVAL:
        statusTextStyle.color = colors.red;
        break;
      case taskStatus.PROGRESS:
        statusTextStyle.color = colors.darkGreen;
        break;
      case taskStatus.CANCELED:
        statusTextStyle.color = colors.orange;
        break;
      default:
        statusTextStyle.color = colors.yellow;
        break;
    }

    const isFinished =  [taskStatus.ARRIVAL, taskStatus.COMPLETED].includes(status);

    // TODO: Remove this after a href="#" is not needed
    /* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */
    return <li className="task">
      {number ?
        <span className="icon-thumbnail mini"><span className="bb-number" style={customStyle}>{number}</span></span> :
        <span className="icon-thumbnail mini"><i className="pg-map"></i></span>}
      <a className="cursor detailed"
         onClick={() => status === select()}>
        <div className="title">{recipient && recipient.name}</div>
        {this.getAddress(destination)}
        { status !== 'UNASSIGNED' &&
          <div className="status">
            <small style={statusTextStyle}>{statusText}</small>
          </div>
        }
      </a>
      {isFinished ? 
        <TaskModal
          address={this.getAddress(destination)}
          driver={user}
          recipient={recipient}
          task={task}
          isOpen={taskModal}
          toggle={this.toggleModal}
          className={this.props.className || null}
          />
        : <TaskReportModal
          address={this.getAddress(destination)}
          driver={user}
          recipient={recipient}
          task={task}
          isOpen={taskModal}
          toggle={this.toggleModal}
          className={this.props.className || null}
          />
      }
    </li>;
  }
}

export default TaskItem;
