import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { TaskForm } from 'components/Task';
import { actions as taskActions } from 'reducers/task';
import { actions as destinationActions } from 'reducers/destination';
import * as destinationKeys from 'constants/destinations';
import { homePath } from 'constants/routes';

const TASK_ORIGIN = destinationKeys.TASK_ORIGIN;
const TASK_DESTINATION = destinationKeys.TASK_DESTINATION;

class NewOrder extends Component {
  state = {
    draftSaved: false,
  }

  componentWillMount() {
    const { draftData } = this.props;
    if (draftData) {
      this.setFormData(draftData);
    }
  }

  componentWillUnmount() {
    const { task, setDraft, clearTask, destination } = this.props;
    let taskData = { ...task };

    if (!taskData.id && !this.state.draftSaved) {
      const taskOrigin = destination[TASK_ORIGIN];
      const taskDestination = destination[TASK_DESTINATION];
      const recipientOrigin = taskData.recipients[TASK_ORIGIN] || {};
      const recipientDestination = taskData.recipients[TASK_DESTINATION] || {};

      taskData.sender = {
        ...taskData.sender,
        ...recipientOrigin,
      };

      taskData.recipient = {
        ...taskData.recipient,
        ...recipientDestination,
      };

      taskData.origin = {
        ...taskOrigin,
        owner: taskData.sender && taskData.sender.id,
      };

      taskData.destination = {
        ...taskDestination,
        owner: taskData.recipient && taskData.recipient.id,
      };

      taskData.originId = taskOrigin && taskOrigin.id;
      taskData.destinationId = taskDestination && taskDestination.id;
      setDraft(taskData);
    }
    clearTask();
  }

  setFormData(task) {
    const {
      vehicles, onEditTask,
      onEstimatedPickupDate, onEstimatedPickupTime,
      onEstimatedDeliveryDate, onEstimatedDeliveryTime,
      onVehicleChanged,
    } = this.props;

    if (task) {
      onEditTask(task);

      if (task.vehicle && task.vehicle.id) {
        const vehicle = vehicles.find(vehicle => vehicle.id === task.vehicle.id);
        onVehicleChanged(vehicle);
      } else {
        onVehicleChanged(null);
      }

      onEstimatedPickupDate(task.estimatedPickup.date);
      onEstimatedPickupTime(task.estimatedPickup.time);
      onEstimatedDeliveryDate(task.estimatedDelivery.date);
      onEstimatedDeliveryTime(task.estimatedDelivery.time);

      const origin = `${task.origin ? task.origin.owner : task.originId}, ${task.originId}`;
      const destination = `${task.destination ? task.destination.owner : task.destinationId}, ${task.destinationId}`;

      this.handleBusinessValue(TASK_ORIGIN, origin, task.sender, task.origin);
      this.handleBusinessValue(TASK_DESTINATION, destination, task.recipient, task.destination);
    }
  }

  onSave = () => {
    this.setState({ draftSaved: true });
    this.props.setDraft(false);
    this.props.history.push(homePath);
  }

  handleBusinessValue(on, selectedDestination, recipient, destination) {
    const {
      recipients,
      destinations,
      onRecipientChanged,
      onDestinationChanged,
      onSenderChanged,
      onOriginChanged,
      onDestinationEdit,
    } = this.props;

    const [recipientId, destinationId] = selectedDestination.split(',').map(id => id.trim());
    recipient = recipients.find(r => r.id === recipientId) || recipient;
    destination = destinations.find(d => d.id === destinationId) || destination;

    switch (on) {
      case TASK_ORIGIN:
        onSenderChanged(recipient);
        onOriginChanged(destination);
        break;
      default:
        onRecipientChanged(recipient);
        onDestinationChanged(destination);
    }

    onDestinationEdit(on, destination);
  }

  render() {
    const {
      recipients,
      vehicles,
      currentUser,
      drivers,
      destinations,
      terminals,
      brands
    } = this.props;

    return (
      <Container>
        <h1>New Order</h1>
        <TaskForm
          currentUser={currentUser}
          vehicles={vehicles}
          drivers={drivers}
          recipients={recipients}
          destinations={destinations}
          terminals={terminals}
          toggle={this.onSave}
          brands={brands}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ task, destination }) => {
  return {
    task,
    destination,
    draftData: task.draft,
  }
};

const mapDispatchToProps = {
  ...taskActions,
  ...destinationActions,
};

const Connected = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NewOrder);

export default Connected;
