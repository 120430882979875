import React, { Component } from 'react';
import DeckGL from '@deck.gl/react';
import { LineLayer } from '@deck.gl/layers';
import { mapbox } from 'config';
import { StaticMap } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import bbox from "@turf/bbox";
import { lineString } from '@turf/helpers';
import {firestoreConnect, isLoaded} from "react-redux-firebase";
import { connect } from "react-redux";
import * as collections from 'constants/collections';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import { getDestinationMarker, getOriginMarker } from ".";

class DriverRouteMap extends Component {

  static propTypes = {
    task: PropTypes.string,
  };

  state = {
    mapStyle: 'mapbox://styles/mapbox/streets-v10',
    viewPort: {
      width: 500, //approx number width, lower number or empty causes the calculated zoom to fail
      height: 300,//approx number width, lower number or empty causes the calculated zoom to fail
      longitude: -122.41669,
      latitude: 37.7853,
      zoom: 11,
      pitch: 0,
      bearing: 0
    },
  }

  getBounds = (markers) => {
    let points = markers.map(item => ([item.location.lon, item.location.lat]));
    return bbox(lineString(points));
  };

  prepareLineData(data) {
    const lineData = []
    data.forEach((item, index) => {
      if (index > 0) {
        lineData.push({
          sourcePosition: [data[index-1].location.lon, data[index-1].location.lat],
          targetPosition: [item.location.lon, item.location.lat]
        })
      }
    })
    return lineData;
  }

  getViewPort() {
    if (!isLoaded(this.props.track) || this.props.track === null || this.props.track === undefined) {
      return this.state.viewPort;
    }
    const [minLng, minLat, maxLng, maxLat] = this.getBounds(this.props.track.path);
    let viewPort = new WebMercatorViewport(this.state.viewPort);
    const {longitude, latitude, zoom} = viewPort.fitBounds(
      [[minLng, minLat], [maxLng, maxLat]],
      {padding: 40}
    );
    return {
      ...this.state.viewPort,
      longitude,
      latitude,
      zoom
    };
  }

  render() {
    if (!isLoaded(this.props.track) || this.props.track === null || this.props.track === undefined) {
      return (<Loading />);
    }

    const layers = [
      new LineLayer({id: 'line-layer', data: this.prepareLineData(this.props.track.path)})
    ];

    const originPoint = this.props.track.path[0].location;
    const destinationPoint = this.props.track.path[this.props.track.path.length - 1].location;

    return <DeckGL viewState={this.getViewPort()} layers={layers}>
      <StaticMap mapboxApiAccessToken={mapbox.accessToken}>
        {getOriginMarker(originPoint)}
        {getDestinationMarker(destinationPoint)}
      </StaticMap>
    </DeckGL>
  }
}

const FirestoreConnected = compose(
  firestoreConnect((props) => ([
    {
      collection: collections.TRACK,
      doc: props.task,
    },
  ])),
  connect(state => ({
      track: state.firestore.ordered.tracks ? state.firestore.ordered.tracks[0] : null
    })
  )
)(DriverRouteMap);
export default FirestoreConnected;