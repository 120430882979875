import React, { PureComponent } from "react";

class MaxSpeedInfo extends PureComponent {
  render() {
    return (
      <div onClick={this.props.onClick} className="map-pin-popup max-speed">
        <div className="d-flex align-items-baseline popup-row">
        <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style={{color:'#FFFF00'}}></i>
        </div>
      </div>
    );
  }
}

export default MaxSpeedInfo;
