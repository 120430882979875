import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import {
  find,
  values,
  reduce,
  groupBy,
  orderBy,
} from 'lodash';
import moment from 'moment';
import {
  Dropdown, DropdownMenu, DropdownItem, DropdownToggle,
  CardGroup, Button, ButtonGroup, Input,
} from 'reactstrap';

import { actions as appActions } from 'reducers/appState';
import { actions as taskActions } from 'reducers/task';
import { actions as destinationActions } from 'reducers/destination';

import { collator, parsedOrders } from 'utils';
import * as roles from 'constants/roles';
import * as collections from 'constants/collections';
import * as colors from 'constants/colors';
import * as taskStatus from 'constants/taskStatus';
import {
  homePath, reportsPath, ordersPath, newOrderPath,
  orderPathForEdit, orderPathForView,
} from 'constants/routes';

import Layout from 'components/Layout';
import TaskList, { TaskItem } from 'components/Task';
// TODO: #chat uncomment this when chat comes back
// import { ContactList } from 'components/Chat';
import Avatar from 'components/Avatar';
import Profile from 'components/Profile';
import Notification from 'components/Notification';
import Settings from 'components/Settings';
import Loading from 'components/Loading';
import CustomDateRangePicker from 'components/CustomDateRangePicker';
import icon from 'images/pages/icons/boltbox-brand-logo.png';

class AppLayout extends Component {
  state = {
    isNotificationMenuOpen: false,
    isUserMenuOpen: false,
    profileModal: false,
    recipients: []
  };

  constructor(props) {
    super(props);

    this.toggleProfile = this.toggleProfile.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
  }

  componentDidMount() {
    document.body.classList.add('dashboard');
    document.body.classList.add('no-header');

    // TODO: #chat remove this line after chat is fixed
    document.body.classList.add('no-sidebar');

    // Initialize dates in date range component
    this.setDateRange({ startDate: moment(), endDate: moment() });
  }

  getGroupedTasks = () => {
    const {
      tasks, recipients, vehicles, disabledVehicles,
      appState: { taskFilter, startDateFilter, endDateFilter },
    } = this.props;

    const sanitizeTaskFilter = taskFilter.toLowerCase();

    const filteredTasks = tasks.filter(task => {
      const recipient = find(recipients, { id: task.recipientId });
      const name = task.destination.name ? task.destination.name.toLowerCase() : '';
      const formatted = task.destination.address.formatted ? task.destination.address.formatted.toLowerCase() : '';
      const recipientName = recipient ? recipient.name.toLowerCase() : '';
      const taskInDateRange = moment.unix(task.estimatedDelivery).isBetween(startDateFilter, endDateFilter, 'days', '[]');
      return taskInDateRange && (formatted.includes(sanitizeTaskFilter) || name.includes(sanitizeTaskFilter) || recipientName.includes(sanitizeTaskFilter));
    });

    return values(reduce(groupBy(filteredTasks, 'vehicleId'), function (stack, tasks, id) {
      const vehicle = id && (find(vehicles, { id }) || find(disabledVehicles, { id }));

      let ascOrderedTasks;
      if (vehicle) {
        stack[id] = { vehicle, tasks };
        ascOrderedTasks = [...tasks];
      } else {
        stack[""] = { vehicle: { description: '' }, tasks: [...((stack[""] || {}).tasks || []), ...tasks] };
        ascOrderedTasks = [...stack[""].tasks];
      }

      orderBy(ascOrderedTasks, ['sort', 'estimatedDelivery'], ['asc']).forEach((task, idx) => task.number = idx + 1)

      return stack;
    }, {})).sort((a, b) => collator.compare(a.vehicle.description, b.vehicle.description));
  }

  componentWillUnmount() {
    document.body.classList.remove('dashboard');
  }

  toggleNotificationMenu() {
    this.setState(prevState => ({
      isNotificationMenuOpen: !prevState.isNotificationMenuOpen
    }));
  }

  toggleUserMenu() {
    this.setState(prevState => ({
      isUserMenuOpen: !prevState.isUserMenuOpen
    }));
  }

  logout() {
    const { firebase } = this.props;
    firebase.logout();
  }

  hasPendingNotifications() {
    const { pendingNotifications, firebase } = this.props;
    if (isLoaded(pendingNotifications)) {
      return pendingNotifications.filter(item => !item.read.includes(firebase.auth().currentUser.uid)).length;
    }
    return 0;
  }

  toggleProfile() {
    this.setState(prevState => ({
      profileModal: !prevState.profileModal
    }));
  }

  toggleSettings() {
    this.setState(prevState => ({
      settingsModal: !prevState.settingsModal
    }));
  }

  showSearchOverlay() {
    this.props.setSearchOverlayVisibility(true);
  }

  setTaskNumberStatus(tasks) {
    const filterTask = tasks.filter(task => task.status === taskStatus.COMPLETED || task.status === taskStatus.PROGRESS);
    return filterTask.length > 0 ? (filterTask.length + " of " + (tasks).length) : (tasks).length;
  }

  getRecipientForTask(recipientId) {
    return find(this.props.recipients, { id: recipientId })
  }

  setDateRange(dateRange) {
    const { startDateFilterChanged, endDateFilterChanged } = this.props;
    const { startDate, endDate } = dateRange;
    startDateFilterChanged(startDate);
    endDateFilterChanged(endDate);
  }

  navigateTo = (path) => {
    this.props.history.push(path);
  }

  onCalendarMonthChange = (selectedDate) => {
    const today = moment();
    const initialDate = selectedDate > today ? today : selectedDate;

    const year = initialDate.format('YYYY');
    const month = initialDate.format('M') - 1;
    const startMonth = moment().set({ year, month }).startOf('month');
    this.props.onCalendarMonthChange(startMonth);
  }

  getTaskList(key, groupedTasks) {
    const { vehicle, tasks } = groupedTasks;
    const { onDutyDrivers = [], disabledRecipients } = this.props;

    return <TaskList
      id="collapseThree"
      key={key}
      title={(vehicle && vehicle.id && (`${vehicle.description || ''} ${vehicle.model || ''}`.trim() || 'No name')) || 'No vehicle'}
      enumerate dark
      style={{ marginTop: '32px' }}
      color={(vehicle && vehicle.color) || colors.blue}
      status={`${this.setTaskNumberStatus(tasks)} tasks.`} >
      {tasks.map((task) => {
        const { estimatedTimeArrival } = onDutyDrivers.find(item => item.activeTask === task.id) || {};
        const recipient = this.getRecipientForTask(task.recipientId);

        const taskPath = task.status === taskStatus.CREATED
          ? orderPathForEdit(task.id)
          : orderPathForView(task.id);

        const getRecipient = () => {
          return recipient || disabledRecipients.find(r => r.id === task.recipientId);
        }

        return <TaskItem
          key={task.id}
          number={task.number}
          task={{ ...task, estimatedTimeArrival }}
          user={task.driver || null}
          select={() => this.navigateTo(taskPath)}
          recipient={getRecipient()} />
      })}
    </TaskList>;
  }

  // TODO: Remove this after a href="#" is not needed
  /* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */
  render() {
    if (!isLoaded(this.props.tasks) || !isLoaded(this.props.users) || !isLoaded(this.props.drivers) || !isLoaded(this.props.vehicles) || !isLoaded(this.props.terminals) || !isLoaded(this.props.brands)) {
      return (<Loading />);
    }

    const { isNotificationMenuOpen, isUserMenuOpen, profileModal, settingsModal } = this.state;
    const {
      auth: { uid }, /*users,*/ tasks, terminals, recipients, vehicles, currentUser, isLoading,
      onDutyDrivers, drivers, destinations, task, brands,
      searchFilterChanged, match: { path: currentPath },
      component: Component,
    } = this.props;
    // TODO: #chat Improve this, chat is taking long for page load
    // const contacts = users.filter(u => u.id !== uid);

    // const activeTasks = tasks.filter(task => {
    // 	const { status } = task;
    // 	const completionEvent = task.status === taskStatus.COMPLETED &&
    // 		task.details.events.find(event => event.name === 'completion');

    // 	let completedToday = false;
    // 	if (completionEvent) {
    // 		const completionTime = moment(completionEvent.time);
    // 		completedToday = completionTime.isSame(moment(), 'date')
    // 	}

    // 	return status === taskStatus.PROGRESS || completedToday;
    // });

    // let activeClients = [];

    // if (recipients) {
    // 	activeClients = recipients.reduce((filtered, recipient) => {
    // 		const recipientTasks = activeTasks.filter(task => task.recipientId === recipient.id);
    // 		if (recipientTasks.length) {
    // 			filtered.push({
    // 				id: recipient.id,
    // 				displayName: recipient.name,
    // 				role: roles.RECIPIENT,
    // 			});
    // 		}
    // 		return filtered;
    // 	}, []);
    // }

    const groupedTasks = this.getGroupedTasks();

    return <Layout isProgressBarVisible={isLoading}>
      <nav className="page-sidebar" data-pages="sidebar">
        <div className="sidebar-overlay-slide from-top" id="appMenu">
          <div className="row">
            <div className="col-xs-6 no-padding">
              <a href="#" className="p-l-40"><img src="assets/img/demo/social_app.svg" alt="social" /></a>
            </div>
            <div className="col-xs-6 no-padding">
              <a href="#" className="p-l-10"><img src="assets/img/demo/email_app.svg" alt="social" /></a>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 m-t-20 no-padding">
              <a href="#" className="p-l-40"><img src="assets/img/demo/calendar_app.svg" alt="social" /></a>
            </div>
            <div className="col-xs-6 m-t-20 no-padding">
              <a href="#" className="p-l-10"><img src="assets/img/demo/add_more.svg" alt="social" /></a>
            </div>
          </div>
        </div>
        <div className="sidebar-header">
          <div className="sidebar-header-controls">
            <button type="button" className="btn btn-link d-lg-inline-block d-xlg-inline-block d-md-inline-block d-sm-none d-none" data-toggle-pin="sidebar"><i className="fa fs-12"></i>
            </button>
          </div>
          <button type="button" className="btn btn-link btn-expand" data-toggle-open="sidebar"><i className=" pg-menu"></i></button>
        </div>
        <div className="sidebar-menu">
          {/* TODO: #chat uncomment this */}
          {/* <div className="scroll-wrapper menu-items" style={{ position: 'relative' }}>
						{<ContactList contacts={contacts} currentUser={currentUser} />}
					</div>
					{activeClients.length &&
						<div className="scroll-wrapper menu-items" style={{ position: 'relative' }}>
							<div className="divider"></div>
							{<ContactList contacts={activeClients} currentUser={currentUser} />}
						</div>
					} */}
          <div className="clearfix"></div>
        </div>
      </nav>
      <div className="page-container ">
        <div className="header ">
          <a href="#" className="btn-link toggle-sidebar d-lg-none pg pg-menu" data-toggle="sidebar"></a>
          {/*<Search onClick={() => this.showSearchOverlay()} /> */}
          <button className="btn btn-link logo-link d-lg-inline-block d-none" onClick={() => this.navigateTo(homePath)} >
            <img src={icon} data-src={icon} data-src-retina={icon} alt={icon} height="40" className="lazy" />
          </button>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center b-grey b-r p-r-10">
              <ButtonGroup className="layout-switch" size="sm">
                <Button onClick={() => this.navigateTo(homePath)} active={currentPath === homePath}>
                  <i className="fa fa-map"></i>
                </Button>
                <Button onClick={() => this.navigateTo(ordersPath)} active={currentPath === ordersPath}>
                  <i className="fa fa-th"></i>
                </Button>
                <Button onClick={() => this.navigateTo(reportsPath)} active={currentPath === reportsPath}>
                  <i className="fa fa-line-chart"></i>
                </Button>
              </ButtonGroup>
            </div>
            <ul className="d-lg-inline-block d-none notification-list no-margin d-lg-inline-block b-grey b-r no-style p-l-10 p-r-10">
              <li className="p-l-10 p-r-10 inline">
                <Dropdown isOpen={isNotificationMenuOpen} toggle={() => this.toggleNotificationMenu()}>
                  <DropdownToggle tag="a" className="cursor header-icon fa fa-bell">
                    {this.hasPendingNotifications() > 0 &&
                      <span className="bubble"></span>
                    }
                  </DropdownToggle>
                  <DropdownMenu>
                    <Notification />
                  </DropdownMenu>
                </Dropdown>
              </li>
            </ul>
            <div className="dropdown pull-right d-lg-block d-none p-l-10">
              <Dropdown isOpen={isUserMenuOpen} toggle={() => this.toggleUserMenu()}>
                <DropdownToggle className="profile-dropdown-toggle">
                  <span className="name">
                    <span className="semi-bold">{currentUser.firstName}</span> <span className="text-master">{currentUser.lastName}</span>
                  </span>
                  &nbsp;
                  <i className="fa fa-angle-down fs-16"></i>
                  &nbsp;
                  <span className="thumbnail-wrapper d32 circular inline">
                    <Avatar user={currentUser} backgroundColor={'gray'} />
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.toggleProfile}><i className="fa fa-user"></i> Profile</DropdownItem>
                  <DropdownItem onClick={this.toggleSettings}><i className="pg-settings_small"></i> Settings</DropdownItem>
                  <DropdownItem className="clearfix bg-master-lighter" onClick={() => this.logout()}>
                    <span className="pull-left">Logout</span>
                    <span className="pull-right"><i className="pg-power"></i></span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <Profile userId={uid} isOpen={profileModal} toggle={this.toggleProfile} className={this.props.className} />
          </div>
        </div>
        <div className="page-content-wrapper full-height">
          <div className="content full-width full-height overlay-foote map-wrapper">
            <Component
              task={task}
              tasks={tasks}
              drivers={drivers}
              vehicles={vehicles}
              terminals={terminals}
              recipients={recipients}
              destinations={destinations}
              currentUser={currentUser}
              onDutyDrivers={onDutyDrivers}
              groupedTasks={groupedTasks}
              brands={brands}
            />
          </div>
        </div>
      </div>
      <div className="secondary-sidebar">
        <div className="m-b-10 m-l-20 m-r-20 d-sm-none d-md-block d-lg-block d-xl-block">
          <div className="input-group white">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="pg-unordered_list"></i>
              </span>
            </div>
            <Input type="text" placeholder="Search" className="form-control" name="taskFilter"
              onChange={event => searchFilterChanged(event.target.value)} />
          </div>
        </div>
        <div className="m-b-10 m-l-20 m-r-20 d-flex justify-content-between">
          <div>
            <CustomDateRangePicker
              startDateId="start_date"
              endDateId="end_date"
              handleDateChange={({ startDate, endDate }) =>
                this.setDateRange({ startDate, endDate })
              }
              onPrevMonthChange={this.onCalendarMonthChange}
              onNextMonthChange={this.onCalendarMonthChange}
              highlightedDates={tasks.map(t => moment.unix(t.estimatedDelivery))}
              autoFocusEndDate={false}
              showInputs={true}
            />
          </div>
          <div>
            <Link className="btn btn-secondary p-l-10 p-r-10" to={newOrderPath}>New Order</Link>
          </div>
        </div>
        <div className="scroll-wrapper">
          <CardGroup className="horizontal" id="accordion" role="tablist" aria-multiselectable="true">
            {groupedTasks.map((groupedTasks, key) => this.getTaskList(key, groupedTasks))}
          </CardGroup>
        </div>
      </div>
      <Settings isOpen={settingsModal} toggle={this.toggleSettings} currentUser={currentUser} className={'modal-lg'} />
    </Layout>;
  }
}

const onDutyDriversStorage = 'onDutyDrivers';
const enabledVehiclesStorage = 'enableVehicles';
const disabledVehiclesStorage = 'disabledVehicles';
const disabledRecipients = 'disabledRecipients';

const mapStateToProps = (state, { appState }) => {

  const { firebase: { profile, auth }, firestore: { ordered, data } } = state;
  const { tasks: orderedTasks = [], users = [], notifications = [] } = ordered;

  const onDuttyDrivers = ordered[onDutyDriversStorage] || [];

  const parsedTasks = parsedOrders({
    orders: orderedTasks,
    users: data.users,
    destinations: data.destinations,
    recipients: ordered.recipients,
    vehicles: ordered[enabledVehiclesStorage],
  });

  return {
    appState,
    users,
    task: state.task,
    tasks: parsedTasks,
    vehicles: ordered[enabledVehiclesStorage],
    disabledVehicles: ordered[disabledVehiclesStorage],
    disabledRecipients: ordered[disabledRecipients],
    recipients: ordered.recipients || [],
    terminals: ordered.terminals || [],
    destinations: ordered.destinations,
    drivers: users.filter(item => item.role === roles.DRIVER),
    pendingNotifications: notifications.filter(n => n.recipients && n.recipients.includes(auth.uid)),
    onDutyDrivers: onDuttyDrivers.map(monitoring => ({
      ...monitoring,
      task: orderedTasks.find(t => t.id === monitoring.activeTask) || {},
      driver: (data.users[monitoring.id] || null),
    })),
    currentUser: { ...profile, id: auth.uid },
    brands: ordered.brands,
  }
};

const mapDispatchToProps = ({
  ...appActions,
  ...taskActions,
  ...destinationActions
});

const FirestoreConnected = compose(
  withRouter,
  firestoreConnect(({ appState: { startMonth } }) => {
    const fromDate = startMonth || moment().startOf('month');
    return [
      collections.USER,
      collections.DESTINATION,
      {
        collection: collections.RECIPIENT,
        where: ['disabled', '==', false],
        orderBy: ['name', 'asc']
      },
      {
        collection: collections.RECIPIENT,
        where: ['disabled', '==', true],
        orderBy: ['name', 'asc'],
        storeAs: disabledRecipients
      },
      {
        collection: collections.TASK,
        where: ['estimatedDelivery', '>', fromDate.unix()],
        orderBy: ['estimatedDelivery', 'asc']
      },
      {
        collection: collections.VEHICLE,
        where: ['disabled', '==', false],
        storeAs: enabledVehiclesStorage
      },
      {
        collection: collections.VEHICLE,
        where: ['disabled', '==', true],
        storeAs: disabledVehiclesStorage
      },
      {
        collection: collections.MONITORING,
        where: ['onDuty', '==', true],
        storeAs: onDutyDriversStorage,
      },
      {
        collection: collections.TERMINAL,
        where: ['disabled', '==', false],
        orderBy: ['name', 'asc'],
      },
      {
        collection: collections.BRAND,
        where: ['disabled', '==', false],
        orderBy: ['name', 'asc'],
      },
    ];

  }),
  connect(mapStateToProps, mapDispatchToProps),
)(AppLayout);

export default FirestoreConnected;
