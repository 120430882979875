import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { actions as recaptchaActions } from 'reducers/recaptcha';

const ReCaptcha = ({ action, recaptchaKey, onRecaptchaLoaded }) => {
  useEffect(() => {
    const onLoad = () => {
      window.grecaptcha.ready(() => {
        onRecaptchaLoaded(window.grecaptcha);
      });
    };

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
    script.addEventListener('load', onLoad);
    document.body.appendChild(script);
  }, [action, recaptchaKey, onRecaptchaLoaded]);

  return (
    <div
      className='g-recaptcha'
      data-sitekey={recaptchaKey}
      data-size='invisible'
    ></div>
  );
};

ReCaptcha.propTypes = {
  action: PropTypes.string,
  callback: PropTypes.func,
  recaptchaKey: PropTypes.string,
};

const mapStateToProps = ({ recaptcha }) => ({
  ...recaptcha,
});
const mapDispatchToProps = {
  ...recaptchaActions,
};

const Connected = compose(connect(mapStateToProps, mapDispatchToProps))(
  ReCaptcha,
);
export default Connected;
