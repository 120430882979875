// const string for actions in uppercase
const RECIPIENT_NEW = "RECIPIENT_NEW";
const RECIPIENT_EDIT = "RECIPIENT_EDIT";
const RECIPIENT_NO_CHANGED = 'RECIPIENT_NO_CHANGED';
const RECIPIENT_NAME_CHANGED = 'RECIPIENT_NAME_CHANGED';
const RECIPIENT_PHONE_CHANGED = 'RECIPIENT_PHONE_CHANGED';
const RECIPIENT_METRC_LICENSE_NUMBER_CHANGED = 'RECIPIENT_METRC_LICENSE_NUMBER_CHANGED';
const RECIPIENT_EMAIL_CHANGED = 'RECIPIENT_EMAIL_CHANGED';
const RECIPIENT_BILLING_ADDRESS_CHANGED = 'RECIPIENT_BILLING_ADDRESS_CHANGED';
const RECIPIENT_DESTINATIONS_CHANGED = 'RECIPIENT_DESTINATIONS_CHANGED';
const RECIPIENT_SMS_NOTIFICATIONS_CHANGED = 'RECIPIENT_SMS_NOTIFICATIONS_CHANGED';
const RECIPIENT_EMAIL_NOTIFICATIONS_CHANGED = 'RECIPIENT_EMAIL_NOTIFICATIONS_CHANGED';
const RECIPIENT_ARRIVING_NOTIFICATION_INTERVAL_CHANGED = 'RECIPIENT_ARRIVING_NOTIFICATION_INTERVAL_CHANGED';
const RECIPIENT_FORM_CLEAR = 'RECIPIENT_FORM_CLEAR';
const RECIPIENT_FORM_ERROR = 'RECIPIENT_FORM_ERROR';
const RECIPIENT_FORM_SUCCESS = 'RECIPIENT_FORM_SUCCESS';
const RECIPIENT_CHANGED = 'RECIPIENT_CHANGED';
const RECIPIENT_ENABLE_ACCOUNT_CHANGED = 'RECIPIENT_ENABLE_ACCOUNT_CHANGED';
const RECIPIENT_ENABLE_ACCOUNT_PASSWORD_CHANGED = 'RECIPIENT_ENABLE_ACCOUNT_PASSWORD_CHANGED';
const RECIPIENT_ENABLE_ACCOUNT_CONFIRM_PASSWORD_CHANGED = 'RECIPIENT_ENABLE_ACCOUNT_CONFIRM_PASSWORD_CHANGED';
const RECIPIENT_NOTIFICATION_TYPES = 'RECIPIENT_NOTIFICATION_TYPES';


// actions
export const actions = {
  onRecipientNew: () => ({ type: RECIPIENT_NEW }),
  onRecipientEdit: recipient => ({ type: RECIPIENT_EDIT, recipient }),
  onNoRecipientChanged: (noRecipient) => ({ type: RECIPIENT_NO_CHANGED, noRecipient }),
  onRecipientChanged: (recipient) => ({ type: RECIPIENT_CHANGED, recipient }),
  onNameChanged: (name) => ({ type: RECIPIENT_NAME_CHANGED, name }),
  onPhoneChanged: (phone) => ({ type: RECIPIENT_PHONE_CHANGED, phone }),
  onMetrcLicenseNumberChanged: (metrcLicenseNumber) => ({ type: RECIPIENT_METRC_LICENSE_NUMBER_CHANGED, metrcLicenseNumber }),
  onEmailChanged: (email) => ({ type: RECIPIENT_EMAIL_CHANGED, email }),
  onNotificationTypeChanged: (notification, checked) => ({ type: RECIPIENT_NOTIFICATION_TYPES, notification, checked }),
  onEnableAccountChanged: (enableAccount) => ({ type: RECIPIENT_ENABLE_ACCOUNT_CHANGED, enableAccount }),
  onEnableAccountPasswordChanged: (enableAccountPassword) => ({ type: RECIPIENT_ENABLE_ACCOUNT_PASSWORD_CHANGED, enableAccountPassword }),
  onEnableAccountConfirmPasswordChanged: (enableAccountConfirmPassword) => ({ type: RECIPIENT_ENABLE_ACCOUNT_CONFIRM_PASSWORD_CHANGED, enableAccountConfirmPassword }),
  onBillingAddressChanged: (billingAddressId) => ({ type: RECIPIENT_BILLING_ADDRESS_CHANGED, billingAddressId }),
  onSmsNotificationChanged: (smsNotification) => ({ type: RECIPIENT_SMS_NOTIFICATIONS_CHANGED, smsNotification }),
  onEmailNotificationChanged: (emailNotification) => ({ type: RECIPIENT_EMAIL_NOTIFICATIONS_CHANGED, emailNotification }),
  onArrivingNotificationIntervalChanged: (arrivingNotificationInterval) => ({ type: RECIPIENT_ARRIVING_NOTIFICATION_INTERVAL_CHANGED, arrivingNotificationInterval: parseInt(arrivingNotificationInterval) }),
  onDestinationsChanged: (destinations) => ({ type: RECIPIENT_DESTINATIONS_CHANGED, destinations }),
  onError: (error) => ({ type: RECIPIENT_FORM_ERROR, error }),
  onSuccess: (success) => ({ type: RECIPIENT_FORM_SUCCESS, success }),  
  clear: () => ({ type: RECIPIENT_FORM_CLEAR }),
};

// initial state
const initialState = {
  id: null,
  selected: null,
  name: '',
  phone: '',
  email: '',
  billingAddressId: '',
  smsNotification: false,
  emailNotification: false,
  arrivingNotificationInterval: '',
  destinations: [],
  metrcLicenseNumber: '',
  error: null,
  success: null,
  enableAccount: false,
  enableAccountPassword: '',
  enableAccountConfirmPassword: '',
  notificationTypes: {},
};

// action handlers
const actionHandlers = {

  [RECIPIENT_NEW]: state => ({ ...state, ...initialState }),

  [RECIPIENT_EDIT]: (state, { recipient }) => ({
    ...state,
    ...recipient
  }),
  
  [RECIPIENT_NO_CHANGED]: (state, { noRecipient }) => {
    const newState = { ...state, noRecipient };
    if (noRecipient) {
      newState.selected = null;
      newState.phone = '';
      newState.smsNotification = false;
      newState.emailNotification = false;
      newState.destinations = [];
    }
    return newState;
  },
  [RECIPIENT_CHANGED]: (state, { recipient }) => ({ ...state, selected: recipient }),
  [RECIPIENT_NAME_CHANGED]: (state, { name }) => ({ ...state, name }),
  [RECIPIENT_PHONE_CHANGED]: (state, { phone }) => ({ ...state, phone }),
  [RECIPIENT_METRC_LICENSE_NUMBER_CHANGED]: (state, { metrcLicenseNumber }) => ({ ...state, metrcLicenseNumber }),
  [RECIPIENT_EMAIL_CHANGED]: (state, { email }) => ({ ...state, email }),
  [RECIPIENT_ENABLE_ACCOUNT_CHANGED]: (state, { enableAccount }) => ({ ...state, enableAccount }),
  [RECIPIENT_ENABLE_ACCOUNT_PASSWORD_CHANGED]: (state, { enableAccountPassword }) => ({ ...state, enableAccountPassword }),
  [RECIPIENT_ENABLE_ACCOUNT_CONFIRM_PASSWORD_CHANGED]: (state, { enableAccountConfirmPassword }) => ({ ...state, enableAccountConfirmPassword }),
  [RECIPIENT_BILLING_ADDRESS_CHANGED]: (state, { billingAddressId }) => ({ ...state, billingAddressId }),
  [RECIPIENT_NOTIFICATION_TYPES]: (state, { notification, checked }) => ({ ...state, notificationTypes: { ...state.notificationTypes, [notification]: checked } }),
  [RECIPIENT_SMS_NOTIFICATIONS_CHANGED]: (state, { smsNotification }) => ({ ...state, smsNotification }),
  [RECIPIENT_EMAIL_NOTIFICATIONS_CHANGED]: (state, { emailNotification }) => ({ ...state, emailNotification }),
  [RECIPIENT_ARRIVING_NOTIFICATION_INTERVAL_CHANGED]: (state, { arrivingNotificationInterval }) => ({ ...state, arrivingNotificationInterval }),
  [RECIPIENT_DESTINATIONS_CHANGED]: (state, { destinations }) => ({ ...state, destinations }),
  [RECIPIENT_DESTINATIONS_CHANGED]: (state, { destinations }) => ({ ...state, destinations }),
  [RECIPIENT_FORM_CLEAR]: (state) => ({ ...state, name: '', phone: '', email: '', smsNotification: false, emailNotification: false, destinations: [],
    error: null, success: null }),
  [RECIPIENT_FORM_ERROR]: (state, { error }) => ({ ...state, error: error }),
  [RECIPIENT_FORM_SUCCESS]: (state, { success }) => ({ ...state, success: success }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state
};
