const firebase = {
  apiKey: 'AIzaSyDFrjxs-bHvGXimmnqMunrmM4SoEUCc-4g',
  authDomain: 'boltbox-admin-e2e.firebaseapp.com',
  databaseURL: 'https://boltbox-admin-e2e.firebaseio.com',
  projectId: 'boltbox-admin-e2e',
  storageBucket: 'boltbox-admin-e2e.appspot.com',
  messagingSenderId: '225331902764'
};

const google = {
  analytics: {
    trackingId: '',
  },
  recaptcha: {
    siteKey: '6LcPkfwUAAAAAClj5kXCvO4coEPhQe5sIzJFXhE6',
  }
};

const sentry = {
  dsn: 'https://466428874f3c4251bc3a96c2b0a9ece6@o234632.ingest.sentry.io/5249805',
  release: 'boltbox-admin@8c0796f0a42a4f933e695c3265989e2e44e416d8'
};

const domain = 'boltbox-admin-e2e.web.app';
const site = {
  domain,
  title: 'BoltBox App Admin',
  url: `https://${domain}`,
  development: true,
};

const mapbox = {
  accessToken: 'pk.eyJ1IjoiYm9sdGJveGFwcCIsImEiOiJja2FqNjNiNTYwOGt0MnFuczJzOXRzM21wIn0.-uSNJLA8yMWCJWhHCc_htA',
  navigationApi: 'https://api.mapbox.com/directions/v5/mapbox/driving/{location}?access_token={accessToken}',
  showInstructions: true,
};

// Our own apis
const api = {
  searchPlaces: 'https://us-central1-boltbox-admin-e2e.cloudfunctions.net/searchPlace',
  geocode: 'https://us-central1-boltbox-admin-e2e.cloudfunctions.net/geocode',
  sendSMS: 'https://us-central1-boltbox-admin-e2e.cloudfunctions.net/sendSMS',
  sendEmail: 'https://us-central1-boltbox-admin-e2e.cloudfunctions.net/sendEmail',
  createPDFInvoice: 'https://us-central1-boltbox-admin-e2e.cloudfunctions.net/createPDFInvoice',
  checkRecaptcha: 'https://us-central1-boltbox-admin-e2e.cloudfunctions.net/checkRecaptcha',
};

const login = {
  resetTime: {
    amount: 30,
    unit: 'seconds'
  }
}

export {
  api,
  firebase,
  google,
  mapbox,
  sentry,
  site,
  login,
};
