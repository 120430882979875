// const string for actions in uppercase
const ACCOUNT_NEW = 'ACCOUNT_NEW';
const ACCOUNT_EDIT = 'ACCOUNT_EDIT';
const ACCOUNT_EMAIL_CHANGED = 'ACCOUNT_EMAIL_CHANGED';
const ACCOUNT_PASSWORD_CHANGED = 'ACCOUNT_PASSWORD_CHANGED';
const ACCOUNT_FIRST_NAME_CHANGED = 'ACCOUNT_FIRST_NAME_CHANGED';
const ACCOUNT_LAST_NAME_CHANGED = 'ACCOUNT_LAST_NAME_CHANGED';
const ACCOUNT_ROLE_CHANGED = 'ACCOUNT_ROLE_CHANGED';
const ACCOUNT_DRIVER_LICENSE = 'ACCOUNT_DRIVER_LICENSE';
const ACCOUNT_PHONE_NUMBER_CHANGED = 'ACCOUNT_PHONE_NUMBER_CHANGED';
const ACCOUNT_TERMINAL_LOCATION_CHANGED = 'ACCOUNT_TERMINAL_LOCATION_CHANGED';
const ACCOUNT_METRC_NUMBER_ID_CHANGED = 'ACCOUNT_METRC_NUMBER_ID_CHANGED';
const ACCOUNT_CLEAR = 'ACCOUNT_CLEAR';

// actions
export const actions = {
  onAccountNew: () => ({ type: ACCOUNT_NEW}),
  onAccountEdit: (data) => ({ type: ACCOUNT_EDIT, data }),
  onAccountEmailChanged: (email) => ({ type: ACCOUNT_EMAIL_CHANGED, email }),
  onAccountPasswordChanged: (password) => ({ type: ACCOUNT_PASSWORD_CHANGED, password }),
  onAccountFirstNameChanged: (firstName) => ({ type: ACCOUNT_FIRST_NAME_CHANGED, firstName }),
  onAccountLastNameChanged: (lastName) => ({ type: ACCOUNT_LAST_NAME_CHANGED, lastName }),
  onAccountRoleChanged: (role) => ({ type: ACCOUNT_ROLE_CHANGED, role }),
  onAccountDriverLicenseChanged: (driverLicense) => ({ type: ACCOUNT_DRIVER_LICENSE, driverLicense }),
  onAccountNumberPhoneChanged: (phoneNumber) => ({ type: ACCOUNT_PHONE_NUMBER_CHANGED, phoneNumber }),
  onAccountTerminalChanged: (terminalLocationId) => ({ type: ACCOUNT_TERMINAL_LOCATION_CHANGED, terminalLocationId }),
  onAccountMetrcNumberIdChanged: (metrcNumberId) => ({ type: ACCOUNT_METRC_NUMBER_ID_CHANGED, metrcNumberId }),
  clear: () => ({ type: ACCOUNT_CLEAR }),
};

// initial state
const initialState = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  role: '',
  driverLicense: '',
  metrcNumberId: '',
  phoneNumber: '',
  terminalLocationId: '',
};

// action handlers
const actionHandlers = {
  [ACCOUNT_NEW]: (state) => ({ ...state, ...initialState }),
  [ACCOUNT_EDIT]: (state, { data }) => ({ ...state, ...data }),
  [ACCOUNT_EMAIL_CHANGED]: (state, { email }) => ({ ...state, email }),
  [ACCOUNT_PASSWORD_CHANGED]: (state, { password }) => ({ ...state, password }),
  [ACCOUNT_FIRST_NAME_CHANGED]: (state, { firstName }) => ({ ...state, firstName }),
  [ACCOUNT_LAST_NAME_CHANGED]: (state, { lastName }) => ({ ...state, lastName }),
  [ACCOUNT_ROLE_CHANGED]: (state, { role }) => ({ ...state, role }),
  [ACCOUNT_DRIVER_LICENSE]: (state, { driverLicense }) => ({ ...state, driverLicense }),
  [ACCOUNT_PHONE_NUMBER_CHANGED]: (state, { phoneNumber }) => ({ ...state, phoneNumber }),
  [ACCOUNT_TERMINAL_LOCATION_CHANGED]: (state, { terminalLocationId }) => ({ ...state, terminalLocationId }),
  [ACCOUNT_METRC_NUMBER_ID_CHANGED]: (state, { metrcNumberId }) => ({ ...state, metrcNumberId }),
  [ACCOUNT_CLEAR]: () => ({ ...initialState }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
