import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { formatShortDate, parsedOrders } from '../../utils';
import * as collections from 'constants/collections';
import * as taskStatus from 'constants/taskStatus';
import * as colors from 'constants/colors';
import { orderPathForEdit, orderPathForView } from 'constants/routes';
import DataTable from 'react-data-table-component';

const ListOrders = ({ orders, history }) => {
  const columns = [
    {
      name: '#',
      center: true,
      maxWidth: '5%',
      selector: 'orderNumber',
    },
    {
      compact: true,
      name: 'Status',
      maxWidth: '5%',
      selector: 'status',
    },
    {
      name: 'Drivers',
      selector: 'drivers',
    },
    {
      name: 'Sender',
      selector: 'sender',
    },
    {
      name: 'Recipient',
      selector: 'recipient',
    },
    {
      name: 'Estimated Delivery',
      selector: 'estimatedDelivery',
    },
    {
      name: 'Created By',
      selector: 'createdBy',
    },
  ];

  const customStyles = {
    cells: {
      style: {
        fontSize: '10px',
      },
      left: true,
    },
    headCells: {
      style: {
        fontWeight: '500',
        fontFamily: 'Montserrat',
        fontSize: '10.5px',
        letterSpacing: '.06em',
        verticalAlign: 'middle',
      },
    },
    headRow: {
      style: {
        textTransform: 'uppercase',
        backgroundColor: '#fff',
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: row => row.status === taskStatus.CREATED || taskStatus.CANCELED,
      style: {
        backgroundColor: colors.yellow,
      },
    },
    {
      when: row => row.status === taskStatus.PROGRESS,
      style: {
        backgroundColor: colors.darkGreen,
      },
    },
    {
      when: row => row.status === taskStatus.COMPLETED,
      style: {
        backgroundColor: colors.red,
      },
    },
  ];

  const onRowClicked = ({ id, status }) => {
    const orderPath = status === taskStatus.CREATED
      ? orderPathForEdit(id)
      : orderPathForView(id);

    history.push(orderPath);
  }

  const data = orders.map(
    ({
      id,
      orderNumber,
      status,
      driver,
      assistant,
      sender,
      recipient,
      estimatedDelivery,
      creator,
    }) => {
      return {
        id,
        orderNumber,
        status,
        drivers: [
          driver ? driver.firstName || '' : false,
          assistant ? assistant.firstName || '' : false,
        ]
          .filter(Boolean)
          .join(', '),
        sender: sender && (sender.name || '-'),
        recipient: recipient && (recipient.name || '-'),
        estimatedDelivery: formatShortDate(estimatedDelivery),
        createdBy: creator && (creator.firstName || '-'),
      };
    },
  );

  return (
    <div className="container-fluid">
      <div className="col-xs-12 pt-4">
        <DataTable
          columns={columns}
          conditionalRowStyles={conditionalRowStyles}
          customStyles={customStyles}
          dense
          data={data}
          noHeader
          pagination
          paginationPerPage={25}
          paginationRowsPerPageOptions={[10, 25, 50]}
          responsive
          onRowClicked={onRowClicked}
          pointerOnHover
          highlightOnHover
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({
  firestore: { ordered, data }
}, {
  recipients,
  vehicles,
}) => {
  const orders = parsedOrders({
    orders: ordered[ALL_ORDERS],
    users: data.users,
    destinations: data.destinations,
    recipients,
    vehicles,
  });
  return { orders };
};

const ALL_ORDERS = 'orders';
const FirestoreConnected = compose(
  withRouter,
  firestoreConnect([
    {
      collection: collections.TASK,
      storeAs: ALL_ORDERS
    }
  ]),
  connect(mapStateToProps),
)(ListOrders);

export default FirestoreConnected;
