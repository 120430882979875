import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, withFirebase, withFirestore } from 'react-redux-firebase';
import { Button } from 'reactstrap';
import WithLoader from 'components/WithLoader';
import User from './User';
import { actions as accountActions } from 'reducers/account';
import { actions as fetchingActions } from 'reducers/fetching';
import * as collections from 'constants/collections';
import * as roles from 'constants/roles';

class Dispatchers extends User {
  static defaultProps = {
    role: 'dispatcher'
  }

  getAccounts() {
    return this.props.dispatchers;
  }

  getNewAccountButton() {
    return <Button color="primary" onClick={() => this.showAccount()}>New Employee</Button>;
  }
}

const ALL_USERS = 'ALL_USERS';

const mapStateToProps = ({ account, firestore: { ordered }, fetching: { isLoading } }) => {
  const users = ordered[ALL_USERS] || [];
  const dispatchers = users.filter(u => ([roles.DISPATCHER, roles.ADMIN].includes(u.role)) && !u.disabled);

  return {
    account,
    isLoading,
    dispatchers,
  };
};

const mapDispatchToProps = ({
  ...accountActions,
  ...fetchingActions,
});

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  firestoreConnect([{
    collection: collections.USER,
    orderBy: ['firstName', 'asc'],
    storeAs: ALL_USERS
  }]),
  connect(mapStateToProps, mapDispatchToProps),
)(Dispatchers);

export default WithLoader(FirestoreConnected);
