import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class CarInfo extends PureComponent {
  static propTypes = {
    driver: PropTypes.object,
    assistant: PropTypes.object,
    destination: PropTypes.object,
    location: PropTypes.object,
    remainingDistance: PropTypes.number,
    estimatedTimeArrival: PropTypes.number,
    activeTask: PropTypes.bool,
    streetName: PropTypes.string,
  };

  renderLocationInfo() {
    const { location, streetName, streetMaxSpeed } = this.props;
    if (location && location.lon && location.lat) {
      const value = (streetName && streetName !== "") ? streetName : `${location.lat},${location.lon}`;
    const maxSpeedValue = (streetMaxSpeed &&  location.speedUnit && streetMaxSpeed !== "" && location.speedUnit !=="") ?  `(Max Speed : ${streetMaxSpeed} ${location.speedUnit || ''})` : '';

      return <div className="d-flex align-items-baseline popup-row">
        <i className="fa fa-map-pin"></i>
        <span><a href={`https://maps.google.com/?q=${location.lat},${location.lon}&z=16`} target="_blank" rel="noopener noreferrer">{value} {maxSpeedValue && <br />} {maxSpeedValue}</a></span>
      </div>
    }
  }

  renderAssistantInfo() {
    const { activeTask, assistant, } = this.props;

    if (activeTask && assistant) {
      return (
        <div className="d-flex align-items-baseline popup-row">
          <i className="fa fa-user-plus"></i>
          <span>{assistant.firstName || ''} {assistant.lastName || ''}</span>
        </div>
      );
    }
  }

  renderCurrentSpeed() {
    const { location, streetMaxSpeed } = this.props;

    if (location && location.speed && streetMaxSpeed) {
      return (
        <div className="d-flex align-items-baseline popup-row">
          <i className="fa fa-tachometer"></i>
          <span className = {`${location.speed > streetMaxSpeed ? 'blink overspeed': ''}`}>Current Speed: {`${streetMaxSpeed} ${location.speedUnit}` || 0} </span>
        </div>
      );
    }
  }

  renderDestinationInfo() {
    const {
      activeTask,
      destination,
      remainingDistance, // in meters
      estimatedTimeArrival, // in seconds
    } = this.props;

    const metersToMiles = meters => {
      return (meters * 0.000621371).toFixed(2);
    }

    if (activeTask && destination) {
      return (
        <div className="d-flex align-items-baseline popup-row">
          <i className="fa fa-map-marker"></i>
          <div>
            <span>{destination.name}</span>
            {remainingDistance && <span> Remaining <strong>{metersToMiles(remainingDistance)}</strong> miles.</span>}
            {estimatedTimeArrival && <span> (ETA - <strong>{moment.duration(estimatedTimeArrival, 'seconds').humanize()}</strong>)</span>}
          </div>
        </div>
      );
    }
  }

  render() {
    const { activeTask, driver } = this.props;
    let name = `${driver.firstName || ''} ${driver.lastName || ''}`.trim();
    return (
      <div className="map-pin-popup">
        <div className="d-flex align-items-baseline popup-row">
          <i className="fa fa-user"></i>
          <span className="font-weight-bold">{name || 'No Name'}</span>
        </div>
        {this.renderLocationInfo()}
        {this.renderCurrentSpeed()}
        {this.renderAssistantInfo()}
        {this.renderDestinationInfo()}
        {!activeTask && <span>Not active tasks</span>}
      </div>
    );
  }
}

export default CarInfo;