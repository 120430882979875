import { LabelPin } from '.';
import { gray } from 'constants/colors';

class DetourPin extends LabelPin {
  constructor(props) {
    super(props);

    this.propsDefault.size = 30;
    this.propsDefault.className = 'detour';
    this.propsDefault.fill = gray;
    this.state = { text: 'D' };
  }
}

export default DetourPin;
