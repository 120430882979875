import React from 'react';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import WebMercatorViewport from 'viewport-mercator-project';

const withBounds = (Component) => {
  class WithBounds extends React.Component {
    render = () => {
      const getBounds = (points) => bbox(lineString(points));
      const fitBounds = (viewPort, bounds, opts = {padding: 80}) => {
        const webMercatorViewport = new WebMercatorViewport(viewPort);
        const [minLng, minLat, maxLng, maxLat] = bounds;
        return webMercatorViewport.fitBounds(
            [[minLng, minLat], [maxLng, maxLat]],
            opts
          );
      };

      return <Component
        {...this.props}
        getBounds={getBounds}
        fitBounds={fitBounds}
        fitPoints={(points, viewPort, opts) => {
          return fitBounds(viewPort, getBounds(points), opts)
        }}
      />;
    }
  }

  return WithBounds
};

export default withBounds;
