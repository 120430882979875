import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {DriverRouteMap} from 'components/Map';

import moment from "moment";
import { EVENT_TYPES } from 'constants/taskEvents';
import { DATE_TIME_FORMAT } from "constants/formats";

class TaskModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.toggleRoute = this.toggleRoute.bind(this);
  }

  state = {
    toggleRoute: false
  };

  toggleRoute() {
      this.setState(prevState => ({
        toggleRoute: !prevState.toggleRoute
      }));
  }

  render() {
    const { isOpen, toggle, task, driver, recipient, address } = this.props;
    const { id, details } = task;

    return <Modal isOpen={isOpen} toggle={toggle} className={this.props.className} size={'lg'}>
      <ModalHeader toggle={toggle}>Task {id}</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
                <p className="col-4">Assigned to</p>
                <div className="col-8 bold">
                  <p>{driver && `${driver.firstName} ${driver.lastName}`}</p>
                  <p>{driver && driver.phone}</p>
                </div>
            </div>
            <div className="row">
                <p className="col-4">{task.taskTypes.includes('pickup') ? 'Shipper' : 'Receiver'}</p>
                <div className="col-8 bold">
                  <p>{recipient && `${recipient.firstName || recipient.name} ${recipient.lastName || ''}`}</p>
                  <p>{recipient && recipient.phone}</p>
                </div>
            </div>
            <div className="row">
                <p className="col-4">Destination</p>
                <div className="col-8 bold">
                  {address}
                </div>
            </div>
            <div className="row">
                <p className="col-4">Duration</p>
                <p className="col-8">{details.duration && moment.duration(details.duration, 'seconds').humanize()}</p>
            </div>
            <div className="row">
                <p className="col-4">Distance</p>
                <p className="col-8">{(details.distance * 0.62137).toFixed(2)} miles</p>
            </div>
            {(details.signatureUrl || details.photoUrl) &&
            <div className="row">
              {details.signatureUrl &&
              <div className="col-lg-6">
                <div className="row"><p className="col-4">Signature</p></div>
                <p><img style={{maxWidth: '200px'}} src={details.signatureUrl } alt="signature" /></p>
              </div>}
              {details.photoUrl &&
              <div className="col-lg-6">
                <p className="">Photo</p>
                <p><img style={{maxWidth: '200px'}} src={details.photoUrl} alt="task" /></p>
              </div>}
            </div>}
          </div>
          <div className="col-md-6 events-timeline">
            {this.state.toggleRoute ? <div className="driver-path">
              <DriverRouteMap task={id}/>
            </div>
              :
              <div className="timeline-wrapper">
                <h4>Timeline</h4>
                <hr/>
                <div className="list-wrapper">
                  <ul>
                    {
                      details && details.events && details.events.map((event, idx) => (
                        <li className="event" key={idx}>
                          <p className={'event-title'}>{EVENT_TYPES.get(event.name)}</p>
                          <p>
                            {event.location && event.location.lat && event.location.lon &&
                            <a target="_blank" rel="noopener noreferrer"
                               href={`https://www.google.com/maps/search/?api=1&query=${event.location.lat},${event.location.lon}`}>
                              {event.location.lat.toFixed(3)},{event.location.lon.toFixed(3)}&nbsp;-&nbsp;</a>
                            }
                            <time>{moment(event.time * 1000).format(DATE_TIME_FORMAT)}</time>
                          </p>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            }
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={this.toggleRoute}>View {this.state.toggleRoute ? 'Timeline' : 'Route'}</Button>
        <Button color="primary" onClick={toggle}>Done</Button>
      </ModalFooter>
    </Modal>;
  }
}

export default TaskModal;
