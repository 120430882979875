export const blue = '#5773FF';
export const gray = '#78849E';
export const red = '#ff0000';
export const light = '#FFF';
export const summerSky = '#3dbad9';
export const blueBell = '#9792d3';
export const lightGray = '#efebeb';
export const green = '#a6ce9c';
export const darkGreen = '#1f9d55';
export const yellow = '#dcec4b';
export const orange = '#f39c12';
