import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Base Pin Class
// We will need to other type of pins
class Pin extends PureComponent {
  static propTypes = { 
    size: PropTypes.number,
    fill: PropTypes.string,
  };

  render() {
    return <div>Please use your own custom pin</div>;
  }
}

export default Pin;
