import { api } from 'config';

const SEND_SMS_STATUS = 'SEND_SMS_STATUS';

// actions
export const actions = {
  sendSMS: (to, message) => (dispatch) => {
    const config = {
      method: 'POST',
      headers:{ 'Content-Type': 'application/json' },
      body: JSON.stringify({ to, message })
    }

    return fetch(api.sendSMS, config)
      .then(response => response.json())
      .then(json => {
        const sent = json.status === 'sent';
        dispatch({ type: SEND_SMS_STATUS, sent });
        return sent;
      }).catch(() => false)
  }
};

// initial state
const initialState = {
  sent: false
};

// action handlers
const actionHandlers = {
  [SEND_SMS_STATUS]: (state, { sent }) => ({ ...state, sent }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
