import { LabelPin } from '.';
import { blueBell } from 'constants/colors';

class DestinationPin extends LabelPin {
  constructor(props) {
    super(props);

    this.propsDefault.className = 'destination';
    this.propsDefault.fill = blueBell;
    this.state = { text: 'B' };
  }
}

export default DestinationPin;
