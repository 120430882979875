import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Container, Button } from 'reactstrap';
import { NotificationManager, NotificationPosition } from 'components/Layout';
import withOrder from 'components/WithOrder';
import { TaskForm } from 'components/Task';
import { actions as taskActions } from 'reducers/task';
import { actions as destinationActions } from 'reducers/destination';
import { actions as instructionsActions } from 'reducers/instructions';
import * as collections from 'constants/collections';
import * as destinationKeys from 'constants/destinations';
import { homePath } from 'constants/routes';
import { getLicenses, getGroupedBrands } from 'utils';

class EditOrder extends PureComponent {
  state = {
    isLoaded: false,
    recipients: this.props.recipients || [],
    terminals: this.props.terminals || [],
    vehicles: this.props.vehicles || [],
    recipientLicenses: [],
    senderLicenses: [],
    brands: this.props.brands || []
  }

  componentDidMount() {
    const { task } = this.props;
    if (task) {
      this.setFormData(task);
    }
  }

  setFormData = async (task) => {
    const {
      firestore,
      onEditTask,
      onEstimatedPickupDate, onEstimatedPickupTime,
      onEstimatedDeliveryDate, onEstimatedDeliveryTime,
      onVehicleChanged,
      onTerminalChanged,
      onRecipientLicenseChanged,
      onSenderLicenseChanged,
      onBrandChanged,
    } = this.props;

    onEditTask(task);
    onEstimatedPickupDate(moment.unix(task.estimatedPickup).format('YYYY-MM-DD'));
    onEstimatedPickupTime(moment.unix(task.estimatedPickup).format('HH:mm'));
    onEstimatedDeliveryDate(moment.unix(task.estimatedDelivery).format('YYYY-MM-DD'))
    onEstimatedDeliveryTime(moment.unix(task.estimatedDelivery).format('HH:mm'))

    const origin = `${task.origin ? task.origin.owner : task.originId}, ${task.originId}`;
    const destination = `${task.destination ? task.destination.owner : task.destinationId}, ${task.destinationId}`;
    const vehicleId = (task && task.vehicleId) || (task && task.vehicle && task.vehicle.id) || (task.driver && task.driver.vehicleId);

    // Not in list, get sender from databse and ask if sender is disabled 
    const recipientLicenses = await getLicenses(firestore, task.recipientId, 'recipientLicenses');
    const senderLicenses = await getLicenses(firestore, task.senderId, 'senderLicenses');

    this.setState({ recipientLicenses });
    this.setState({ senderLicenses });

    await this.getTaskItem(task.senderId, collections.RECIPIENT);
    await this.getTaskItem(task.recipientId, collections.RECIPIENT);
    await this.getTaskItem(vehicleId, collections.VEHICLE);
    await this.getTaskItem(task.terminalId, collections.TERMINAL);
    await this.getTaskItem(task.brandId, collections.BRAND);
    await this.getTaskInstructions(task.instructionsId);

    this.handleBusinessValue(destination, destinationKeys.TASK_DESTINATION);
    this.handleBusinessValue(origin, destinationKeys.TASK_ORIGIN);


    const vehicle = this.state.vehicles.find(v => v.id === vehicleId);
    const terminal = this.state.terminals.find(t => t.id === task.terminalId);
    const recipientLicense = (this.state.recipientLicenses && this.state.recipientLicenses.find(l => l.id === task.recipientLicenseId)) || [];
    const senderLicense = (this.state.senderLicenses && this.state.senderLicenses.find(l => l.id === task.senderLicenseId)) || [];
    const brand = this.state.brands.find(l => l.id === task.brandId);

    vehicle && onVehicleChanged(vehicle);
    terminal && onTerminalChanged(terminal.id);
    recipientLicense && onRecipientLicenseChanged(recipientLicense);
    senderLicense && onSenderLicenseChanged(senderLicense);
    brand && onBrandChanged(brand); 

    this.setState({ isLoaded: true });
  }

  getTaskItem = async (itemId, collection) => {
    if (!itemId || !collection) {
      return;
    }

    const { firestore } = this.props;
    const isItemEnabled = this.state[collection].find(r => r.id === itemId);
    if (!isItemEnabled) {

      var disabledItem = await firestore.collection(collection).doc(itemId).get();
      const item = disabledItem.data();

      if (item && item.disabled) {
        item.id = itemId;
        const newState = [...this.state[collection], item];

        this.setState(prevState => ({
          ...prevState,
          [collection]: newState
        }))
      }
    }
  }

  getTaskInstructions = async (instructionsId) => {
    if (!instructionsId) {
      return;
    }

    const { firestore, onTaskInstrunctionsChanged } = this.props;
    const item = await firestore.collection(collections.ORDER_INSTRUCTIONS).doc(instructionsId).get();
    
    if(item.empty) {
      return null;
    }
    const orderInstructions = item.data();
    orderInstructions && onTaskInstrunctionsChanged(orderInstructions);
  }

  handleBusinessValue(selectedDestination, on = destinationKeys.TASK_DESTINATION) {
    const {
      destinations,
      onRecipientChanged,
      onDestinationChanged,
      onSenderChanged,
      onOriginChanged,
      onRecipientInfoChanged,
      onDestinationEdit,
      onFilteredBrandChanged,
    } = this.props;

    const [recipientId, destinationId] = selectedDestination.split(',').map(id => id.trim());
    const recipient = this.state.recipients.find(r => r.id === recipientId);

    const destination = destinations.find(d => d.id === destinationId);

    if (on === destinationKeys.TASK_DESTINATION) {
      onRecipientChanged(recipient);
      onDestinationChanged(destination);
      onDestinationEdit(destinationKeys.TASK_DESTINATION, null);
      onDestinationEdit(destinationKeys.TASK_DESTINATION, destination);
      !recipient && onRecipientInfoChanged(on, { name: selectedDestination });
    } else if (on === destinationKeys.TASK_ORIGIN) {
      onSenderChanged(recipient);
      onOriginChanged(destination);
      onDestinationEdit(destinationKeys.TASK_ORIGIN, null);
      onDestinationEdit(destinationKeys.TASK_ORIGIN, destination);

      if (recipient && recipient.id) {
         onFilteredBrandChanged(getGroupedBrands(this.state.brands, recipient));
      }

      !recipient && onRecipientInfoChanged(on, { name: selectedDestination });
    }
  }

  deleteOrder() {
    const { task } = this.props;
    const { firestore, onNewTask, history } = this.props;

    if (window.confirm(`Are you sure to delete this Order #${task.orderNumber}?`)) {
      firestore
        .collection(collections.TASK)
        .doc(task.id)
        .delete()
        .then(() => NotificationManager.success('Order successfully Deleted', null, NotificationPosition.TOP_LEFT));
      onNewTask();
      history.push(homePath);
    }
  }

  render() {
    const {
      task,
      drivers,
      destinations,
      currentUser,
      history,
    } = this.props;

    const { isLoaded } = this.state;

    if (task) {
      return (
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <h1>EDIT ORDER&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Order #{task.orderNumber || task.id}</h1>
            <Button color="primary" onClick={() => this.deleteOrder()}>Delete Order</Button>
          </div>
          {isLoaded && (
            <TaskForm
              currentUser={currentUser}
              drivers={drivers}
              destinations={destinations}
              recipients={this.state.recipients}
              terminals={this.state.terminals}
              vehicles={this.state.vehicles}
              recipientLicenses={this.state.recipientLicenses}
              senderLicenses={this.state.senderLicenses}
              brands={this.state.brands}
              toggle={() => history.push(homePath)}
            />
          )}
        </Container>
      );
    }
  }
}

const mapDispatchToProps = ({
  ...taskActions,
  ...destinationActions,
  ...instructionsActions
});

const Connected = compose(
  withRouter,
  withOrder,
  connect(null, mapDispatchToProps),
)(EditOrder);

export default Connected;
