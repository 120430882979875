import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, firestoreConnect } from 'react-redux-firebase';
import qs from 'qs';
import moment from 'moment';
import { api } from 'config';
import { partition, values, orderBy } from 'lodash';
import { DATE_TIME_FORMAT, TIME_FORMAT, SHORT_DATE_FORMAT } from 'constants/formats';
import * as taskStatus from 'constants/taskStatus';
import * as taskEvents from 'constants/taskEvents';
import * as collections from 'constants/collections';

export const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
);

export const withSettings = compose(
  firestoreConnect(props => {
    const { auth } = props;
    if (auth.isLoaded && auth.isEmpty) {
      return [];
    }

    return [
      {
        collection: collections.SETTING,
        limit: 1
      }]
  }),
  connect(({ firebase: { auth }, firestore: { data, ordered: { settings } } }) => {
    return {
      settings: {
        isLoaded: ('settings' in data) || (auth.isLoaded && auth.isEmpty),
        isEmpty: !data.settings,
        ...(settings && settings.length && settings[0])
      }
    }
  })
);

// Google geocode api always returns the last two elements for state & country
export const getLine1FromAddress = address => address
  .split(",")
  .slice(0, -2)
  .join(",");

export const geocodeAddress = address => {
  if (!address) {
    return {};
  }

  return fetch(api.geocode + '?' + qs.stringify({ address }))
    .then(response => response.json())
    .then(json => {
      const [address] = json.results;

      if (!address) {
        return;
      }

      const addressComponents = address.address_components;
      const location = address.geometry.location;
      const formatted = address.formatted_address;
      const googlePlaceId = address.place_id;
      const postCode = addressComponents.find((component) => component.types.indexOf('postal_code') !== -1);
      const state = addressComponents.find((component) => component.types.indexOf('administrative_area_level_1') !== -1);
      const country = addressComponents.find((component) => component.types.indexOf('country') !== -1);
      return {
        location: { lat: location.lat, lon: location.lng },
        address: {
          formatted,
          line1: getLine1FromAddress(formatted),
          state: state && state.short_name,
          country: country && country.short_name,
          postCode: (postCode && postCode.short_name) || '',
        },
        extra: { googlePlaceId },
      }
    });
}

export const authCondition = ({ auth }) => !isEmpty(auth);

export const unAuthCondition = ({ auth }) => isEmpty(auth);

/**
 *
 * @param time MUST BE IN SECONDS
 * @returns {string}
 */
export const formatDateTime = (time) => moment(time * 1000).format(DATE_TIME_FORMAT);
export const formatTime = (time) => moment(time * 1000).format(TIME_FORMAT);
export const formatShortDate = (time) => moment(time * 1000).format(SHORT_DATE_FORMAT);

export const now = () => {
  return new Date().getTime() / 1000;
}

export const sendEmail = async ({ email, subject, message }) => {
  const config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, subject, message })
  }
  try {
    const response = await fetch(api.sendEmail, config);
    const json = await response.json();
    return (json.status === 'sent');
  }
  catch (e) {
    return false;
  }
}

export const createPDFInvoice = async ({ taskId }) => {
  const config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ taskId })
  }
  try {
    const response = await fetch(api.createPDFInvoice, config);
    const json = await response.json();
    return (json.status === 'ok');
  }
  catch (e) {
    return false;
  }
}

// Return a contrast color (black, white) for specific hex color
export const getContrastColor = (hexColor) => {
  if (hexColor.slice(0, 1) === '#') {
    hexColor = hexColor.slice(1);
  }

  if (hexColor.length === 3) {
    hexColor = [...hexColor].map(color => color + color).join('');
  }

  // Convert to RGB value
  var r = parseInt(hexColor.substr(0, 2), 16);
  var g = parseInt(hexColor.substr(2, 2), 16);
  var b = parseInt(hexColor.substr(4, 2), 16);

  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  // Check contrast
  return (yiq >= 128) ? '#000' : '#fff';
}

export const checkRecaptcha = async (token) => {
  const config = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }
  try {
    const response = await fetch(`${api.checkRecaptcha}?token=${token}`, config);
    const jsonResult = await response.json();
    return jsonResult;
  }
  catch (e) {
    return false;
  }
}

export const parsedOrders = ({
  orders = [],
  recipients,
  destinations,
  users,
  vehicles,
}) => {
  const taskSorting = [
    taskStatus.PROGRESS,
    taskStatus.ASSIGNED,
    taskStatus.CREATED,
    taskStatus.COMPLETED,
  ];

  const tasks = orders.map(task => ({
    ...task,
    sort: taskSorting.indexOf(task.status),
    sender: (recipients && recipients.find(r => r.id === task.senderId)) || {},
    origin: (destinations[task.originId] || null),
    recipient: (recipients && recipients.find(r => r.id === task.recipientId)) || {},
    destination: (destinations[task.destinationId] || null),
    assistant: (users[task.assistantId] || null),
    driver: (users[task.driverId] || null),
    creator: (users[task.creator] || null),
    vehicle: (vehicles && vehicles.find(v => v.id === task.vehicleId)) || {},
  }));
  const [completedTasks, restTasks] = partition(values(tasks), task =>
    task.status === taskStatus.COMPLETED && task.details.events.length > 0);

  const sortedCompletedTasks = orderBy(completedTasks, task => {
    const completedEvent = task.details.events.find(item => item.name === taskEvents.ORDER_EVENT_COMPLETION);
    return completedEvent ? completedEvent.time : '';
  }, ['desc']);

  const sortedRestTasks = orderBy(restTasks, ['sort', 'estimatedDelivery'], ['asc', 'asc']);

  return [
    ...sortedRestTasks,
    ...sortedCompletedTasks
  ];
}


/**
 *
 *
 * @param {*} actionName
 * @param {*} amount: Time the use has to wait to execute the same action again
 * @param {*} unit: seconds, minutes, hours, days, weeks, months, years
 */
export const logActionAttempt = (actionName, amount, unit) => {
  const attempsJson = localStorage.getItem(actionName);
  let count = 1;

  if (attempsJson) {
    const attempt = JSON.parse(attempsJson);
    count = attempt.count + 1;
  }

  localStorage.setItem(actionName, JSON.stringify({
    count: count,
    timestamp: moment().add(amount, unit)
  }));
}

export const getActionAttempt = actionName => {
  return localStorage.getItem(actionName);
}

export const clearActionAttempt = actionName => {
  localStorage.removeItem(actionName);
}

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

/**
 *
 * @description: Disable or remove document from collection dynamically
 * @param {*} firestoreInstance: {object} - Firestore database instance
 * @param {*} collection: {string} - Collection name, it is used to remove the document. It is displayed in the notification message
 * @param {*} itemKey: {value} - Document key in the collection
 * @param {*} disable: {boolean} - Indicates where to mark item as disable or remove completely from the database.
 * @param {*} disableReason: {string} - Additional message to explain why the item was just disabled
 * @param {*} notificationInstance {object} - NotificationManager instance
 * @param {*} notificationPosition {string} - Position of the notification (TopLeft, TopRight, BottomLeft, BottomRight)
 */
export const deleteOrDisableDocument = async (firestoreInstance, collection, itemKey, disable, disableReason, notificationInstance, notificationPosition) => {
  let result = '';
  const singularCollectionName = capitalizeFirstLetter(collection).substring(0, collection.length - 1)
  try {
    if (disable) {
      // soft delete
      await firestoreInstance
        .collection(collection)
        .doc(itemKey)
        .update({
          disabled: true
        });
      notificationInstance.success(`${singularCollectionName} successfully disabled. ${disableReason}.`, null, notificationPosition);
    } else {
      await firestoreInstance
        .collection(collection)
        .doc(itemKey)
        .delete()
      notificationInstance.success(`${singularCollectionName} successfully deleted.`, null, notificationPosition);
    }
  } catch (error) {
    notificationInstance.error(`An error ocurred trying to delete the ${singularCollectionName}.`, null, notificationPosition)
  } finally {
    return result;
  }
}
export const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

export const getLicenses = async (firestore, ownerId, stateProp) => {
  if (!ownerId) {
    return;
  }

  let licenses = [];
  licenses = await firestore.collection(collections.MRA_LICENSE).where('recipientId', '==', ownerId).get();

  if (licenses.empty) {
    return [];
  }
  return licenses.docs.map(l => ({ id: l.id, value: l.id, label: l.data().number }));
}

export const getBrands = (brands, sender, header, related = false) => {
  let options = [];
  
  const filteredBrands = brands.filter(brand => brand.recipients 
    &&  (related ? brand.recipients.includes(sender.id): !brand.recipients.includes(sender.id)));

  if (filteredBrands.length > 0) {
    options = [
      { label: header, value: -1, disabled: true }, // header
      ...filteredBrands.map(({ id: value, name: label }) => ({ value, label }))
    ]
  }
  
  return options;
}

export const getGroupedBrands = (brands, recipient) => {
  const relatedBrands = getBrands(brands, recipient, "--- RELATED BRANDS ---", true);
  const notRelated = getBrands(brands, recipient, (relatedBrands.length > 0 ? "--- OTHER BRANDS ---": ""));
  return [...relatedBrands, ...notRelated];
}

export const notificationTypes = [
  { name: 'on_order_day', label: "On Order's day" },
  { name: 'on_order_start', label: 'On Order start' },
  { name: 'on_order_arriving_interval', label: 'On Order arriving interval' },
];
