import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import _ from 'lodash';
// import PropTypes from 'prop-types';
import { /*Button,*/
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ButtonGroup,
  Button,
  Table,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Alert,
  /*ModalFooter*/ } from 'reactstrap';
import moment from 'moment';

import { RouteMap } from 'components/Map';
import { Address } from 'components/Select';
import { NotificationManager, NotificationPosition } from 'components/Layout';
import { actions as taskActions } from 'reducers/task';
import { geocodeAddress, now } from 'utils';
import * as collections from 'constants/collections';
import { DATE_FORMAT } from 'constants/formats';
import * as taskStatus from 'constants/taskStatus';
import { TaskCancelModal } from './';

class TaskReportModal extends Component {

  state = {
    cancelConfirmationDialog: false,
    cancelNote: null,
  };

  getTax = () => 6;

  getTaxFloat = () => this.getTax() / 100;

  getSubTotal = () => {
    const { task } = this.props;
    const { items } = task;
    if (items) {
      return items.reduce((prev, item) => prev += (item.price * item.quantity), 0);
    }

    return 0;
  }

  getTotal = () => {
    const { task } = this.props;
    const { items } = task;
    if (items) {
      // TODO: Get this TAX FROM settings
      return items.reduce((prev, item) => prev += (item.price * item.quantity), 0) * (1 + (this.getTaxFloat()));
    }

    return 0;
  }

  isSaveEnabled = () => {
    const { origin } = this.props;
    return !!origin;
  }

  isClearEnabled = () => {
    const { origin } = this.props;
    return !!origin;
  }

  save = () => {
    const { firestore, origin, waypoints, task: { id: taskId } } = this.props;
    const data = { origin, waypoints, modifiedAt: moment().utc().unix() };
    firestore.update({ collection: collections.TASK, doc: taskId },
      data)
  }

  toggleCancelConfirmationDialog = () => {
    this.setState(prevState => ({
      cancelConfirmationDialog: !prevState.cancelConfirmationDialog
    }));
  }

  cancelOrder = async () => {
    const { firestore, task: { id: taskId } } = this.props;
    await firestore.collection(collections.TASK)
      .doc(taskId)
      .update({
        status: taskStatus.CANCELED,
        cancelNote: this.state.cancelNote || '-',
        'details.events': firestore.FieldValue.arrayUnion({
          name: 'canceled',
          time: now()
        })
      });
    this.toggleCancelConfirmationDialog();
    NotificationManager.success('Order has been canceled!', null, NotificationPosition.TOP_LEFT);
  }

  clear = () => {
    this.props.clearWayPoints();
    this.props.changeOrigin(null);
  }

  onOpened = () => {
    const { waypoints } = this.props.task;
    waypoints && this.props.setWaypoints(waypoints);
  }

  render() {
    const self = this;
    const { isOpen, toggle, task, recipient, waypoints, origin, actions: { undo, redo } } = this.props;
    const { id, status, createdAt, destination, items } = task;
    const address = _.get(origin, 'address.line1');
    const { cancelConfirmationDialog } = this.state;
    // const address = '5647 Loveland St, West Bloomfield Township';

    return <Modal 
      isOpen={isOpen}
      toggle={toggle}
      className={this.props.className}
      size={'xl'}
      onOpened={this.onOpened}
      onExit={this.onExit}>
      {cancelConfirmationDialog && (
        <TaskCancelModal 
          isOpen={this.state.cancelConfirmationDialog}
          onClose={this.toggleCancelConfirmationDialog}
          cancelNote={this.state.cancelNote}
          onChangeInput={cancelNote => this.setState({ cancelNote })}
          confirmAction={this.cancelOrder}
        />
      )}
      <div className="modal-header p-0 text-left">
        <Container fluid className="mt-5">
          <Row>
            <Col>
              <h3>{(recipient && recipient.name) || 'No recipient'}</h3>
              <p>
                {destination.address.formatted}<br />
                {recipient && `P: ${recipient.phone}`}
              </p>
            </Col>
            <Col xs="auto" className="text-right">
              <h3>State {status}</h3>
              {status === taskStatus.PROGRESS 
                && <Button color="danger" onClick={this.toggleCancelConfirmationDialog}>Cancel Order</Button>}
              {/* <Button color="primary" className="btn-cons">Print</Button> */}
            </Col>
          </Row>
        </Container>
      </div>
      <ModalBody>
        <hr />
        <Row>
          <Col>
            <p>
              <strong>Addressed to</strong><br />
              State of Michigan<br />
              795 Folsom Ave, Suite 600<br />
              Lansing, MI 48209
            </p>
          </Col>
          <Col xs="auto">
            <p>
              <strong>Order Date:</strong> {moment(createdAt * 1000).format(DATE_FORMAT)}<br />
              <strong>Order Status:</strong> <span className="label label-warning label-pill">Pending</span><br />
              {/* TODO: Use some order ID generator if this is not good enough https://www.npmjs.com/package/order-id, #1234567 */}
              <strong>Order ID:</strong> #{id}
            </p>
          </Col>
        </Row>
        <br />
        {items && <Row>
          <Table>
            <thead>
              <tr>
                <th className="number">#</th>
                <th>Item</th>
                <th>Description</th>
                <th className="quantity">Quantity</th>
                <th className="price">Price</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, idx) =>
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{item.item}</td>
                  <td>{item.description}</td>
                  <td>{item.quantity}</td>
                  <td>{item.price}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>}
        <br />
        <Row className="align-items-end">
          <Col md="4">
            <h3>Route</h3>
            <Card className="route">
              <CardHeader>
                {_.isEmpty(origin) && <Alert color="warning">Please provide a departure address.</Alert>}
                <h6>From</h6>
                <div>
                  <Address
                    value={address}
                    onChange={e => {
                      const {
                        target: { value: address }
                      } = e;
                      address && geocodeAddress(address)
                        .then(origin => {
                          self.props.changeOrigin(origin);
                        });
                    }} />
                </div>
              </CardHeader>
              <CardFooter>
                <h6>To</h6>
                <h5>{destination.name}</h5>
              </CardFooter>
            </Card>
          </Col>
          <Col>
            <Card className="card-transparent">
              <CardBody>
                <ButtonGroup>
                  <Button disabled={undo.length === 0} onClick={this.props.undo} title="Undo">
                    <i className="fa fa-undo"></i>
                  </Button>
                  <Button disabled={redo.length === 0} onClick={this.props.redo} title="Redo">
                    <i className="fa fa-repeat"></i>
                  </Button>
                  <Button disabled={!this.isClearEnabled()} onClick={this.clear}>Clear</Button>
                </ButtonGroup>{' '}
                <Button color="primary" disabled={!this.isSaveEnabled()} onClick={this.save}>Save</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col style={{ height: '400px' }}>
            <RouteMap
              // originPoint={{lat: 42.5535689, lon: -83.4199712 }}
              originPoint={(origin && origin.location) || null}
              waypoints={waypoints}
              addWaypoint={this.props.addWaypoint}
              destinationPoint={destination.location} />
            <div><strong>Note: </strong>Please double click on a point where you want to add the detour.</div>
          </Col>
          <Col xs="auto" className="text-right">
              <strong>Sub-total: </strong> USD{this.getSubTotal()}<br />
              <strong>Tax: </strong> {this.getTax()}%
              <h3>USD{this.getTotal().toFixed(2)}</h3>
              <Button color="primary" className="btn-complete btn-cons">Submit</Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>;
  }
}

const mapStateToProps = ({
  firestore: {
    ordered: { settings }
  },
  task,
}, ownProps) => {
  return {
  settings: settings && settings.length && settings[0],
  waypoints: task.waypoints,
  actions: task.actions,
  origin: task.origin || ownProps.task.origin,
}};
const mapDispatchToProps = ({
  ...taskActions,
});

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps
);
const FirestoreConnected = compose(
  withFirestore,
  Connected,
)(TaskReportModal);
export default FirestoreConnected;
