import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

import { AppRoute } from 'components/Routes';
import ErrorBoundary from 'components/ErrorBoundary';
import ResourceNotFound from 'components/NotFound';

import Login from 'pages/Login';
import { ListOrders, NewOrder, ViewOrder, EditOrder } from 'pages/Orders';
import GeneralReports from 'pages/Reports';
import Monitoring from 'pages/Monitoring';
import firebase from '../../firebase';
import store from '../../store';

import {
  loginPath, homePath,
  newOrderPath, ordersPath, viewOrderPath, editOrderPath,
  reportsPath,
} from 'constants/routes';

import './App.css';

class App extends Component {
  constructor() {
    super();
    this.history = createBrowserHistory();

    // react-redux-firebase config
    this.rrfConfig = {
      userProfile: 'users',
      useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
      enableLogging: !['production', 'test'].includes(process.env.NODE_ENV)
    };

    this.rrfProps = {
      firebase,
      config: this.rrfConfig,
      dispatch: store.dispatch,
      createFirestoreInstance // <- needed if using firestore
    };
  }
  
  
  render() {
    const PageNotFound = () => <ResourceNotFound message="Sorry but we couldn't find this page." />;

    return (
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...this.rrfProps}>
          <Router history={this.history}>
            <Switch>
              <Route exact path={loginPath} component={Login} />
              
              <AppRoute exact path={homePath} component={Monitoring} />
              <AppRoute exact path={ordersPath} component={ListOrders} />
              <AppRoute exact path={newOrderPath} component={NewOrder} />
              <AppRoute exact path={viewOrderPath} component={ViewOrder} />
              <AppRoute exact path={editOrderPath} component={EditOrder} />
              <AppRoute exact path={reportsPath} component={GeneralReports} />
              <Route component={PageNotFound} />
            </Switch>
          </Router>
        </ReactReduxFirebaseProvider>
      </Provider>
    );
  }
}

export default ErrorBoundary(App);
