import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Notification, NotificationManager } from './';
import _ from 'lodash';

class NotificationContainer extends Component {
  static propTypes = {
    notificationStyle: PropTypes.string, // 'pgn-bar' -> Bar, 'pgn-flip' -> Flip Bar, 'pgn-circle' -> Circle and 'pgn-simple' -> Simple alert
  };

  state = {
    notifications: [],
  };

  componentDidMount = () => {
    NotificationManager.addChangeListener(this.handleStoreChange);
  };

  componentWillUnmount = () => {
    NotificationManager.removeChangeListener(this.handleStoreChange);
  };

  handleStoreChange = notifications => {
    this.setState({
      notifications,
    });
  };

  render() {
    const { notifications } = this.state;
    if (!notifications || notifications.length <= 0) {
      return null;
    }

    const { className } = this.props;
    const props = _.omit(this.props, [
      'data-position',
      'position',
      'className',
      'class',
    ]);
    const classNames = (className && className.split(' ')) || [];

    const notificationsByPosition = _.groupBy(notifications, 'position');

    return (
      <Fragment {...props}>
        {_.map(notificationsByPosition, (notifications, position) => (
          <div
            className={
              _.includes(classNames, 'pgn-wrapper')
                ? className
                : `pgn-wrapper ${className || ''}`
            }
            data-position={position}
            key={`pgn-wrapper-${position}`}
          >
            {notifications.map(notification => (
              <Notification
                key={notification.id}
                type={notification.type}
                timeOut={notification.timeOut}
              >
                {notification.title
                  ? `<strong>${notification.title}</strong> `
                  : ''}
                {notification.message}
              </Notification>
            ))}
          </div>
        ))}
      </Fragment>
    );
  }
}

export default NotificationContainer;
