import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import moment from 'moment';

import WithAuthorization from 'components/WithAuthorization';
import Layout, { NotificationManager, NotificationPosition } from 'components/Layout';
import { unAuthCondition, checkRecaptcha, logActionAttempt, clearActionAttempt, getActionAttempt } from 'utils';
import { homePath } from 'constants/routes';
import { actions as loginActions } from 'reducers/login';
import { site, google, login as loginConfig } from 'config';
import { MAX_LOGIN_ATTEMPTS_ALLOWED, MIN_RECAPTCHA_SCORE } from 'constants/firebaseAuth';

import wallpaper from 'images/boltbox_login_bg.png';
import logo from 'images/boltbox_logo.png';
import ForgotPassword from 'components/ForgotPassword';
import ReCaptcha from 'components/Recaptcha';

class Login extends Component {
  static propTypes = {
    email: PropTypes.string,
    onEmailChanged: PropTypes.func,
    onPasswordChanged: PropTypes.func,
    login: PropTypes.func,
    recaptcha: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      // TODO: store attempt in cookie
      loginAttemptCount: 0
    };

    this.toggle = this.toggle.bind(this);
  }


  onError(err) {
    const { message } = err;
    NotificationManager.error(message, null, NotificationPosition.TOP_LEFT);
  }


  handleLogin = () => {
    const {
      email,
      password,
      keepMeSignedIn,
      firebase
    } = this.props;

    // email and password are not empty
    if (!(_.isEmpty(email) || _.isEmpty(password))) {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence[keepMeSignedIn ? 'LOCAL' : 'SESSION']);
      firebase
        .login({ email, password })
        .then(() => {
          clearActionAttempt('login');
        })
        .catch(err => {
          const { resetTime: { amount, unit } } = loginConfig;
          logActionAttempt('login', amount, unit);
          this.onError(err);
          if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(err);
          }
        });
    } else {
      this.onError({
        message: 'Email and Password are required',
      });
    }
  }

  handleRecaptchaValidation = async (callback) => {
    const { recaptcha } = this.props;
    try {
      recaptcha.execute(google.recaptcha.siteKey, { action: 'login' })
        .then(async token => {  
          const result = await checkRecaptcha(token);

          if (result.status === 'failed') {
            this.onError({
              message: result.message
            });
            return;
          }

          if (result.score <= MIN_RECAPTCHA_SCORE) {
            this.onError({
              message: 'Unable to login at this moment.'
            });
            return;
          }

          callback();
        });
    } catch (err) {
      this.onError(err);
    }
  }

  onSubmit = async (event) => {
    const { resetTime: { unit } } = loginConfig;
    event.preventDefault();
    // if modal is open do nothing.
    if (this.state.modal) {
      return;
    }
    
    const attemptpsJson = getActionAttempt('login');
    const attemptps = attemptpsJson && JSON.parse(attemptpsJson);

    if (attemptps) {
      const end = moment(attemptps.timestamp);
      const now = moment();
      const difference = end.diff(now, unit);

      // reset attempts counter after 24 hours
      if (difference <= 0) {
        clearActionAttempt('login');
        this.handleRecaptchaValidation(this.handleLogin);
        return;
      }

      // reject login if attempts exceeded today
      if (difference > 0 && attemptps.count > MAX_LOGIN_ATTEMPTS_ALLOWED) {
        this.onError({
          message: `Too many unsuccessful login attempts. Please try again in ${difference} ${unit}`
        });
        return;
      }
    } 
    
    // otherwise execute captcha validation
    if (!attemptps || attemptps.count <= MAX_LOGIN_ATTEMPTS_ALLOWED) {
      this.handleRecaptchaValidation(this.handleLogin);
      return;
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    // TODO: Display `error`
    const { email, password, keepMeSignedIn, onEmailChanged, onPasswordChanged, onKeepMeSignedInChanged } = this.props;

    // TODO: Remove this after a href="#" is not needed
    /* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */
    return <Layout>
      <div className="login-wrapper ">
        <div className="bg-pic">
          <img src={wallpaper} data-src={wallpaper} data-src-retina={wallpaper} alt="" className="lazy" />
        </div>
        <div className="login-container bg-white">
          <div className="p-l-50 m-l-20 p-r-50 m-r-20 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
            <img src={logo} data-src={logo} width="220" alt={site.title} />
            <p className="p-t-35">Welcome back! Please login to your {site.title} account</p>
            <form id="form-login" className="p-t-15" onSubmit={this.onSubmit}>
              <div className="form-group form-group-default">
                <label>Email</label>
                <div className="controls">
                  <input 
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="form-control"
                    required
                    autoComplete="email"
                    value={email}
                    onChange={event => onEmailChanged(event.target.value) } />
                </div>
              </div>
              <div className="form-group form-group-default">
                <label>Password</label>
                <div className="controls">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    required
                    autoComplete="current-password"
                    value={password}
                    onChange={event => onPasswordChanged(event.target.value) } />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 no-padding sm-p-l-10">
                  <div className="checkbox ">
                    <input type="checkbox" id="keepMeSignedIn" checked={keepMeSignedIn} onChange={event => onKeepMeSignedInChanged(event.target.checked)} />
                    <label htmlFor="keepMeSignedIn">Keep Me Signed in</label>
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-end">
                  <a onClick={this.toggle} className="text-info small" style={{ cursor: 'pointer' }}>Forgot Password?</a>
                  <ForgotPassword isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}/>
                </div>
              </div>
              <button className="btn btn-primary btn-cons m-t-10" type="submit">Sign in</button>
              <ReCaptcha
                action="login"
                recaptchaKey={google.recaptcha.siteKey}
              ></ReCaptcha>
            </form>
             {/*<div className="pull-bottom sm-pull-bottom">
              <div className="m-b-30 p-r-80 sm-m-t-20 sm-p-r-15 sm-p-b-20 clearfix">
                <div className="col-sm-9 no-padding m-t-10">
                  <p>
                    <small>
                      <a href="" className="text-info">Term of use</a>. <a href="" className="text-info">Privacy policy</a>
                    </small>
                  </p>
                </div>
              </div>
            </div>  */}
          </div>
        </div>
      </div>
    </Layout>;
  }
}

const mapStateToProps = ({ login, recaptcha, firebase }) => ({
  ...login,
  ...recaptcha
});
const mapDispatchToProps = ({
  ...loginActions,
});

const Connected = compose(
  WithAuthorization(unAuthCondition, homePath),
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(Login);
export default Connected;
