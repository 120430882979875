import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import { NotificationManager, NotificationPosition } from 'components/Layout';
import { actions as smsActions } from 'reducers/sms';
import { sendEmail, createPDFInvoice } from 'utils';
import { ORDER_EVENT_INVOICE_CREATION } from 'constants/taskEvents';
import Loading from 'components/Loading';

class TaskInvoiceModal extends Component {
  state = {
    invoiceReady: false,
    activeModal: false,
    loading: false,
    phoneNumber: '',
    email: '',
  };

  async componentDidMount() {
    const { order: { id: taskId, details } } = this.props;
    const invoiceCreationEvent = details.events.find(event => event.name === ORDER_EVENT_INVOICE_CREATION);
    if (invoiceCreationEvent) {
      this.setState({ invoiceReady: true });
    }
    else {
      const created = await createPDFInvoice({ taskId });
      if (created) {
        this.setState({ invoiceReady: true });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { order: { recipient } } = nextProps;
    if (recipient && recipient.phone !== this.state.phoneNumber) {
      this.setState({
        phoneNumber: recipient.phone,
        email: recipient.email
      });
    }
  }

  async sendAction() {
    this.setState({ loading: true });
    const { order: { invoiceUrl, orderNumber }, sendSMS } = this.props;
    const { phoneNumber, email, activeModal } = this.state;

    let sent = false;
    let message = `Here is a copy of the Order #${orderNumber}, click below to download.\n${invoiceUrl}`;

    switch (activeModal) {
      case 'text':
        sent = await sendSMS(phoneNumber, message);
        break;
      default:
        const subject = `Copy of Invoice #${orderNumber}`;
        sent = await sendEmail({ email, subject, message });
    }

    if (sent) {
      NotificationManager.success('Invoice sent', null, NotificationPosition.TOP_LEFT);
    } else {
      NotificationManager.error('Invoice was not sent', null, NotificationPosition.TOP_LEFT);
    }
    this.setState({ loading: false });
  }

  setActiveModal = (activeModal) => {
    this.setState({ activeModal });
  }

  closeSendingActionsModal = () => {
    this.setState({ activeModal: false });
  }

  renderSendingActionsModal() {
    const { order: { orderNumber } } = this.props;
    const { phoneNumber, email, activeModal, loading } = this.state;
    const isTextModalActive = activeModal === 'text';

    return (
      <Modal
        isOpen={!!activeModal}
        toggle={this.closeSendingActionsModal}>
        <ModalHeader>
          <div>{isTextModalActive ? 'Text' : 'Email'} a copy of <span className="font-weight-bold">Order {orderNumber}</span></div>
        </ModalHeader>
        <ModalBody>
          <div className="form-group form-group-default">
            <label>{isTextModalActive === 'text' ? 'Text a Link' : 'Email'}</label>
            {isTextModalActive ? (
              <div className="controls">
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="Add a number"
                  className="form-control"
                  onChange={(e => this.setState({ phoneNumber: e.target.value }))}
                  value={phoneNumber} />
              </div>
            ) : (
                <div className="controls">
                  <input
                    type="text"
                    name="email"
                    placeholder="Add an email"
                    className="form-control"
                    onChange={(e => this.setState({ email: e.target.value }))}
                    value={email} />
                </div>
              )}
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button disabled={loading} color="primary" onClick={() => this.sendAction()}>{isTextModalActive ? 'Text' : 'Email'}</Button>
          <Button disabled={loading} color="primary" onClick={() => this.closeSendingActionsModal()}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { open, toggle, order: { orderNumber, invoiceUrl } } = this.props;
    const { invoiceReady } = this.state;
    return <Fragment>
      <Modal
        isOpen={open}
        toggle={toggle}
        contentClassName="modal-invoice"
        size={'lg'}>
        <ModalHeader className="p-0">
          <Button onClick={() => window.open(invoiceUrl)} className="mr-2" color="primary">Print</Button>
          <Button className="mr-2" color="primary" onClick={() => this.setActiveModal('email')}>Email</Button>
          <Button className="mr-2" color="primary" onClick={() => this.setActiveModal('text')}>Text</Button>
        </ModalHeader>
        <ModalBody className="p-0 bg-white" style={{ width: '100%', height: '90vh' }}>
          {invoiceReady
            ? (
              <iframe
              title={`Invoice ${orderNumber}`}
              src={`${invoiceUrl}#toolbar=0`}
              style={{ width: '100%', height: '90vh' }}></iframe>
            ) : (
              <Loading message="Preparing invoice, wait a moment..." />
            )
          }
          {this.renderSendingActionsModal()}
        </ModalBody>
      </Modal>
    </Fragment>
  }
}

const mapDispatchToProps = ({ ...smsActions });
const mapStateToProps = ({ sms }) => ({ sms });
export default connect(mapStateToProps, mapDispatchToProps)(TaskInvoiceModal);
