import React from 'react';
import PropTypes from 'prop-types';
import { Pin } from '.';

class CarPin extends Pin {
  static propTypes = {
    orientation: PropTypes.number,
  };

  render() {
    const {size = 36, fill = '#ffb900', onClick, orientation = 0 } = this.props;

    return (<svg height={size} viewBox="0 0 42 66" onClick={onClick} style={{transform: `rotate(${orientation}deg`, marginTop: `-${size / 2}px` }}>
      <defs>
        <filter id="Rectangle_73" x="0" y="0" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood floodOpacity="0.251"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g id="car-wrapper" data-name="car" transform="translate(-276.863 -99.093)">
        <rect id="shadow" width="25" height="48" rx="8" transform="translate(278.469 115.928)" opacity="0.251"/>
        <g id="car" transform="translate(114.59 -144.355)">
          <g transform="matrix(1, 0, 0, 1, 162.27, 243.45)" filter="url(#Rectangle_73)">
            <rect id="Rectangle_73-2" data-name="Rectangle 73" width="24" height="48" rx="8" transform="translate(9 6)" fill={fill}/>
          </g>
          <rect id="Rectangle_74" data-name="Rectangle 74" width="20" height="30" rx="8" transform="translate(173.22 261.447)" fill="rgba(0,0,0,0.48)"/>
          <rect id="Rectangle_75" data-name="Rectangle 75" width="16" height="18" rx="6" transform="translate(175.168 270.447)" fill={fill}/>
        </g>
      </g>
    </svg>);
  }
}

export default CarPin;
