import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, { NavigationControl, Marker } from 'react-map-gl';
import _ from 'lodash';
import polyline from '@mapbox/polyline';
import format from 'string-format';

import { getDestinationMarker,
  getOriginMarker,
  DetourPin,
  PolylineOverlay } from '.';
import WithBounds from 'components/WithBounds';
import { blueBell } from 'constants/colors';
import { NotificationManager, NotificationPosition } from 'components/Layout';

import { mapbox } from 'config';

class RouteMap extends Component {

  static propTypes = {
    originPoint: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired
    }),
    destinationPoint: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired
    }).isRequired,
    addWaypoint: PropTypes.func,
  };

  defaultLongitude = -83.1692448;
  defaultLatitude = 42.3528165;
  state = {
    // dark mode, might be useful for a night mode
    // mapStyle: 'mapbox://styles/mapbox/dark-v9',
    // This looks like the one used in the design
    // mapStyle: 'mapbox://styles/mapbox/light-v9',
    mapStyle: 'mapbox://styles/mapbox/streets-v10',
    viewPort: {
      width: 1100, //approx number width, lower number or empty causes the calculated zoom to fail
      height: 400,//approx number width, lower number or empty causes the calculated zoom to fail
      longitude: this.defaultLongitude,
      latitude: this.defaultLatitude,
      zoom: 11,
    },
    viewPortChanged: false,
    trip: {}, // The coordinates use in the trip
    loading: false,
  }

  get points() {
    const { originPoint, destinationPoint, waypoints } = this.props;
    return [
      originPoint && [originPoint.lon, originPoint.lat],
      ...((waypoints.map(({lon, lat}) => [lon, lat]) || [])),
      destinationPoint && [destinationPoint.lon, destinationPoint.lat],
    ].filter(i => i);
  }

  componentDidMount = () => {
    if (this.points) {
      if (this.points.length > 1) {
        this.generateRoute();
      } else if (this.props.destinationPoint) {
        this.setState({ viewPort: { ...this.state.viewPort,
          latitude: this.props.destinationPoint.lat,
          longitude: this.props.destinationPoint.lon,
          zoom: 14 } });
      }
    } 
  }

  componentDidUpdate = (prevProps) => {
    // If origin point change, let's generate route
    if (prevProps.originPoint !== this.props.originPoint ||
      prevProps.waypoints.length !== this.props.waypoints.length) {
      this.generateRoute();
    }
  }

  generateRoute = () => {
    if (this.state.loading || !this.props.originPoint) {
      return;
    }

    this.setState({ loading: true });
    const self = this;
    const url = this.assembleMapboxDirectionUrl();
    fetch(url)
      .then(async (response) => {
        const json = await response.json();
        if (response.ok) {
          // from optimization api
          // const { trips: [ trip ] } = json;
          // this.setState({ trip: { ...trip, geometry: polyline.toGeoJSON(trip.geometry) } });
          const { viewPort } = self.state;
          const { routes: [ route ] } = json;
          const geometry = polyline.toGeoJSON(route.geometry);
          const { longitude, latitude, zoom } = this.props.fitPoints(geometry.coordinates, viewPort, { padding: {top: 40, bottom: 40, left: 120, right: 140}});
          this.setState({
            trip: { ...route, geometry: geometry },
            viewPort: { ...viewPort, longitude, latitude, zoom },
            loading: false
          });
        } else {
          const {message} = json;
          if (message) {
            NotificationManager.error(message, null, NotificationPosition.TOP_LEFT);
          }
          this.setState({
            loading: false,
          });
        }
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  assembleMapboxDirectionUrl = () => format(mapbox.navigationApi,
      { location: this.points.map(p => p.join(',')).join(';'), accessToken: mapbox.accessToken });

  getWaypointMarkers = () => {
    return this.props
      .waypoints
      .map(({lon, lat}, idx) =>
        <Marker key={idx} latitude={lat} longitude={lon}>
          <DetourPin />
        </Marker>);
  }

  drawTrip = () => {
    const coordinates = _.get(this.state, 'trip.geometry.coordinates', false);
    if (!coordinates) {
      return;
    }

    return <PolylineOverlay
      color={blueBell}
      points={coordinates} />;
  }

  addWaypoint = (e) => {
    if (!this.props.originPoint) {
      NotificationManager.error('Please enter the origin point before adding any waypoint or detour.', null, NotificationPosition.TOP_LEFT);
      return;
    }

    const [lon, lat] = e.lngLat;
    this.props.addWaypoint({lat, lon});
    this.generateRoute();
  }

  onViewportChange = viewPort => this.setState({viewPort, viewPortChanged: true});

  render() {
    const { mapStyle, viewPort } = this.state;
    const { originPoint, destinationPoint } = this.props;

    return <ReactMapGL
      {...viewPort}
      width='100%'
      height='100%'
      mapboxApiAccessToken={mapbox.accessToken}
      mapStyle={mapStyle}
      onViewportChange={this.onViewportChange}
      onClick={this.addWaypoint}
      >
        <div className="mapControls">
          <NavigationControl onViewportChange={this.onViewportChange} />
        </div>
        {this.drawTrip()}
        {this.getWaypointMarkers()}
        {getOriginMarker(originPoint)}
        {getDestinationMarker(destinationPoint)}
      </ReactMapGL>;
  }
}

export default WithBounds(RouteMap);
