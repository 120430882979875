import React, { Component, Fragment } from 'react';
import DeckGL from '@deck.gl/react';
import { LineLayer } from '@deck.gl/layers';
import ReactMapGL, { NavigationControl, Marker, Popup } from 'react-map-gl';

import { CarPin, CarInfo, ControlPanel, TaskInfo, MaxSpeedInfo } from '.';
import WithBounds from 'components/WithBounds';
import { getOriginMarker } from 'components/Map';

import { mapbox } from 'config';
import * as colors from "constants/colors";
import * as taskStatus from 'constants/taskStatus';
import * as taskEvents from 'constants/taskEvents';
import { getContrastColor } from 'utils';

class MonitoringMap extends Component {
  defaultLongitude = -83.1692448;
  defaultLatitude = 42.3528165;
  state = {
    // dark mode, might be useful for a night mode
    // mapStyle: 'mapbox://styles/mapbox/dark-v9',
    // This looks like the one used in the design
    // mapStyle: 'mapbox://styles/mapbox/light-v9',
    mapStyle: 'mapbox://styles/mapbox/streets-v10',
    viewPort: {
      width: 1100, //approx number width, lower number or empty causes the calculated zoom to fail
      height: 1300, //approx number width, lower number or empty causes the calculated zoom to fail
      longitude: this.defaultLongitude,
      latitude: this.defaultLatitude,
      zoom: 11,
    },
    viewPortChanged: false,
    popupInfo: null,
    popupTaskInfo: null,
    groupedCompletedTasks: [],
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { driverMarkers, groupedTasks } = nextProps;
    // getBounds method needs to have at least 2 coordinates to calculate the box
    if (
      driverMarkers &&
      driverMarkers.length >= 2 &&
      !this.state.viewPortChanged
    ) {
      const points = driverMarkers.map(item => [
        item.location.lon,
        item.location.lat,
      ]);
      const { longitude, latitude, zoom } = this.props.fitPoints(
        points,
        this.state.viewPort,
      );
      this.setState({
        viewPortChanged: true,
        viewPort: {
          ...this.state.viewPort,
          longitude,
          latitude,
          zoom,
        },
      });
    }

    if (
      groupedTasks &&
      groupedTasks.length &&
      groupedTasks !== this.state.groupedCompletedTasks
    ) {
      let groupedCompletedTasks = groupedTasks.reduce(
        (filtered, { tasks, vehicle }) => {
          const completedTasks = tasks.filter(
            t =>
              t.status === taskStatus.COMPLETED &&
              t.details &&
              t.details.events.find(
                e => e.name === taskEvents.ORDER_EVENT_COMPLETION,
              ));

          if (completedTasks.length) {
            filtered.push({ vehicle, tasks: completedTasks.reverse() });
          }
          return filtered;
        },
        [],
      );

      this.setState({ groupedCompletedTasks });
    }
  }

  onViewportChange = (viewPort, userAction) => {
    ((this.props.driverMarkers && this.props.driverMarkers.length > 0) ||
      userAction) &&
      this.setState({ viewPort, viewPortChanged: true });
  };

  getDriverMarker = (item, idx) => {
    const { profile: user } = item;
    return (
      <Marker
        key={idx}
        latitude={item.location.lat}
        longitude={item.location.lon}
      >
        <CarPin
          fill={(user && user.prefs && user.prefs.color) || colors.red}
          onClick={() => this.setState({ popupInfo: item })}
          orientation={item.bearing}
        />
      </Marker>
    );
  };

  getTaskMarker = (task, idx) => {
    const {
      details: { events },
      vehicle,
    } = task;
    const completed =
      events &&
      events.find(event => event.name === taskEvents.ORDER_EVENT_COMPLETION);
    const backgroundColor = (vehicle && vehicle.color) || colors.blue;
    return (
      <Marker
        key={task.number || idx}
        latitude={completed.location.lat}
        longitude={completed.location.lon}
      >
        <span
          className='square-xs'
          onClick={() => this.setState({ popupTaskInfo: task })}
          style={{ backgroundColor, color: getContrastColor(backgroundColor) }}
        >
          {task.number || (idx+1)}
        </span>
      </Marker>
    );
  };

  getRoutesLayer() {
    const { groupedCompletedTasks } = this.state;
    let layers = [];

    const getLocation = (task, event) => {
      let { location } = (task && task.details.events.find(e => e.name === event)) || {};
      return location || '';
    };

    (groupedCompletedTasks || []).map(({ tasks }) => {
      if (tasks.length) {
        // Initial Route for first task
        let sourceLocation = getLocation(
          tasks[0],
          taskEvents.ORDER_EVENT_DEPARTURE,
        );
        let targetLocation = getLocation(
          tasks[0],
          taskEvents.ORDER_EVENT_COMPLETION,
        );

        tasks.forEach((task, i) => {
          const lineData = {
            id: `line-task-${task.id}`,
            data: [
              {
                sourcePosition: [sourceLocation.lon, sourceLocation.lat],
                targetPosition: [targetLocation.lon, targetLocation.lat],
              },
            ],
          };

          layers.push(new LineLayer(lineData));

          const lastTask = tasks.length - 1 === i;
          const target = lastTask ? i : i + 1; // If task isn't the last, get the next one as target location
          sourceLocation = getLocation(
            tasks[i],
            taskEvents.ORDER_EVENT_COMPLETION,
          );
          targetLocation = getLocation(
            tasks[target],
            taskEvents.ORDER_EVENT_COMPLETION,
          );
        });
      }
      return true;
    });

    return layers;
  }

  getMaxSpeedPopup = (item) => {
    return (
        <Popup
          tipSize={0}
          offsetTop={0}
          offsetLeft={15}
          closeButton={false}
          anchor='left'
          className={'speed-popup'}
          longitude={item.location.lon}
          latitude={item.location.lat}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
        <MaxSpeedInfo onClick={() => this.setState({ popupInfo: item })} />

        </Popup>
      
    );
  };

  getDriverPopup = () => {
    const { popupInfo } = this.state;
    const {
      driver,
      activeTask,
      task: { assistant = null, destination = null },
    } = popupInfo;
    const currentDriver = this.getDriver(popupInfo.id) || {};
    
    if(!currentDriver.driver) {
      return;
    }

    return (
      currentDriver && (
        <Popup
          tipSize={5}
          offsetTop={20}
          anchor='right'
          className={'driver-info'}
          longitude={currentDriver.location.lon}
          latitude={currentDriver.location.lat}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
          <CarInfo
            activeTask={activeTask && activeTask.length}
            driver={driver}
            location={currentDriver.location}
            assistant={assistant}
            destination={destination}
            streetName={popupInfo.route}
            streetMaxSpeed={popupInfo.maxSpeed}
            remainingDistance={popupInfo.remainingDistance}
            estimatedTimeArrival={popupInfo.estimatedTimeArrival}
          />
        </Popup>
      )
    );
  };

  getTaskPopup = () => {
    const { popupTaskInfo } = this.state;
    const {
      details: { events },
    } = popupTaskInfo;
    const departure =
      events &&
      events.find(event => event.name === taskEvents.ORDER_EVENT_DEPARTURE);
    const completion =
      events &&
      events.find(event => event.name === taskEvents.ORDER_EVENT_COMPLETION);

    return (
      <Popup
        tipSize={5}
        offsetTop={30}
        anchor='right'
        longitude={completion.location.lon}
        latitude={completion.location.lat}
        closeOnClick={false}
        onClose={() => this.setState({ popupTaskInfo: null })}
      >
        <TaskInfo departure={departure} completion={completion} />
      </Popup>
    );
  };

  getDriver(id) {
    const { driverMarkers } = this.props;
    return driverMarkers.find(item => item.id === id);
  }

  getRouteMarkers({ vehicle, tasks, idx }) {
    const departure = tasks[0].details.events.find(
      e => e.name === taskEvents.ORDER_EVENT_DEPARTURE,
    );

    if(!departure || !departure.location) {
      return;
    }

    return (
      <Fragment key={idx}>
        {getOriginMarker(departure.location, vehicle.color)}
        {tasks.map((task, idx) => this.getTaskMarker(task, idx))}
      </Fragment>
    );
  }

  _onStyleChange = value => {
    this.setState({
      mapStyle: value,
    });
  };

  render() {
    const {
      mapStyle,
      viewPort,
      popupInfo,
      groupedCompletedTasks,
      popupTaskInfo,
    } = this.state;
    const { driverMarkers } = this.props;
    const layers = (groupedCompletedTasks && this.getRoutesLayer()) || [];

    return (
      <ReactMapGL
        {...viewPort}
        width='100%'
        height='100%'
        mapboxApiAccessToken={mapbox.accessToken}
        mapStyle={mapStyle}
        onViewportChange={viewPort => this.onViewportChange(viewPort, true)}
      >
        {groupedCompletedTasks && (
          <Fragment>
            <DeckGL viewState={viewPort} layers={layers} />
            {popupTaskInfo && this.getTaskPopup()}
            {groupedCompletedTasks.map((group, idx) => {
              return group.tasks.length ? this.getRouteMarkers({ ...group, idx }) : '';
            })}
          </Fragment>
        )}

        {popupInfo && this.getDriverPopup()})
        
        {(driverMarkers || []).filter(item => item.driver).map((item, idx) => 
          this.getDriverMarker(item, idx)
        )}

        {/* Commented by user request */}
        {/*(driverMarkers || []).filter(item => item.driver && (item.location.speed > item.maxSpeed)).map(item => 
          this.getMaxSpeedPopup(item)
        )*/}
        
        <div className='mapOptions'>
          <ControlPanel onChange={this._onStyleChange} />
        </div>
        <div className='mapControls'>
          <NavigationControl
            onViewportChange={viewPort => this.onViewportChange(viewPort, true)}
          />
        </div>
      </ReactMapGL>
    );
  }
}

export default WithBounds(MonitoringMap);
