import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import "firebase/firestore";

import { TaskForm } from './';

class TaskFormModal extends Component {
  render() {
    const { isOpen, toggle } = this.props;
    
    return <Modal isOpen={isOpen} toggle={toggle} className={this.props.className} size={'lg'}>
      <ModalHeader toggle={toggle}>Task</ModalHeader>
      <ModalBody>
        <TaskForm {...this.props} />
      </ModalBody>
    </Modal>;
  }
}

export default TaskFormModal;
