import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, CardTitle, CardHeader, CardBody, Card } from 'reactstrap';

class TaskList extends Component {
  static propTypes = { 
    enumerate: PropTypes.bool,
    dark: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.string.isRequired,
  };

  state = {
    isCollapse: false,
  };

  toggle() {
    this.setState(prevState => ({
      isCollapse: !prevState.isCollapse
    }))
  }

  render() {
    const { title, enumerate, children, dark, color, className, status, id } = this.props;
    const { isCollapse } = this.state;
    const statusMarkup = status ? (<small>{status}</small>) : '';
    return <Card className={`m-b-0 ${className || ''}`}>
        <CardHeader>
          <CardTitle>
            <div id={id}
              onClick={() => this.toggle()}
              className={isCollapse ? "collapsed" : ""}>
              <div style={{color}}><strong>{title}</strong></div>
              <div className="status">{statusMarkup}</div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Collapse isOpen={!isCollapse}>
            <ul className="tasks-list">
              {
                children.map((child, idx) => {
                  if (enumerate) {
                    return { ...child, props: {
                      ...child.props,
                      color: (child.props.color || (dark && '#fff')),
                      bgColor: (child.props.bgColor || color),
                      number: (child.props.number || (idx + 1)) }
                    };
                  }
                  return child;
                })
              }
            </ul>
          </Collapse>
        </CardBody>
      </Card>
  }
}

export default TaskList;
